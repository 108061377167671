import { Area } from "types/types";

export const getColorBySubCategory = (subCategory: string): string => {
  switch (subCategory) {
    case "광역버스":
      return "#BFDBFE"; // bg-blue-200
    case "지하철":
      return "#A7F3D0"; // bg-green-200
    case "대형마트":
      return "#FDE68A"; // bg-yellow-200
    case "재래시장":
      return "#FDBA74"; // bg-orange-200
    case "극장":
      return "#C7D2FE"; // bg-indigo-200
    case "초등학교":
      return "#DDD6FE"; // bg-purple-200
    case "중학교":
      return "#FECACA"; // bg-pink-200
    case "고등학교":
      return "#CCFBF1"; // bg-teal-200
    case "대형병원":
      return "#E5E7EB"; // bg-gray-200
    case "소규모병원":
      return "#D1FAE5"; // bg-lime-200
    case "공원":
      return "#A7F3D0"; // bg-emerald-200
    default:
      return "#FFFFFF"; // bg-white
  }
};

export const convertDist2Score = (dist?: number) => {
  // 가까운거리가 1점 먼거리가 0점
  // 1- dist/1000 (최대 거리가 1000m)
  if (dist) {
    return (1 - dist / 1000) * 100;
  }

  return 0;
};

export const convertValue = (value: string, toUnit: Area["unit"]) => {
  const numValue = parseFloat(value);
  if (isNaN(numValue)) return "";
  if (toUnit === "평") {
    return (numValue / 3.3058).toFixed(2);
  } else {
    return (numValue * 3.3058).toFixed(2);
  }
};
