import { ResetChangePasswdForm } from "@prop-insight/components";
import { RouteObject, useLocation, useNavigate } from "react-router-dom";
import withViewerTemplate from "hoc/withViewerTemplate";
import axiosInst from "utils/axiosInst";

function ChangePassword() {
  const navigate = useNavigate();
  const location = useLocation();

  if (!location.state?.username) navigate("/reset-password", { replace: true });
  return (
    <div>
      <div className="flex flex-col items-center">
        <div className="flex flex-col max-w-[390px] w-full mt-[217px]">
          <div className="flex flex-col items-center">
            <span className="text-primary-main-color font-semibold text-2xl mb-[8px]">
              비밀번호 찾기
            </span>
            <span className="text-primary-main-color text-[13px] mb-[47px] w-[181px] leading-[18px]">
              이메일로 발송된 초기화 코드와 새로운 비밀번호를 입력하세요.
            </span>
          </div>

          <ResetChangePasswdForm
            resetChange={async (code, password) => {
              const { username } = location.state;
              return axiosInst.post("/users/change-password", {
                username,
                code,
                password,
              });
            }}
            onSuccess={() => {
              navigate("/change-password-success", { replace: true });
            }}
          />
        </div>
      </div>
    </div>
  );
}

const ChangePasswordWithSidebar = withViewerTemplate(ChangePassword);

export default {
  path: "/change-password",
  element: <ChangePasswordWithSidebar />,
} as RouteObject;
