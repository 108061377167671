import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateRight } from "@fortawesome/free-solid-svg-icons";

export interface SmallButtonProps {
  darkColor?: number;
  text: string;
  onClick?: () => void;
  btnStyle?: string;
}

const SmallButton = ({
  darkColor,
  text,
  onClick,
  btnStyle,
}: SmallButtonProps) => {
  return (
    <button
      className={`flex items-center bg-background-sub-color py-[3px] px-[6px] rounded h-[19px] ${btnStyle}`}
      onClick={onClick}
    >
      <FontAwesomeIcon
        size="2x"
        icon={faArrowRotateRight}
        className="text-text-darkgray mr-[2px] h-[12px]"
      />
      <span
        className="text-text-darkgray text-[10px]"
        style={{ transform: "scale(0.9)" }}
      >
        {text}
      </span>
    </button>
  );
};

export default SmallButton;
