import { useMemo } from "react";
import { RealtyPolicy } from "@prop-insight/database";
import moment from "moment";

interface RealtyPolicyItemProps {
  realtyPolicy: RealtyPolicy;
  openRealtyPolicyDetail: (realtyPolicy: RealtyPolicy) => void;
}

const RealtyPolicyItem = ({
  realtyPolicy,
  openRealtyPolicyDetail,
}: RealtyPolicyItemProps) => {
  // 담당부서별 색상
  const getColor = useMemo(() => {
    if (realtyPolicy.department === "금융위원회") {
      return "bg-primary-main-color";
    } else if (realtyPolicy.department === "국토교통부") {
      return "bg-primary-sub-color";
    } else {
      return "bg-background-blue";
    }
  }, [realtyPolicy.department]);

  return (
    <div
      className="flex items-center border-b border-background-sub-color py-5 cursor-pointer hover:bg-background-main-color"
      onClick={() => openRealtyPolicyDetail(realtyPolicy)}
    >
      <div
        className={`${getColor} rounded flex items-center justify-center h-[31px] pl-[3px] pr-1 mr-[6px]`}
      >
        <span className="text-h4 text-white whitespace-nowrap">
          {realtyPolicy.department}
        </span>
      </div>

      <div className="flex flex-col mt-[3px] w-full">
        <div className="flex justify-between">
          <span className="text-h3 text-text-darkgray font-semibold line-clamp-1 max-w-[238px] overflow-hidden">
            {realtyPolicy.title}
          </span>
          <span className="text-p4 text-text-darkgray whitespace-nowrap mt-[2px]">
            보도 일시 {moment(realtyPolicy.createdAt).format("YYYY.MM.DD")}
          </span>
        </div>

        <div className="flex">
          <span
            className="text-p3 text-text-darkgray line-clamp-1 max-w-[310px] overflow-hidden"
            dangerouslySetInnerHTML={{ __html: realtyPolicy.summary }}
          ></span>
        </div>
      </div>
    </div>
  );
};

export default RealtyPolicyItem;
