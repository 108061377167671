import { PrivateLandSite } from "@prop-insight/database";
import axiosInst from "utils/axiosInst";

interface getPrivateLandSiteParams {
  currentPage: number;
  currentCategory: string | undefined;
  currentUseZone: string | undefined;
  currentSido: string | undefined;
}

export const getPrivateLandSite = async ({
  currentCategory,
  currentUseZone,
  currentSido,
  currentPage,
}: getPrivateLandSiteParams) => {
  const result = await axiosInst.get<{
    count: number;
    privateLandSites: PrivateLandSite[];
  }>(
    `/private-land-sites?page=${currentPage}&limit=10${
      currentCategory ? `&landCategory=${currentCategory}` : ""
    }${currentUseZone ? `&landUseZone=${currentUseZone}` : ""}${
      currentSido ? `&sido=${currentSido}` : ""
    }`
  );

  return result.data;
};
