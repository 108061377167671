import { SignupForm } from "@prop-insight/components";
import { useAuth } from "providers/AuthProvider";
import { RouteObject, useLocation, useNavigate } from "react-router-dom";
import Logo from "logo.svg";
import withViewerTemplate from "hoc/withViewerTemplate";

function SignUp() {
  const { signUp } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <div>
      <div className="flex flex-col items-center">
        <div className="flex flex-col max-w-[390px] w-full my-[150px]">
          <div className="flex flex-col items-center">
            <img src={Logo} alt="logo" className="mb-[9px]" />
            <span className="text-primary-main-color font-semibold text-2xl mb-12">
              회원가입
            </span>
          </div>

          <SignupForm
            signup={signUp}
            onSuccess={(username) => {
              navigate("/email-confirm", {
                replace: true,
                state: { username },
              });
            }}
            // 약관 동의 페이지 이동
            onPrivacyPolicy={() => {
              navigate("/privacy-policy");
            }}
            onServiceTerms={() => {
              navigate("/service-terms");
            }}
            onLegalNotice={() => {
              navigate("/legal-notice", {
                state: {
                  from: pathname,
                },
              });
            }}
          />
        </div>
      </div>
    </div>
  );
}

const SignupWithSidebar = withViewerTemplate(SignUp);

export default {
  path: "/signup",
  element: <SignupWithSidebar />,
} as RouteObject;
