import clsx from "clsx";
import { mobileContents, MobileContentType } from "contans/Mobile";
import Logo from "icons/Logo";
import { useState } from "react";

const MobilePage = () => {
  const [currentMobileContent, setCurrentMobileContent] = useState(0);

  return (
    <div className="py-7 px-5 flex flex-col justify-between h-screen">
      <div className="flex justify-between items-center w-full">
        <Logo />
        <div></div>
      </div>
      <MobileContent mobileContent={mobileContents[currentMobileContent]} />
      <ul className="mx-auto flex gap-2 w-full justify-center ">
        {mobileContents.map((content, index) => (
          <li
            key={index}
            onClick={() => setCurrentMobileContent(index)}
            className={clsx(
              "rounded-full w-4 h-4 border-[#1A6667] border",
              index === currentMobileContent && "bg-[#1A6667]"
            )}
          ></li>
        ))}
      </ul>
    </div>
  );
};

export default MobilePage;

interface MobileContentProps {
  mobileContent: MobileContentType;
}

const MobileContent = ({ mobileContent }: MobileContentProps) => {
  if (mobileContent.type === "video") {
    return (
      <div className="h-[400px]">
        <div className="font-adamina text-sm tracking-[-1px] mb-7">
          <p>안녕하세요.</p>
          <p>
            <span className="text-[#1A6667] text-lg">PROP-INSIGHT</span>
            입니다.
          </p>
        </div>
        <div className="mb-7">
          {mobileContent.descriptions.map((description, index) => (
            <div
              key={index}
              className="mt-2 flex gap-1 items-center font-adamina text-xs tracking-[-1px]"
            >
              <svg
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.8125 8.46875L6.5625 12.2188L12.1875 3.78125"
                  stroke="#16B5B8"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p>{description}</p>
            </div>
          ))}
        </div>

        <div className="w-full h-[150px] bg-black"></div>
      </div>
    );
  } else {
    return (
      <div className="h-[400px]">
        <div className="flex gap-1 items-end mb-7 font-adamina tracking-[-1px]">
          <p className="text-[60px] text-[#1A6667] leading-[60px]">
            {mobileContent.index}
          </p>
          <p
            className="whitespace-nowrap text-sm"
            dangerouslySetInnerHTML={{
              __html: mobileContent.title.replaceAll("\n", "<br/>"),
            }}
          />
        </div>
        <img className="mb-7" src={mobileContent.image} alt="모바일 이미지" />
        <div>
          {mobileContent.descriptions.map((description, index) => (
            <div
              key={index}
              className="mt-2 flex gap-1 items-center font-adamina"
            >
              <svg
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.28125 3.3125L7.96875 8L3.28125 12.6875M7.03125 3.3125L11.7188 8L7.03125 12.6875"
                  stroke="#16B5B8"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="text-xs tracking-[-0.5px]">{description}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }
};
