import { useCallback, useState } from "react";
import { LoadingSpinner, SearchInput } from "@prop-insight/components";

import Pagination from "./Pagination";
import SelectBox from "./SelectBox";
import _ from "lodash";
import {
  RealtyPolicyDepartment,
  RealtyPolicyRegion,
} from "contans/SelectItems";
import RealtyPolicyItem from "./RealtyPolicyItem";
import axiosInst from "utils/axiosInst";
import { RealtyPolicy } from "@prop-insight/database";
import { getRealtyPolicy } from "utils/api/realtyPolicyApi";
import useQuery from "hooks/useQuery";
import { calcTotalPages } from "utils/calcMaxPage";

export interface RealtyPolicyPanelProps {
  openRealtyPolicyDetail: (realtyPolicy: RealtyPolicy) => void;
}

interface RealtyPolicyResponse {
  count: number;
  realtyPolicies: RealtyPolicy[];
}

const convertRegion = (currentRegion: string | undefined) => {
  if (currentRegion === "all" || currentRegion === undefined) {
    return "";
  } else if (currentRegion) {
    return RealtyPolicyRegion[currentRegion];
  }
};

const convertDepartment = (currentDepartment: string | undefined) => {
  if (currentDepartment === "all" || currentDepartment === undefined) {
    return "";
  } else if (currentDepartment) {
    return RealtyPolicyDepartment[currentDepartment];
  }
};

export default function RealtyPolicyPanel({
  openRealtyPolicyDetail,
}: RealtyPolicyPanelProps) {
  const [searchKey, setSearchKey] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [currentDepartment, setCurrentDepartment] = useState<string>();
  const [currentRegion, setCurrentRegion] = useState<string>();
  const [currentSearchKey, setCurrentSearchKey] = useState<string>("");

  const [currentPage, setCurrentPage] = useState(1);

  const { data: realtyPolicies, isLoading } = useQuery<RealtyPolicyResponse>(
    useCallback(
      () =>
        getRealtyPolicy({
          currentPage,
          currentSearchKey,
          currentRegion: convertRegion(currentRegion),
          currentDepartment: convertDepartment(currentDepartment),
        }),
      [currentDepartment, currentPage, currentRegion, currentSearchKey]
    )
  );

  const totalPages = calcTotalPages(realtyPolicies?.count ?? 0);

  // 제목 검색 기능
  const handleSearch = useCallback((searchKey: string) => {
    setCurrentSearchKey(searchKey);
    setCurrentDepartment(undefined);
    setCurrentRegion(undefined);
    setCurrentPage(1);
  }, []);

  // 담당부서별 필터
  const handleSelectDepartment = useCallback((department: string) => {
    setCurrentDepartment(department);
    setCurrentSearchKey("");
    setCurrentPage(1);
  }, []);

  // 지역별 필터 // 일단 ui만
  const handleSelectRegion = useCallback((region: string) => {
    setCurrentRegion(region);
    setCurrentSearchKey("");
    setCurrentPage(1);
  }, []);

  // 상세정보 api호출
  const handleRealtyPolicyDetail = useCallback(
    async (id: number) => {
      try {
        const resp = await axiosInst.get(`/realty-policies/${id}`);
        openRealtyPolicyDetail(resp.data);
      } catch (error) {
      } finally {
      }
    },
    [openRealtyPolicyDetail]
  );

  return (
    <div className="mx-[20px]">
      <div className="mb-[9px]">
        <span className="text-text-darkgray text-title2">부동산 정책</span>
      </div>

      <div className="mb-[15px]">
        <SearchInput
          value={searchKey}
          placeholder="정책 제목을 검색해주세요."
          onChange={(
            e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => setSearchKey(e.target.value)}
          onSearch={() => {
            handleSearch(searchKey);
          }}
          onReset={() => setSearchKey("")}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          isFocused={isFocused}
        />
      </div>

      {/* 셀렉트 */}
      <div className="flex justify-between mb-[14px]">
        <SelectBox
          defaultValue="담당부서별"
          keyValue={currentDepartment ?? "담당부서별"}
          rootClassName="mr-[10px] w-1/2 "
          className=""
          textDivClassName="-ml-[16px]"
          MenuClassName="w-[190px]"
          items={_.map(RealtyPolicyDepartment, (val, key) => {
            return {
              key: key,
              value: val,
            };
          })}
          onChange={(e) => {
            handleSelectDepartment(e.key);
          }}
        />
        <SelectBox
          defaultValue="지역별"
          keyValue={currentRegion ?? "지역별"}
          rootClassName="w-1/2 "
          className=""
          textDivClassName="-ml-[16px]"
          MenuClassName="w-[190px]"
          longTextItem
          items={_.map(RealtyPolicyRegion, (val, key) => {
            return {
              key: key,
              value: val,
            };
          })}
          onChange={(e) => {
            handleSelectRegion(e.key);
          }}
        />
      </div>
      <hr className="border-background-sub-color" />

      {isLoading ? (
        <div className="flex items-center justify-center mt-[325px]">
          <LoadingSpinner panel />
        </div>
      ) : (
        <div>
          {_.map(realtyPolicies?.realtyPolicies, (realtyPolicy) => {
            return (
              <RealtyPolicyItem
                key={realtyPolicy.id}
                realtyPolicy={realtyPolicy}
                openRealtyPolicyDetail={() =>
                  handleRealtyPolicyDetail(realtyPolicy.id)
                }
              />
            );
          })}
        </div>
      )}

      {/* pagination */}
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        onChangePage={(page) => {
          setCurrentPage(page);
        }}
      />
    </div>
  );
}
