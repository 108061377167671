import { Button } from "@prop-insight/components";
import { PrivateLandSite } from "@prop-insight/database";
import clsx from "clsx";
import {
  PrivateLandSiteCategory,
  PrivateLandUseDistrict,
  PrivateLandUseZone,
} from "contans/SelectItems";
import React from "react";

interface PrivateLandSiteInfoWindowProps {
  privateLandSite: PrivateLandSite;
  openPrivateLandSiteDetail: (privateLandSite: any) => void;
}

const PrivateLandSiteInfoWindow = ({
  privateLandSite,
  openPrivateLandSiteDetail,
}: PrivateLandSiteInfoWindowProps) => {
  return (
    <div className="bg-white w-full h-full rounded-[10px] shadow-[2px_2px_4px_rgba(0,0,0,0.2)] p-[12px]">
      <div className="w-full">
        <div className="flex mb-[5px] h-[18px]">
          <div className="px-[14px] py-[2px] mr-[11px] border border-primary-main-color flex items-center justify-center rounded-[5px]">
            <span className="text-primary-main-color font-semibold text-[13px] mt-[1px] tracking-tighter">
              지번주소
            </span>
          </div>
          <div className="flex h-[18px]">
            <p className="!text-p3 font-normal whitespace-nowrap tracking-tighter">
              {privateLandSite.address + " " + privateLandSite.mainLotNumber}
            </p>
          </div>
        </div>
        <div className="flex mb-[5px] h-[18px]">
          <div className="px-[14px] py-[2px] mr-[11px] border border-primary-main-color flex items-center justify-center rounded-[5px] bg-primary-main-color">
            <span className="text-white font-semibold text-[13px] mt-[2px] tracking-tighter">
              토지면적
            </span>
          </div>
          <div className="flex h-[18px]">
            <p className="!text-p3 font-normal whitespace-nowrap tracking-tighter">
              {privateLandSite.landArea.toFixed(2)}m²
            </p>
          </div>
        </div>
        <div className="flex mb-[5px] h-[18px]">
          <div className="px-[14px] py-[2px] mr-[11px] border border-primary-main-color flex items-center justify-center rounded-[5px] bg-primary-main-color">
            <span className="text-white font-semibold text-[13px] mt-[2px] tracking-tighter">
              지목
            </span>
          </div>
          <div className="flex h-[18px]">
            <p className="!text-p3 font-normal whitespace-nowrap tracking-tighter">
              {PrivateLandSiteCategory[privateLandSite.landCategory]}
            </p>
          </div>
        </div>
        <div className="flex mb-[5px] h-[18px]">
          <div className="px-[14px] py-[2px] mr-[11px] border border-primary-main-color flex items-center justify-center rounded-[5px] bg-primary-main-color">
            <span className="text-white font-semibold text-[13px] mt-[2px] tracking-tighter">
              용도지역
            </span>
          </div>
          <div className="flex h-[18px]">
            {/* 원형 추가 */}
            <div
              className={clsx("flex items-center justify-center", {
                "text-pink-200":
                  PrivateLandUseZone[privateLandSite.landUseZone].includes(
                    "상업"
                  ),

                "text-slate-400":
                  PrivateLandUseZone[privateLandSite.landUseZone].includes(
                    "공업"
                  ),
                "text-yellow-200":
                  PrivateLandUseZone[privateLandSite.landUseZone].includes(
                    "주거"
                  ),
                "text-lime-400":
                  PrivateLandUseZone[privateLandSite.landUseZone].includes(
                    "관리"
                  ),
              })}
            >
              <span className="flex items-center justify-center text-[23px] -mt-[2px]">
                &#9679;
              </span>
            </div>

            <p className="!text-p3 font-normal whitespace-nowrap tracking-tighter">
              {PrivateLandUseZone[privateLandSite.landUseZone]}
            </p>
          </div>
        </div>
        <div className="flex mb-[5px] h-[18px]">
          <div className="px-[14px] py-[2px] mr-[11px] border border-primary-main-color flex items-center justify-center rounded-[5px] bg-primary-main-color">
            <span className="text-white font-semibold text-[13px] mt-[2px] tracking-tighter">
              용도지구
            </span>
          </div>
          <div className="flex h-[18px]">
            <p className="!text-p3 font-normal whitespace-nowrap tracking-tighter">
              {PrivateLandUseDistrict[privateLandSite.landUseDistrict]}
            </p>
          </div>
        </div>
        <div>
          <Button
            type="button"
            color="primary"
            variant="outlined"
            onClick={() => {
              openPrivateLandSiteDetail(privateLandSite);
            }}
          >
            자세히보기
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PrivateLandSiteInfoWindow;
