import React, { useCallback, useEffect, useMemo, useState } from "react";

import PlannedLandTable from "./PlannedLandTable";
import { LandSite } from "@prop-insight/database";

import {
  SearchInput,
  AddContentButton,
  LoadingSpinner,
} from "@prop-insight/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretUp,
  faBell,
  faMagnifyingGlass,
  faLocationDot,
  faTrainSubway,
} from "@fortawesome/free-solid-svg-icons";
import CompletedLandTable from "./CompletedLandTable";
import _ from "lodash";
import { Post } from "@prop-insight/database";
import clsx from "clsx";
import { Land, Menu, Location } from "types/types";
import { useMap } from "providers/MapProvider";
import FavoriteSlider from "./FavoriteSlider";
import useQuery from "hooks/useQuery";
import { getPostFive } from "utils/api/postApi";
import { getPoi } from "utils/api/poiApi";
import { errorToast } from "utils/toast";

interface HomePanelProps {
  userToken?: boolean;
  onChangeMenu?: (menu: Menu) => void;
  openNewLandDetail: (landSite: LandSite) => void;
  openPostDetail: (notice: Post) => void;
}

export default function HomePanel({
  userToken,
  onChangeMenu,
  openNewLandDetail,
  openPostDetail,
}: HomePanelProps) {
  const [searchKey, setSearchKey] = useState("");
  const [value, setValue] = useState<0 | 1>(0);
  const [isFocused, setIsFocused] = useState(false);

  const [isSearchInputHovered, setIsSearchInputHovered] = useState(false);
  const [isSearchModalHovered, setIsSearchModalHovered] = useState(false);
  const { data: notices, isLoading: noticesLoading } =
    useQuery<Post[]>(getPostFive);
  const [landArr, setLandArr] = useState<Land[]>([]);
  const [locationArr, setLocationArr] = useState<Location[]>([]);
  const [subwayArr, setSubwayArr] = useState<Location[]>([]);
  const [isSearchLoading, setIsSearchLoading] = useState(false);

  const { setMapState, handleClickedLand } = useMap();

  useEffect(() => {
    debouncedGetPoi(searchKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey]);

  const debouncedGetPoi = useMemo(
    () =>
      _.debounce(async (searchKey: string) => {
        if (searchKey.trim() === "") {
          setLandArr([]);
          setLocationArr([]);
          setSubwayArr([]);
          return;
        }

        try {
          setIsSearchLoading(true);

          const { place, subway, jijuk } = await getPoi(searchKey);

          setLandArr(jijuk);
          setLocationArr(place);
          setSubwayArr(subway);
        } catch (e) {
          console.log(e);
          errorToast();
        } finally {
          setIsSearchLoading(false);
        }
      }, 1000),
    []
  );

  const handleResetHoverFocus = useCallback(() => {
    setIsFocused(false);
    setIsSearchInputHovered(false);
    setIsSearchModalHovered(false);
  }, []);

  const handleSearch = useCallback((searchKey: string) => {
    const storedSearches = localStorage.getItem("prop-insight-search");
    const searches = JSON.parse(storedSearches ?? "[]");

    if (searchKey.trim() !== "") {
      localStorage.setItem(
        "prop-insight-search",
        JSON.stringify([...searches, searchKey])
      );
    }
    setSearchKey(searchKey);
  }, []);

  const createCurrentSearchList = useCallback(() => {
    return _.map(
      JSON.parse(localStorage.getItem("prop-insight-search") ?? "[]")
        .filter((search: string) => search.includes(searchKey))
        .reverse()
        .slice(0, 5),
      (location, index) => (
        <button
          key={index}
          className="flex items-center bg-tertiary-main mx-4 pl-[15px] pr-[17px] rounded-[100px] w-max h-5 mb-[10px] border-[1px] hover:border-primary-main-color"
          onClick={() => {
            setSearchKey(location);
          }}
        >
          <span className="text-text-darkgray text-p3 mt-[2px]">
            {location}
          </span>
        </button>
      )
    );
  }, [searchKey]);

  const createAutoCompleteLandList = useCallback(() => {
    return _.map(landArr, (land, index) => (
      <div
        key={index}
        className="border-b-[1px] px-4 flex flex-col border-background-sub-color pt-[2px] pb-[3px] cursor-pointer hover:bg-background-main-color"
        onClick={() => {
          setSearchKey(land.jibunName);
          handleClickedLand(land);
        }}
      >
        <span className="text-p3 text-text-darkgray">{land.jibunName}</span>
        <span className="text-p5 text-text-darkgray">
          {land.doroNameList &&
            land.doroNameList.length > 0 &&
            land.doroNameList[0]}
        </span>
      </div>
    ));
  }, [handleClickedLand, landArr]);

  const createAutoCompleteLocationList = useCallback(() => {
    return _.map(locationArr, (location, index) => (
      <div
        key={index}
        className="border-b-[1px] px-4 border-background-sub-color pt-[2px] pb-[3px] cursor-pointer hover:bg-background-main-color"
        onClick={() => {
          setSearchKey(location.name);
          setMapState({
            lat: location.latitude,
            lng: location.longitude,
          });
        }}
      >
        <span className="text-p3 text-text-darkgray">{location.name}</span>
      </div>
    ));
  }, [locationArr, setMapState]);

  const createAutoCompleteSubwayList = useCallback(() => {
    return _.map(subwayArr, (subway, index) => (
      <div
        key={index}
        className="border-b-[1px] px-4 border-background-sub-color pt-[2px] pb-[3px] cursor-pointer hover:bg-background-main-color"
        onClick={() => {
          setSearchKey(subway.name);
          setMapState({
            lat: subway.latitude,
            lng: subway.longitude,
          });
        }}
      >
        <span className="text-p3 text-text-darkgray">{subway.name}</span>
      </div>
    ));
  }, [setMapState, subwayArr]);

  return (
    <div>
      <div className="mb-[18px] mx-5">
        <div
          onMouseEnter={() => {
            setIsSearchInputHovered(true);
          }}
          onMouseLeave={() => {
            setIsSearchInputHovered(false);
          }}
        >
          <SearchInput
            value={searchKey}
            isHaveSearchIcon={false}
            placeholder="지역, 동 이름을 검색해주세요."
            onChange={(
              e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setSearchKey(e.target.value)}
            onSearch={() => {
              handleSearch(searchKey);
              handleResetHoverFocus();
            }}
            onReset={() => setSearchKey("")}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              if (!isSearchInputHovered && !isSearchModalHovered) {
                setIsFocused(false);
              }
            }}
            isFocused={isFocused}
          />
        </div>

        {/* 검색 모달창 */}
        {isFocused && (
          <div
            onMouseEnter={() => {
              setIsSearchModalHovered(true);
            }}
            onMouseLeave={() => {
              setIsSearchModalHovered(false);
            }}
            className="flex flex-col absolute left-[20px] bg-white pt-[13px] pb-[15px] rounded w-[390px] z-50 mt-2"
            style={{ boxShadow: "0px 5px 20px 0px #0000001A" }}
          >
            <span className="text-h2 px-4 font-semibold text-text-darkgray">
              최근 검색
            </span>
            <div className="flex  flex-col mt-[5px]">
              {createCurrentSearchList()}
              <button
                className="flex items-center mx-4 bg-background-sub-color pl-4 pr-[14px] rounded-[100px] w-max h-5"
                onClick={() => {
                  localStorage.setItem(
                    "prop-insight-search",
                    JSON.stringify([])
                  );
                  handleResetHoverFocus();
                }}
              >
                <div className="flex items-center">
                  <svg
                    width="8"
                    height="10"
                    viewBox="0 0 8 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginRight: 5 }}
                  >
                    <path
                      d="M7.84573 1.65075C8.08114 1.38512 8.04364 0.990592 7.76031 0.769889C7.47698 0.549185 7.05614 0.584342 6.82073 0.849967L3.99989 4.02379L1.17906 0.849967C0.943643 0.584342 0.52281 0.549185 0.239476 0.769889C-0.0438572 0.990592 -0.0813572 1.38512 0.15406 1.65075L3.13114 5.00036L0.15406 8.34997C-0.0813572 8.61559 -0.0438572 9.01012 0.239476 9.23083C0.52281 9.45153 0.943643 9.41637 1.17906 9.15075L3.99989 5.97692L6.82073 9.15075C7.05614 9.41637 7.47698 9.45153 7.76031 9.23083C8.04364 9.01012 8.08114 8.61559 7.84573 8.34997L4.86864 5.00036L7.84573 1.65075Z"
                      fill="#333333"
                    />
                  </svg>

                  <span className="text-text-darkgray text-p4 mt-[2px]">
                    검색 기록 삭제
                  </span>
                </div>
              </button>
              {isSearchLoading ? (
                <div className="flex justify-center items-center h-[100px]">
                  <LoadingSpinner panel />
                </div>
              ) : (
                searchKey && (
                  <div className="mt-[19px] mb-1">
                    {/* 택지 */}
                    <div className="mb-[15px]">
                      <div className="border-b-[1px] mx-4 border-background-sub-color pb-[2px]">
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          className="text-primary-main-color h-[15px] mr-[6px] ml-[1px]"
                        />
                        <span className="text-h2 text-text-darkgray font-semibold mr-1">
                          택지
                        </span>
                        <span className="text-p5 text-primary-main-color inline-block h-[17px]">
                          ※ 택지 검색은 지번 주소로만 가능합니다.
                        </span>
                      </div>
                      {createAutoCompleteLandList()}
                    </div>

                    {/* 위치 */}
                    <div>
                      <div className="border-b-[1px] mx-4 border-background-sub-color pb-[2px]">
                        <FontAwesomeIcon
                          icon={faMagnifyingGlass}
                          className="text-primary-main-color h-[15px] mr-[6px] ml-[1px]"
                        />
                        <span className="text-h2 text-text-darkgray font-semibold">
                          위치
                        </span>
                      </div>
                      {createAutoCompleteLocationList()}
                    </div>

                    {/* 지하철 */}
                    <div className="mt-4">
                      <div className="border-b-[1px] mx-4 border-background-sub-color pb-[2px]">
                        <FontAwesomeIcon
                          icon={faTrainSubway}
                          className="text-primary-main-color h-[15px] mr-[6px] ml-[1px]"
                        />
                        <span className="text-h2 text-text-darkgray font-semibold">
                          지하철
                        </span>
                      </div>
                      {createAutoCompleteSubwayList()}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>

      {/* 실시간 검색어 부분 */}
      <div className="flex flex-col">
        <hr className="w-full border-background-sub-color border-[thin]" />
        <div className="h-[40px] flex justify-between px-[12px]">
          <div className="flex flex-row items-center pl-[8px]">
            <FontAwesomeIcon
              size="2x"
              icon={faCaretUp}
              className="text-text-red mr-2 w-[14px] h-6 mt-[3px]"
            />
            <span className="font-semibold text-[13px] text-text-red mt-[1px]">
              인기 즐겨찾기
            </span>
          </div>
          <div className="flex justify-center items-center">
            <FavoriteSlider />
          </div>
        </div>

        <hr className="w-full border-background-sub-color border-[thin]" />
      </div>

      {/* 소식 알리미 */}
      <div className="pl-5 pb-[11px] pt-[15px] pr-[19px]">
        <div className="flex justify-between">
          <div className="flex items-center">
            <FontAwesomeIcon
              size="2x"
              icon={faBell}
              className="text-primary-sub-color h-[25px] mr-[5px]"
            />
            <span className="text-primary-sub-color text-[16px] font-semibold mt-[2px]">
              소식 알리미
            </span>
          </div>

          <AddContentButton
            text="더보기"
            onClick={() => {
              onChangeMenu?.("posts");
            }}
            btnTextStyle="mt-[4px]"
          />
        </div>
        {/* 공지사항 제목 나열 */}
        <div
          className={`my-[9px] min-h-[127px] ${clsx({
            "flex items-center justify-center": noticesLoading,
          })}`}
        >
          {noticesLoading ? (
            <LoadingSpinner panel />
          ) : (
            _.map(notices, (notice, index) => {
              return (
                <div
                  className="py-[4px] cursor-pointer hover:bg-background-main-color"
                  key={index}
                  onClick={() => openPostDetail(notice)}
                >
                  <p className="text-text-darkgray text-[12px]">
                    {notice.title}
                  </p>
                </div>
              );
            })
          )}
        </div>
      </div>

      {/*택지 탭*/}
      <ul
        className="mb-[22px] flex list-none flex-row flex-wrap border-b border-background-sub-color pl-0"
        role="tablist"
        data-te-nav-ref
      >
        <li
          className={clsx(
            `w-1/2 flex justify-center cursor-pointer  hover:border-b-2  hover:border-primary-main-color hover:text-primary-main-color`,
            {
              "border-b-2 border-primary-main-color text-primary-main-color active:text-primary-sub-color active:border-primary-sub-color":
                value === 0,
              "text-secondary-main-color": value === 1,
            }
          )}
          onClick={() => setValue(0)}
        >
          <div className={`text-[13px] font-semibold py-[8px] `} role="tab">
            입찰진행중 택지
          </div>
        </li>
        <li
          className={clsx(
            `w-1/2 flex justify-center cursor-pointer  hover:border-b-2  hover:border-primary-main-color hover:text-primary-main-color`,
            {
              "border-b-2 border-primary-main-color text-primary-main-color active:text-primary-sub-color active:border-primary-sub-color":
                value === 1,
              "text-secondary-main-color": value === 0,
            }
          )}
          onClick={() => setValue(1)}
        >
          <div className={`text-[13px] font-semibold py-[8px] `} role="tab">
            마감된 택지
          </div>
        </li>
      </ul>

      <div className="pl-[21px] pr-[16px]">
        <div>
          {/* 접수예정, 접수 중과 접수마감 테이블 디자인이 달라서 컴포넌트 분리(Leehj) */}
          {value === 0 ? (
            // 접수예정, 접수 중 테이블
            <PlannedLandTable openNewLandDetail={openNewLandDetail} />
          ) : (
            // 접수마감 테이블
            <CompletedLandTable
              openNewLandDetail={openNewLandDetail}
              userToken={userToken}
            />
          )}
        </div>
      </div>
      {/* 더보기 버튼 */}
      <div className="flex justify-center my-[10px]">
        <AddContentButton
          variant="outlined"
          btnStyle="w-[80px] h-[30px]"
          text="더보기"
          size="medium"
          btnTextStyle="mr-1"
          onClick={() => {
            onChangeMenu?.("newland");
          }}
        />
      </div>
    </div>
  );
}
