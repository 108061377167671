import { Button } from "@prop-insight/components";
import { RouteObject, useNavigate } from "react-router-dom";
import withViewerTemplate from "hoc/withViewerTemplate";

function PrivacyPolicy() {
  const navigate = useNavigate();
  return (
    <div className="px-[57px] py-[46px]">
      <div className="flex flex-col mb-10">
        <span className="text-primary-main-color font-semibold text-2xl mb-8">
          개인정보처리 방침
        </span>
        <div className="bg-background-sub-color px-[20px] py-[10px] h-[68.5vh] rounded-[10px] border border-solid border-secondary-main-color text-sm overflow-y-auto">
          <p className="whitespace-pre-line font-bold mb-4">
            {
              "<(주)띵스워크샵>(‘www.prop-insight.com’이하 ‘프롭인사이트’)는 다음의 목적을 위하여 개인정보를 처리하고 있으며, 다음의 목적 이외의 용도로는 이용하지 않습니다.\n\n– 고객 가입의사 확인, 고객에 대한 서비스 제공에 따른 본인 식별.인증, 회원자격 유지.관리, 물품 또는 서비스 공급에 따른 금액 결제, 마케팅 활동 동의에 따른 경품 배송등."
            }
          </p>
          <ol className="list-decimal ml-5 flex flex-col gap-2">
            <li>
              <p className="font-bold mb-2">
                개인정보의 수집 항목 및 수집 방법
              </p>
              <ol className="list-decimal ml-5">
                <li className="mb-2">
                  <p className="mb-2">수집 항목</p>
                  <ul className="list-disc ml-5">
                    <li>
                      필수 항목: 이름, 이메일 주소, 전화번호, 생년월일, 로그인
                      정보(아이디, 비밀번호)
                    </li>
                    <li>선택 항목: 성별, 주소, 결제 정보</li>
                    <li>
                      서비스 이용 과정에서 자동으로 생성되는 정보: IP 주소,
                      쿠키, 서비스 이용 기록, 접속 로그, 기기 정보
                    </li>
                  </ul>
                </li>

                <li>
                  <p className="mb-2">수집 방법</p>
                  <ul className="list-disc ml-5">
                    <li>
                      회원가입, 서비스 이용, 고객 상담, 이벤트 응모, 자동 수집
                      도구(쿠키 등)를 통한 수집
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
            <li>
              <p className="mb-2 font-bold">개인정보의 수집 및 이용 목적</p>
              <p className="mb-2">
                ‘프롭인사이트’는 수집한 개인정보를 다음의 목적을 위해
                이용합니다.
              </p>
              <ul className="list-disc ml-5">
                <li>
                  회원 관리: 회원제 서비스 이용에 따른 본인 확인, 개인 식별,
                  불만 처리 등
                </li>
                <li>
                  서비스 제공: 맞춤형 서비스 제공, 서비스 관련 안내 및 정보 제공
                </li>
                <li>마케팅 및 광고: 이벤트 정보 제공, 맞춤형 광고 제공</li>
                <li>통계 분석: 서비스 이용 분석 및 통계</li>
              </ul>
            </li>
            <li>
              <p className="mb-2 font-bold">개인정보의 보유 및 이용 기간</p>
              <ul>
                <li>
                  ‘프롭인사이트’는 정보주체로부터 개인정보를 수집할 때 동의 받은
                  개인정보 보유․이용기간 또는 법령에 따른 개인정보 보유․이용기간
                  내에서 개인정보를 처리․보유합니다.
                </li>
                <li>
                  회원 탈퇴시 까지 개인정보를 보유하며, 개인정보 내용은 회원탈퇴
                  즉시 삭제 됩니다.
                </li>
              </ul>
            </li>
            <li>
              <p className="mb-2 font-bold">개인정보의 제3자 제공</p>
              <p className="mb-2">
                ‘프롭인사이트’는 이용자의 동의 없이는 개인정보를 외부에 제공하지
                않습니다. 다만, 아래의 경우에는 예외로 합니다.
              </p>
              <ul className="list-disc ml-5">
                <li>이용자가 사전에 동의한 경우</li>
                <li>법령의 규정에 의한 경우</li>
              </ul>
            </li>
            <li>
              <p className="font-bold mb-2">개인정보의 처리 위탁</p>
              <p>
                ‘프롭인사이트’는 원활한 개인정보 업무 처리를 위하여 외부 전문
                업체에 개인정보 처리를 위탁할 수 있습니다. 이 경우 위탁 업체 및
                위탁하는 업무의 내용을 사전에 고지하고 동의를 받습니다.
              </p>
            </li>
            <li>
              <p className="mb-2 font-bold">
                개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항
              </p>
              <ul className="flex flex-col gap-2">
                <li>
                  ‘프롭인사이트’는 개별적인 맞춤서비스를 제공하기 위해
                  이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를
                  사용합니다.
                </li>
                <li className="flex flex-col gap-2">
                  <p>
                    쿠키는 웹사이트를 운영하는데 이용되는 서버(https)가 이용자의
                    컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC
                    컴퓨터내의 하드디스크에 저장되기도 합니다.
                  </p>
                  <p className="ml-5">
                    -쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹
                    사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속
                    여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해
                    사용됩니다.
                  </p>
                  <p className="ml-5">
                    {
                      "-쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다."
                    }
                  </p>
                  <p className="ml-5">
                    -쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이
                    발생할 수 있습니다.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p className="mb-2 font-bold">
                개인정보의 보호를 위한 기술적/관리적 대책
              </p>
              <p className="mb-2 font-bold">
                ‘프롭인사이트’는 개인정보보호법 제29조에 따라 다음과 같이 안전성
                확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.
              </p>
              <ul className="list-disc ml-5">
                <li>
                  <p>개인정보 취급 직원의 최소화 및 교육</p>
                  개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화
                  하여 개인정보를 관리하는 대책을 시행하고 있습니다.
                </li>
                <li>
                  <p>해킹 등에 대비한 기술적 대책</p>
                  ‘프롭인사이트’는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보
                  유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인
                  갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을
                  설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
                </li>
                <li>
                  <p>개인정보의 암호화</p>
                  이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고
                  있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송
                  데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도
                  보안기능을 사용하고 있습니다.
                </li>
                <li>
                  <p>접속기록의 보관 및 위변조 방지</p>
                  개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관,
                  관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록
                  보안기능 사용하고 있습니다.
                </li>
                <li>
                  <p>개인정보에 대한 접근 제한</p>
                  개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의
                  부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여
                  필요한 조치를 하고 있으며 침입차단시스템을 이용하여
                  외부로부터의 무단 접근을 통제하고 있습니다.
                </li>
              </ul>
            </li>
            <li>
              <p className="mb-2 font-bold">
                개인정보관리책임자 및 담당자의 연락처
              </p>
              <p className="mb-2">
                개인정보 보호에 관한 문의사항은 아래의 연락처로 문의해주시기
                바랍니다.
              </p>
              <ul className="list-disc ml-5 flex flex-col gap-2">
                <li>개인정보관리책임자: [손종복]</li>
                <li>이메일: [contact@thingsw.com]</li>
                <li>전화번호: [032-889-0410]</li>
              </ul>
            </li>
            <li>
              <p className="mb-2 font-bold">기타</p>
              <p>
                본 개인정보처리방침은 법령, 정부 정책 또는 서비스의 운영 방침에
                따라 변경될 수 있으며, 변경 사항은 웹사이트 공지사항을 통해
                고지합니다.
              </p>
            </li>
            <li>
              <p className="mb-2 font-bold">
                정보주체의 권익침해에 대한 구제방법
              </p>

              <p className="mb-2">
                {
                  "아래의 기관은 (주)띵스워크샵 과는 별개의 기관으로서, ‘프롭인사이트’의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다."
                }
              </p>

              <p className="mb-2">
                개인정보 보호에 관한 문의사항은 아래의 연락처로 문의해주시기
                바랍니다.
              </p>

              <p>▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)</p>
              <p>– 소관업무 : 개인정보 침해사실 신고, 상담 신청</p>
              <p>– 홈페이지 : privacy.kisa.or.kr</p>
              <p>– 전화 : (국번없이) 118</p>
              <p className="mb-2">
                – 주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층
                개인정보침해신고센터
              </p>

              <p>▶ 개인정보 분쟁조정위원회</p>
              <p>
                – 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
              </p>
              <p>– 홈페이지 : www.kopico.go.kr</p>
              <p>– 전화 : (국번없이) 1833-6972</p>
              <p className="mb-2">
                – 주소 : (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층
              </p>

              <p className="mb-2">
                ▶ 대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)
              </p>
              <p className="mb-2">
                ▶ 경찰청 사이버안전국 : 182 (http://cyberbureau.police.go.kr)
              </p>
            </li>

            <p className="font-bold">시행일: [2024.09.10]</p>
          </ol>
        </div>
      </div>

      <Button
        type="button"
        color="primary"
        variant="contained"
        onClick={() => navigate("/signup")}
        btnStyle="px-[21px]"
      >
        <span className="text-[13px]">확인</span>
      </Button>
    </div>
  );
}

const PrivacyPolicyWithSidebar = withViewerTemplate(PrivacyPolicy);

export default {
  path: "/privacy-policy",
  element: <PrivacyPolicyWithSidebar />,
} as RouteObject;
