import { Button } from "@prop-insight/components";
import { RouteObject, useNavigate } from "react-router-dom";
import withViewerTemplate from "hoc/withViewerTemplate";

function ChangePasswordSuccess() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex flex-col items-center">
        <div className="flex flex-col max-w-[390px] w-full mt-[215px]">
          <div className="flex flex-col items-center">
            <span className="text-primary-main-color font-semibold text-2xl mb-[6px]">
              비밀번호 변경 완료
            </span>
            <span className="text-primary-main-color text-[13px]">
              성공적으로 비밀번호가 변경되었습니다.
            </span>
            <span className="text-primary-main-color text-[13px] mb-[47px]">
              다시 로그인해주세요.
            </span>

            <Button
              type="button"
              color="primary"
              variant="contained"
              onClick={() => navigate("/login")}
              btnStyle="px-[17px]"
              boxShadow
            >
              <span className="text-[13px]">로그인 페이지로 이동</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

const ChangePasswordSuccesssWithSidebar = withViewerTemplate(
  ChangePasswordSuccess
);

export default {
  path: "/change-password-success",
  element: <ChangePasswordSuccesssWithSidebar />,
} as RouteObject;
