// import useUser, { login, logout } from "@/hooks/useUser";
import {
  faFlag,
  faHouseChimney,
  faSitemap,
  faStar,
  faUserCircle,
  faStreetView,
} from "@fortawesome/free-solid-svg-icons";
import { LinkIcon } from "@prop-insight/components";
import { useLocation, useNavigate } from "react-router-dom";

import { Tooltip as ReactTooltip } from "react-tooltip";
import { Menu } from "types/types";

interface LeftMenuProps {
  onChangeMenu: (menu: Menu) => void;
  selectedMenu?: Menu;
  openDrawer?: boolean;
  onOpenDrawer: (open: boolean) => void;
}

const LeftMenu = ({
  onChangeMenu,
  selectedMenu,
  onOpenDrawer,
  openDrawer,
}: LeftMenuProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  // const user = useRouteLoaderData("root");
  const userToken = window.localStorage.getItem("token");
  return (
    <>
      <div className="bg-white !w-[70px] border-r border-background-sub-color">
        <div className="" />
        <div className="">
          <LinkIcon
            loginIcon
            icon={faUserCircle}
            title="계정정보"
            onClick={() => {
              if (!openDrawer) {
                onOpenDrawer(true);
              }
              // 정상 로그인되어 토큰값이 있으면 /users로 이동
              if (userToken && userToken !== "null") {
                if (location.pathname !== "/") {
                  navigate("/", { state: "users" });
                }
                onChangeMenu("users");
              } else {
                navigate("/login", {
                  replace: true,
                  state: "user",
                });
              }
            }}
            selected={selectedMenu === "users"}
            iconStyle={"w-[30px] h-[30px] p-[9px] mt-1"}
          />
        </div>

        <div className="mt-[42px]">
          <LinkIcon
            icon={faHouseChimney}
            title="홈"
            onClick={() => {
              onChangeMenu("home");
              navigate("/", { state: "home" });
              if (!openDrawer) {
                onOpenDrawer(true);
              }
            }}
            selected={selectedMenu === "home"}
            iconStyle={"w-[29px] h-[29px] mt-2 "}
          />
          <LinkIcon
            icon={faSitemap}
            title="비교분석"
            onClick={() => {
              navigate("/analysis", { state: "analysis" });
              onChangeMenu("analysis");
            }}
            selected={location.pathname === "/analysis"}
            iconStyle={"w-[34px] h-[30px] mt-2 pb-2"}
          />
          <LinkIcon
            icon={faFlag}
            title="신규택지"
            onClick={() => {
              if (location.pathname !== "/") {
                navigate("/", { state: "newland" });
              }
              if (!openDrawer) {
                onOpenDrawer(true);
              }
              onChangeMenu("newland");
            }}
            selected={selectedMenu === "newland"}
            iconStyle={"w-[25px] h-[25px] mt-2 pb-[6px]"}
          />

          <LinkIcon
            icon={faStreetView}
            title="개인택지"
            onClick={() => {
              if (location.pathname !== "/") {
                navigate("/", { state: "private-land-sites" });
              }
              if (!openDrawer) {
                onOpenDrawer(true);
              }
              onChangeMenu("private-land-sites");
            }}
            selected={selectedMenu === "private-land-sites"}
            iconStyle={"w-[25px] h-[25px] mt-2 pb-[6px]"}
          />

          <LinkIcon
            icon={faStar}
            title="즐겨찾기"
            onClick={() => {
              if (location.pathname !== "/") {
                navigate("/", { state: "bookmark" });
              }
              if (!openDrawer) {
                onOpenDrawer(true);
              }
              onChangeMenu("bookmark");
            }}
            selected={selectedMenu === "bookmark"}
            iconStyle={"w-[29px] h-[29px] mt-2"}
          />

          {/* <LinkIcon
            icon={faBook}
            title="부동산 정책"
            onClick={() => {
              if (location.pathname !== "/") {
                navigate("/");
              }
              if (!openDrawer) {
                onOpenDrawer(true);
              }
              onChangeMenu("realtypolicy");
            }}
            selected={selectedMenu === "realtypolicy"}
            iconStyle={"w-[25px] h-[25px] mt-2"}
          /> */}

          {/* <LinkIcon
            icon={faBook}
            title="수지분석"
            onClick={() => {
              navigate("/market-analysis", { state: "market-analysis" });
              onChangeMenu("analysis");
            }}
            selected={location.pathname?.startsWith("/market-analysis")}
            iconStyle={"w-[25px] h-[25px] mt-2"}
          /> */}
        </div>
      </div>

      <ReactTooltip id="login-tooltip" place="right" />
    </>
  );
};

export default LeftMenu;
