import { faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LandSite } from "@prop-insight/database";
import _ from "lodash";
import moment from "moment";
import { useMap } from "providers/MapProvider";
import { Fragment } from "react";
import { LandSiteDetail } from "types/types";

interface NewLandSiteTableProps {
  landSites: LandSite[];
  onClickDetailPanel: (landSite: LandSiteDetail) => void;
}

const NewLandSiteTable = ({
  landSites,
  onClickDetailPanel,
}: NewLandSiteTableProps) => {
  const { setMapState } = useMap();
  return (
    <table className="w-[100%] border-collapse">
      <tbody>
        {_.map(landSites, (landSite) => {
          let status = "접수예정";
          let bcColor = "#FF9737";
          const endDate = landSite.applicationEndDate
            ? moment(landSite.applicationEndDate)
            : landSite.contractEndDate
            ? moment(landSite.contractEndDate)
            : moment(landSite.lotteryDate);
          const startDate = landSite.applicationStartDate
            ? moment(landSite.applicationStartDate)
            : landSite.contractStartDate
            ? moment(landSite.contractStartDate)
            : moment(landSite.createdAt);
          const now = moment();
          if (now.isBetween(startDate, endDate)) {
            status = "접수중";
            bcColor = "#FF6056";
          } else if (now.isAfter(endDate)) {
            status = "접수마감";
            bcColor = "#A5ABB3";
          }
          return (
            <Fragment key={`land-site-${landSite.id}`}>
              <tr
                className="border-b-[1px] border-[#F3F2F2] hover:bg-background-main-color cursor-pointer"
                onClick={() => {
                  onClickDetailPanel(landSite);
                }}
              >
                <td className="flex items-center h-[79px] pr-[7px]">
                  <div
                    style={{ backgroundColor: bcColor }}
                    className={
                      "text-[10px] rounded flex items-center justify-center h-[31px] text-white mr-[3px] px-[2px] min-w-[41px] max-w-[41px]"
                    }
                  >
                    {status}
                  </div>
                </td>
                <td className={"text-[12px] h-[31px] text-center pr-[7px]"}>
                  {landSite.purpose}
                </td>
                <td className={"text-[12px] h-[31px] pl-[2px] pr-[7.5px]"}>
                  <p className="font-semibold whitespace-nowrap text-ellipsis max-w-[181px] overflow-hidden">
                    {landSite.title}
                  </p>
                  <p className="max-w-[181px] whitespace-nowrap text-ellipsis overflow-hidden">
                    {landSite.address}
                  </p>
                </td>
                <td
                  className={
                    "text-[12px] w-[75px]  h-[31px] text-center pr-[7px]"
                  }
                >
                  {landSite.authority}
                </td>
                <td className="h-[31px] pl-[5px]">
                  <FontAwesomeIcon
                    icon={faMapLocationDot}
                    size="lg"
                    onClick={(event) => {
                      event.stopPropagation();
                      if (landSite.imageBounds.length > 2) {
                        setMapState({
                          bounds: JSON.parse(landSite.imageBounds),
                          fileId: landSite.fileId,
                        });
                      } else {
                        setMapState({
                          lat: landSite.lat,
                          lng: landSite.lng,
                        });
                      }
                    }}
                  />
                </td>
              </tr>
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
};

export default NewLandSiteTable;
