import axiosInst from "utils/axiosInst";

export const getFavorite = async () => {
  return (await axiosInst.get("/jijuk/favorite")).data.favorite;
};

export const getMyFavorite = async (page?: number) => {
  if (page) {
    return (await axiosInst.get(`/users/favorite-lands?page=${page}`)).data;
  } else {
    return (await axiosInst.get(`/users/favorite-lands`)).data.lands;
  }
};

export const deleteFavorite = async (landId: number) => {
  return await axiosInst.delete(`/users/favorite-lands/${landId}`);
};

export const updateFavoriteMemo = async (bookmarkId: number, memo: string) => {
  return await axiosInst.put(`/users/favorite-lands/${bookmarkId}`, {
    memo: memo,
  });
};
