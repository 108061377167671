import { Button } from "@prop-insight/components";
import { RouteObject, useNavigate } from "react-router-dom";
import withViewerTemplate from "hoc/withViewerTemplate";
import { useCallback, useState } from "react";
import axiosInst from "utils/axiosInst";
import axios from "axios";

function DeleteAccount() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleDeleteAccount = useCallback(async () => {
    setLoading(true);
    try {
      // 계정삭제 API를 호출합니다.
      await axiosInst.delete("/users/");
      // 계정삭제 성공하면 로그아웃 처리
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("refreshToken");
      navigate("/delete-account-success");
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        console.log("error", error.response.data);
      }
    } finally {
      setLoading(false);
    }
  }, [navigate]);

  return (
    <div>
      <div className="flex flex-col items-center">
        <div className="flex flex-col max-w-[390px] w-full mt-[215px]">
          <div className="flex flex-col items-center">
            <span className="text-primary-main-color font-semibold text-2xl mb-[6px]">
              계정 탈퇴
            </span>
            <span className="text-primary-main-color text-[13px] text-center">
              계정을 탈퇴하면 계정에 등록된 모든 데이터가 삭제되며, <br />
              삭제된 데이터는 복구가 불가능합니다.
            </span>

            <div className="flex mt-[46px]">
              <Button
                type="button"
                color="primary"
                variant="contained"
                // 계정정보패널로 이동
                onClick={() => navigate("/", { state: "users" })}
                btnStyle="px-[19px] mr-[10px]"
              >
                <span className="text-[16px]">돌아가기</span>
              </Button>
              <Button
                type="button"
                color="primary"
                variant="outlined"
                disabled={loading}
                onClick={handleDeleteAccount}
                btnStyle="px-[19px] min-w-[103px]"
              >
                <span className="text-[16px]">계정탈퇴</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const DeleteAccountWithSidebar = withViewerTemplate(DeleteAccount);

export default {
  path: "/delete-account",
  element: <DeleteAccountWithSidebar />,
} as RouteObject;
