export const LandSiteAuthority: { [key: string]: string } = {
  all: "전체",
  seoul: "서울주택도시공사",
  busan: "부산도시공사",
  daegu: "대구도시공사",
  incheon: "인천도시공사",
  gwangju: "광주광역시도시공사",
  daejeon: "대전도시공사",
  ulsan: "울산도시공사",
  gyeonggi: "경기도주택도시공사",
  gangwon: "강원도개발공사",
  chungbuk: "충북개발공사",
  chungnam: "충청남도개발공사",
  jeonbuk: "전북개발공사",
  jeonnam: "전남개발공사",
  gyeongbuk: "경상북도개발공사",
  gyeongnam: "경상남도개발공사",
  jeju: "제주특별자치도개발공사",
  hug: "한국토지주택공사",
};

export const LandSitePurpose: { [key: string]: string } = {
  all: "전체",
  공동주택: "공동주택용지",
  단독주택: "단독주택용지",
  상업: "상가용지",
  산업: "공장용지",
  주차: "주차장 용지",
  etc: "기타",
};

export const LandStatus: { [key: string]: string } = {
  all: "전체",
  progress: "접수 중",
  planned: "접수 예정",
  completed: "접수 마감",
};

export const RealtyPolicyDepartment: { [key: string]: string } = {
  all: "전체",
  FSC: "금융위원회",
  MLIT: "국토교통부",
  MOEF: "기획재정부",
};

export const RealtyPolicyRegion: { [key: string]: string } = {
  all: "전체",
  seoul: "서울",
  busan: "부산",
  daegu: "대구",
  incheon: "인천",
  gwangju: "광주",
  daejeon: "대전",
  ulsan: "울산",
  sejong: "세종",
  gyeonggi: "경기",
  gangwon: "강원",
  chungbuk: "충북",
  chungnam: "충남",
  jeonbuk: "전북",
  jeonnam: "전남",
  gyeongbuk: "경북",
  gyeongnam: "경남",
  jeju: "제주",
};

export const PrivateLandSiteCategory: { [key: string]: string } = {
  ALL: "전체",
  LAND: "대지",
  FOREST: "임야",
  FACTORY: "공장",
  RELIGIOUS: "종교",
};

export const PrivateLandUseZone: { [key: string]: string } = {
  ALL: "전체",
  GENERAL_COMMERCIAL: "일반상업지역",
  CENTRAL_COMMERCIAL: "중심상업지역",
  NEIGHBORHOOD_COMMERCIAL: "근린상업지역",
  SEMI_RESIDENTIAL: "준주거지역",
  GENERAL_RESIDENTIAL_3RD: "3종일반주거지역",
  GENERAL_RESIDENTIAL_2ND: "2종일반주거지역",
  GENERAL_RESIDENTIAL_1ST: "1종일반주거지역",
  SEMI_INDUSTRIAL: "준공업지역",
  INDUSTRIAL: "공업지역",
  PLANNED_MANAGEMENT: "계획관리지역",
};

export const PrivateLandSido: { [key: string]: string } = {
  "10": "전체", // 코드는 00이나 전체를 최상단에 올리기 위해 10으로 변경
  "11": "서울특별시",
  "21": "부산광역시",
  "22": "대구광역시",
  "23": "인천광역시",
  "24": "광주광역시",
  "25": "대전광역시",
  "26": "울산광역시",
  "29": "세종특별자치시",
  "31": "경기도",
  "32": "강원도",
  "33": "충청북도",
  "34": "충청남도",
  "35": "전라북도",
  "36": "전라남도",
  "37": "경상북도",
  "38": "경상남도",
  "39": "제주특별자치도",
};

export const PrivateLandUseDistrict: { [key: string]: string } = {
  NONE: "없음",
  DISTRICT_UNIT_PLANNING_ZONE: "지구단위계획구역",
  FIRE_PROTECTION_ZONE: "방화지구",
  URBAN_SCENIC_ZONE: "시가지경관지구",
  CULTURAL_ZONE: "문화지구",
};

export const PrivateLandRoad: { [key: string]: string } = {
  LESS_THAN_6M: "폭 6미터 미만",
  AT_LEAST_6M: "폭 6미터 이상",
  LESS_THAN_8M: "폭 8미터 미만",
  AT_LEAST_8M: "폭 8미터 이상",
  LESS_THAN_12M: "폭 12미터 미만",
  AT_LEAST_12M: "폭 12미터 이상",
  LESS_THAN_25M: "폭 25미터 미만",
  AT_LEAST_25M: "폭 25미터 이상",
};

export const PrivateLandMainUse: { [key: string]: string } = {
  RETAIL: "상가",
  BUSINESS: "업무",
  FACTORY: "공장",
  RESIDENTIAL: "주거",
  WAREHOUSE: "창고",
  RELIGIOUS: "종교",
  CULTURAL_ASSEMBLY: "문화집회",
  EDUCATIONAL: "교육",
};
