import { Button } from "@prop-insight/components";
import { Post, File } from "@prop-insight/database";
import useQuery from "hooks/useQuery";
import JSZip from "jszip";
import moment from "moment";
import "moment/locale/ko";
import { useCallback } from "react";
import { getPost } from "utils/api/postApi";
import axiosInst from "utils/axiosInst";

interface PostDetailPanelProps {
  post: Post;
}

const PostDetailPanel = ({ post }: PostDetailPanelProps) => {
  const getPostCallBack = useCallback(() => {
    return getPost(post.id);
  }, [post.id]);

  const { data } = useQuery<Post & { files: File[] }>(getPostCallBack);

  return (
    <>
      <div className="mx-5 my-[70px] mb-[10px] text-lg font-bold leading-[1.125rem]">
        상세 정보
      </div>
      <div className="w-[390px] h-[540px] flex flex-col gap-[12px] mx-auto rounded-[10px] bg-[#F3F2F2] px-[12px] py-[18px]">
        <div className="flex items-center gap-[5px]">
          <div className="w-[68px] h-[18px] text-center text-h3 rounded border-[1px] border-[#333333]">
            제목
          </div>
          <div className=" text-xs font-normal">{post.title}</div>
        </div>
        <div className="flex items-center gap-[5px]">
          <div className="w-[68px] h-[18px] text-center text-h3 rounded border-[1px] border-[#333333]">
            작성자
          </div>
          <div className=" text-xs font-normal">관리자</div>
        </div>
        <div className="flex items-center gap-[5px]">
          <div className="w-[68px] h-[18px] text-center text-h3 rounded border-[1px] border-[#333333]">
            작성날짜
          </div>
          <div className=" text-xs font-normal">
            {moment(post.createdAt).format("YYYY.MM.DD") +
              moment(post.createdAt).format("dddd").slice(0, 1) +
              " " +
              moment(post.createdAt).format("hh:mm")}
          </div>
        </div>
        <div className="flex items-center gap-[5px]">
          <div className="w-[68px] h-[18px] text-center text-h3 rounded border-[1px] border-[#333333]">
            내용
          </div>
          <div
            className=" text-xs font-normal"
            dangerouslySetInnerHTML={{ __html: post.body }}
          ></div>
        </div>
      </div>

      <div className="flex mt-[16px] mx-5">
        <Button
          type="button"
          color="primary"
          variant="outlined"
          btnStyle="w-full h-[27px]"
          boxShadow
          withRightIcon="fileIcon"
          disabled={data?.files?.length === 0}
          onClick={async () => {
            if (!data) return;

            if (data.files && data.files?.length !== 0) {
              const files = [];

              for (const file of data.files) {
                const resp = await axiosInst.get(`/files/${file.id}`);
                const newFile = {
                  filename: resp.data.filename,
                  mimeType: resp.data.mimeType,
                  url: resp.data.url,
                };
                files.push(newFile);
              }

              // JSZip 인스턴스 생성
              const zip = new JSZip();

              // 파일 다운로드 및 zip에 추가
              const promises = files.map(async (fileInfo, index) => {
                const { filename, url } = fileInfo;
                const fileName = `${filename}`;

                try {
                  const response = await fetch(url);

                  // blob 타입으로 다운로드
                  const blob = await response.blob();
                  zip.file(fileName, blob, { binary: true }); // binary 옵션을 설정하여 이진 파일로 처리
                } catch (error) {
                  console.error(`파일 다운로드 오류: ${error}`);
                }
              });

              // 모든 파일 다운로드 및 압축 후 zip 파일 다운로드
              await Promise.all(promises);
              zip.generateAsync({ type: "blob" }).then((content) => {
                const a = document.createElement("a");
                const url = window.URL.createObjectURL(content);
                a.href = url;
                a.download = "Prop-Insight.zip";
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
              });
            }
          }}
        >
          <span>첨부파일 다운로드</span>
        </Button>
      </div>
    </>
  );
};

export default PostDetailPanel;
