import { LoadingSpinner } from "@prop-insight/components";
import { MarketAnalysisIncomeType } from "@prop-insight/database";
import { useMutation } from "@tanstack/react-query";
import { Field, FormikProvider, useFormik } from "formik";
import axiosInst from "utils/axiosInst";
import Select from "./Select";
import _ from "lodash";

export type MarketAnalysisIncomeCreationParams = {
  id?: number;
  type: MarketAnalysisIncomeType;
  name: string;
  contractArea: number;
  pricePerUnitArea: number;
  count: number;
  memo?: string;
};

export interface MarketAnalysisIncomeProps {
  marketAnalysisId?: string;
  initialValues?: MarketAnalysisIncomeCreationParams;
  onSuccess?: () => void;
}

export default function MarketAnalysisIncome({
  marketAnalysisId,
  initialValues,
  onSuccess,
}: MarketAnalysisIncomeProps) {
  const formik = useFormik({
    initialValues: initialValues
      ? (_.omit(initialValues, [
          "id",
          "marketAnalysisId",
          "createdAt",
          "updatedAt",
        ]) as MarketAnalysisIncomeCreationParams)
      : ({
          type: "LIVING_FACILITY",
          name: "",
          contractArea: 0,
          pricePerUnitArea: 0,
          count: 0,
        } as MarketAnalysisIncomeCreationParams),
    onSubmit: (values) => {
      incomeMutation.mutate(values);
    },
  });
  const { values, handleSubmit, setFieldValue, resetForm } = formik;
  const incomeMutation = useMutation({
    mutationFn: (data: MarketAnalysisIncomeCreationParams) => {
      if (initialValues) {
        return axiosInst.put(
          `/market-analysis-incomes/${initialValues.id}`,
          data
        );
      }
      return axiosInst.post(
        `/market-analysis/${marketAnalysisId}/incomes`,
        data
      );
    },
    onSuccess: () => {
      if (!initialValues) {
        resetForm();
      }
      onSuccess?.();
    },
  });
  return (
    <FormikProvider value={formik}>
      <tr>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
          <button
            type="submit"
            className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => handleSubmit()}
            disabled={incomeMutation.isPending}
          >
            {incomeMutation.isPending && <LoadingSpinner />}
            {initialValues ? "수정" : "추가"}
          </button>{" "}
          {initialValues && (
            <button
              type="submit"
              className="inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              onClick={() => {}}
            >
              {incomeMutation.isPending && <LoadingSpinner />}
              삭제
            </button>
          )}
        </td>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
          <Select
            items={[
              { id: "LIVING_FACILITY", name: "근생" },
              { id: "OFFICETEL", name: "오피스텔" },
              { id: "APARTMENT", name: "아파트" },
            ]}
            selected={values.type}
            onSelected={(id) => {
              setFieldValue("type", id);
            }}
          />
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <Field
            type="text"
            id="name"
            name="name"
            className="block w-full rounded-md border-0 py-1.5 pl-4 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="1층"
            aria-describedby="price-currency"
          />
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <div className="relative mt-2 rounded-md shadow-sm sm:col-span-1 sm:mt-0">
            <Field
              type="number"
              id="contractArea"
              name="contractArea"
              className="block w-full rounded-md border-0 py-1.5 pl-4 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="계약면적"
              aria-describedby="price-currency"
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span className="text-gray-500 sm:text-sm" id="price-currency">
                평
              </span>
            </div>
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <div className="relative mt-2 rounded-md shadow-sm sm:col-span-1 sm:mt-0">
            <Field
              type="text"
              className="block w-full rounded-md border-0 py-1.5 pl-4 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="금액"
              aria-describedby="price-currency"
              readOnly
              value={_.round(
                values.contractArea * values.pricePerUnitArea * values.count
              )}
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span className="text-gray-500 sm:text-sm" id="price-currency">
                천원
              </span>
            </div>
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {/* 자기자본 */}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {/* 조달금액 */}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {/* 분양불 */}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {/* 정산불 */}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <div className="relative mt-2 rounded-md shadow-sm sm:col-span-1 sm:mt-0">
            <Field
              type="number"
              id="pricePerUnitArea"
              name="pricePerUnitArea"
              className="block w-full rounded-md border-0 py-1.5 pl-4 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="평단분양가"
              aria-describedby="price-currency"
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span className="text-gray-500 sm:text-sm" id="price-currency">
                천원
              </span>
            </div>
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <div className="relative mt-2 rounded-md shadow-sm sm:col-span-1 sm:mt-0">
            <Field
              type="number"
              id="count"
              name="count"
              className="block w-full rounded-md border-0 py-1.5 pl-4 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="수량"
              aria-describedby="price-currency"
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span className="text-gray-500 sm:text-sm" id="price-currency">
                세대
              </span>
            </div>
          </div>
        </td>

        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {_.round(values.contractArea * values.pricePerUnitArea)} 천원
        </td>

        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
      </tr>
    </FormikProvider>
  );
}
