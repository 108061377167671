import { useEffect, useState } from "react";
import LeftMenu from "components/LeftMenu";
import { useLocation } from "react-router-dom";
import ToastPop from "components/ToastPop";
import { Menu } from "types/types";
import { useIsMobile } from "hooks/useIsMobile";
import MobilePage from "components/MobilePage";

export interface ScreenDefaultProps {
  selectedMenu: Menu;
  openDrawer: boolean;
  onOpenDrawer: (open: boolean) => void;
  onOpenToast: (open: boolean) => void;
  onChangeMenu?: (menu: Menu) => void;
}

const withViewerTemplate =
  (WrappedComponent: React.FC<any>) => (props: any) => {
    const location = useLocation();
    const state = location.state;
    const [selectedMenu, setSelectedMenu] = useState<Menu>(
      location.pathname !== "/" && location.pathname !== "/analysis"
        ? "users"
        : state || "home"
    );
    const [openDrawer, setOpenDrawer] = useState(true);
    const [showToast, setShowToast] = useState(false);
    const isMobile = useIsMobile();

    useEffect(() => {
      if (showToast) {
        const timer = setTimeout(() => {
          setShowToast(false);
        }, 5000);

        return () => {
          clearTimeout(timer);
        };
      }
    }, [showToast]);

    if (isMobile) {
      return <MobilePage />;
    }

    return (
      <div>
        <div className="fixed left-0 flex z-50 h-full">
          <LeftMenu
            selectedMenu={selectedMenu}
            onChangeMenu={setSelectedMenu}
            onOpenDrawer={setOpenDrawer}
            openDrawer={openDrawer}
          />
        </div>

        <div className="relative" style={{ height: "100%", marginLeft: 70 }}>
          <WrappedComponent
            selectedMenu={selectedMenu}
            openDrawer={openDrawer}
            onOpenDrawer={setOpenDrawer}
            onOpenToast={setShowToast}
            onChangeMenu={setSelectedMenu}
            {...props}
          />
        </div>
        {showToast && <ToastPop />}
      </div>
    );
  };

export default withViewerTemplate;
