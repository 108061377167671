import {
  PrivateLandSido,
  PrivateLandSiteCategory,
  PrivateLandUseZone,
} from "contans/SelectItems";
import { useCallback, useState } from "react";
import SelectBox from "./SelectBox";
import _ from "lodash";
import { useQuery } from "@tanstack/react-query";
import { getPrivateLandSite } from "utils/api/privateLandSiteApi";
import { LoadingSpinner } from "@prop-insight/components";
import Pagination from "./Pagination";
import PrivateLandSiteTable from "./PrivateLandSiteTable";
import axiosInst from "utils/axiosInst";

export interface PrivateLandSitesPanelProps {
  openPrivateLandSiteDetail: (privateLandSite: any) => void;
}

export default function PrivateLandSitesPanel({
  openPrivateLandSiteDetail,
}: PrivateLandSitesPanelProps) {
  const [currentCategory, setCurrentCategory] = useState<string>();
  const [currentUseZone, setCurrentUseZone] = useState<string>();
  const [currentSido, setCurrentSido] = useState<string>();
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isLoading } = useQuery({
    queryKey: [
      "privateLandSites",
      currentPage,
      currentCategory,
      currentUseZone,
      currentSido,
    ],
    queryFn: () =>
      getPrivateLandSite({
        currentCategory,
        currentUseZone,
        currentSido,
        currentPage,
      }),
  });

  // 상세정보 api호출
  const handlePrivateLandSiteDetail = useCallback(
    async (id: number) => {
      try {
        const resp = await axiosInst.get(`/private-land-sites/${id}`);
        openPrivateLandSiteDetail(resp.data);
      } catch (error) {
      } finally {
      }
    },
    [openPrivateLandSiteDetail]
  );

  return (
    <div className="mx-[20px]">
      <div className="flex items-center mb-[23px]">
        <span className="text-text-darkgray text-title2 mr-[15px]">
          개인택지
        </span>
      </div>

      {/* 셀렉트 */}
      <div className="flex justify-between mb-[20px]">
        <SelectBox
          defaultValue="지목"
          keyValue={currentCategory ?? "지목"}
          className="min-w-[125px]"
          textDivClassName="-ml-[10px]"
          MenuClassName="w-[125px]"
          items={_.map(PrivateLandSiteCategory, (val, key) => {
            return {
              key: key,
              value: val,
            };
          })}
          onChange={(e) => {
            setCurrentPage(1);
            setCurrentCategory(e.key);
          }}
        />
        <SelectBox
          defaultValue="용도지역"
          keyValue={currentUseZone ?? "용도지역"}
          className="min-w-[125px] pl-[8px]"
          textDivClassName="-ml-[10px]"
          textClassName="min-w-[125px] text-center"
          MenuClassName="w-[168px]"
          longTextItem
          items={_.map(PrivateLandUseZone, (val, key) => {
            return {
              key: key,
              value: val,
            };
          })}
          onChange={(e) => {
            setCurrentPage(1);

            setCurrentUseZone(e.key);
          }}
        />
        <SelectBox
          defaultValue="시도"
          keyValue={currentSido ?? "시도"}
          className="min-w-[125px] pl-[8px]"
          textDivClassName="-ml-[10px]"
          textClassName="min-w-[125px] text-center"
          MenuClassName="w-[168px]"
          longTextItem
          items={_.map(PrivateLandSido, (val, key) => {
            return {
              key: key,
              value: val,
            };
          })}
          onChange={(e) => {
            setCurrentPage(1);

            setCurrentSido(e.key);
          }}
        />
      </div>

      {/* 표 */}
      {isLoading ? (
        <div className="flex items-center justify-center mt-[325px]">
          <LoadingSpinner panel />
        </div>
      ) : (
        data?.privateLandSites && (
          <div>
            <PrivateLandSiteTable
              privateLandSites={data.privateLandSites}
              onClickDetailPanel={(privateLandSite) =>
                handlePrivateLandSiteDetail(privateLandSite.id)
              }
            />
          </div>
        )
      )}

      {/* pagination */}
      <Pagination
        totalPages={data?.count ? Math.ceil(data.count / 10) : 1}
        currentPage={currentPage}
        onChangePage={(page) => {
          setCurrentPage(page);
        }}
      />
    </div>
  );
}
