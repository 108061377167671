import { ROWS_PER_PAGE } from "contans/List";
import { LandSiteAuthority } from "contans/SelectItems";
import axiosInst from "utils/axiosInst";

export const getLandSitePlanned = async () => {
  return (await axiosInst.get("/land-sites/views?dateStatus=planned")).data;
};

export const getLandSiteCompleted = async () => {
  return (await axiosInst.get("/land-sites/views?dateStatus=completed")).data;
};

interface getLandSiteParams {
  currentPage: number;
  isNearApplicationEndDate: boolean;
  currentLandStatus: string | undefined;
  currentLandSiteAuthority: string | undefined;
  currentLandSitePurpose: string | undefined;
}

export const getLandSite = async ({
  currentLandSiteAuthority,
  currentLandStatus,
  currentPage,
  isNearApplicationEndDate,
  currentLandSitePurpose,
}: getLandSiteParams) => {
  return (
    await axiosInst.get(
      `/land-sites?page=${currentPage}&limit=${ROWS_PER_PAGE}&isNearApplicationEndDate=${isNearApplicationEndDate}&dateStatus=${
        currentLandStatus || "all"
      }&authority=${
        currentLandSiteAuthority === "all" ||
        currentLandSiteAuthority === undefined
          ? ""
          : LandSiteAuthority[currentLandSiteAuthority]
      }&status=GENERAL&status=COMPLETED&purpose=${
        currentLandSitePurpose === "all" || currentLandSitePurpose === undefined
          ? ""
          : currentLandSitePurpose
      }`
    )
  ).data;
};

export const getLandSiteById = async (id: number) => {
  return (await axiosInst.get(`/land-sites/${id}`)).data;
};
