import React, { useCallback, useMemo } from "react";

import { useLocation, Navigate, useRouteLoaderData } from "react-router-dom";

import axiosInst from "utils/axiosInst";
import { CLIENT_ID } from "utils/constants";
import useLocalStorage from "utils/useLocalStorage";

interface AuthContextType {
  signIn: (username: string, password: string) => Promise<void>;
  signUp: (username: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
}

let AuthContext = React.createContext<AuthContextType>(null!);

export default function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [, setToken] = useLocalStorage<string | null>("token", null);
  const [, setRefreshToken] = useLocalStorage<string | null>(
    "refreshToken",
    null
  );

  let signIn = useCallback(
    async (username: string, password: string) => {
      const resp = await axiosInst.post(
        "/users/login",
        {
          username,
          password,
        },
        {
          headers: {
            Authorization: `Basic ${btoa(`${CLIENT_ID}:`)}`,
          },
        }
      );
      console.log("signin", resp.data);
      setToken(resp.data.access_token);
      setRefreshToken(resp.data.refresh_token);
    },
    [setRefreshToken, setToken]
  );

  let signUp = useCallback(async (username: string, password: string) => {
    await axiosInst.post("/users/signup", {
      username,
      password,
    });
  }, []);

  let signOut = useCallback(async () => {}, []);

  let value = useMemo(
    () => ({ signIn, signOut, signUp }),
    [signIn, signOut, signUp]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  const user = useRouteLoaderData("root");
  let location = useLocation();

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  return children;
}
