import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

interface VrTapProps {
  vrId: string | null;
}

const VrTap = ({ vrId }: VrTapProps) => {
  const userToken = window.localStorage.getItem("token");

  if (vrId === null) {
    return (
      <div className="w-full h-[222px] bg-[#F3F2F2] flex flex-col justify-center items-center">
        <p className=" text-center font-semibold text-title text-primary-main-color mb-3">
          VR이 등록되지 <br /> 않았습니다.
        </p>
        <p className=" text-[13px] text-primary-main-color">
          관리자의 VR 등록이 필요합니다.
        </p>
      </div>
    );
  }

  return (
    <div className="relative">
      {(userToken === null || userToken === "null") && (
        <div
          className="absolute left-0 top-0 w-full h-full z-10"
          data-tooltip-id={
            userToken === null || userToken === "null" ? "info-tooltip" : ""
          }
          data-tooltip-content={"로그인 상태에서 VR을 확인할 수 있습니다."}
        >
          <ReactTooltip
            id="info-tooltip"
            place="right"
            style={{
              position: "fixed",
              right: 0,
              backgroundColor: "#16B5B8",
              zIndex: 999,
              padding: "1px 8px",
              borderRadius: 4,
            }}
          />
        </div>
      )}
      <iframe
        width="100%"
        height="222"
        src={`https://my.matterport.com/show/?m=${vrId}`}
        allow="xr-spatial-tracking"
        allowFullScreen
        title="vr"
      ></iframe>
    </div>
  );
};

export default VrTap;
