import { useState, MutableRefObject } from "react";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import RulerControl from "@mapbox-controls/ruler";
import * as turf from "@turf/turf";

import RadiusMode from "../utils/radiusMode";

interface Distance {
  distance: number;
  position: { x: number; y: number };
}

const draw = new MapboxDraw({
  displayControlsDefault: false,
  controls: {
    polygon: true,
  },
  modes: {
    ...MapboxDraw.modes,
    radius_mode: RadiusMode,
  },
});

const ruler = new RulerControl();

const useToolBox = (map: MutableRefObject<any>) => {
  const [area, setArea] = useState<number>(0);
  const [fromRadius, setFromRadius] = useState<number[]>([]);
  const [isRadiusMode, setIsRadiusMode] = useState<boolean>(false);
  const [radiusDistance, setRadiusDistance] = useState<Distance>({
    distance: 0,
    position: { x: 0, y: 0 },
  });

  const handleRulerOn = () => {
    drawDataReset();
    draw.deleteAll();

    const polygonButton = document.querySelector(".mapbox-gl-draw_polygon");

    polygonButton?.classList.remove("active");

    const radiusButton = document.querySelector(".mapboxgl-ctrl-radius");

    radiusButton?.classList.remove("active");
  };

  const rulerDeactivate = () => {
    if (ruler.isActive) {
      ruler.deactivate();
    }
  };

  const drawDataReset = () => {
    setArea(0);
    setFromRadius([]);
    setRadiusDistance({ distance: 0, position: { x: 0, y: 0 } });
  };

  const drawInit = () => {
    map.current.addControl(draw, "top-right");

    map.current.on("draw.modechange", handlerChangeMode);

    if (document.querySelector(".mapboxgl-ctrl-radius")) {
      return;
    }

    const mapboxButtonDiv = document
      .querySelector(".mapboxgl-ctrl-top-right")
      ?.querySelector(".mapboxgl-ctrl-group");

    const radiusButton = document.createElement("button");
    radiusButton.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-radius";
    radiusButton.type = "button";
    radiusButton.onclick = handleRadiusMode;

    mapboxButtonDiv?.appendChild(radiusButton);
  };

  const handleRadiusMode = () => {
    draw.deleteAll();
    rulerDeactivate();

    const radiusButton = document.querySelector(".mapboxgl-ctrl-radius");

    radiusButton?.classList.toggle("active");

    if (radiusButton?.classList.contains("active")) {
      drawDataReset();

      setIsRadiusMode(true);
      draw.changeMode("radius_mode");
    } else {
      setIsRadiusMode(false);
      draw.changeMode("simple_select");
    }
  };

  const onMouseMove = (e: any) => {
    if (!isRadiusMode) return;
    if (draw.getMode() === "radius_mode" && fromRadius.length > 0) {
      const to = [e.lngLat.lng, e.lngLat.lat];

      const distance = Math.round(
        turf.distance(fromRadius, to, { units: "meters" })
      );

      setRadiusDistance({
        distance,
        position: { x: e.point.x, y: e.point.y },
      });
    }
  };

  const handlerChangeMode = () => {
    rulerDeactivate();

    const polygonButton = document.querySelector(".mapbox-gl-draw_polygon");
    const radiusButton = document.querySelector(".mapboxgl-ctrl-radius");

    if (polygonButton?.classList.contains("active")) {
      const data = draw.getAll();

      const radiusId = data.features.find(
        (f: any) => f.properties?.meta === "radius"
      )?.id;

      if (radiusId && typeof radiusId === "string") {
        draw.delete(radiusId);
        setRadiusDistance({ distance: 0, position: { x: 0, y: 0 } });
      }

      if (data.features.length > 1 && !radiusId) {
        draw.delete(draw.getAll().features[0].id as string);
        setArea(0);
      }

      radiusButton?.classList.remove("active");
    } else if (draw.getMode() === "simple_select") {
      radiusButton?.classList.remove("active");
    }
  };

  const updateArea = (e: any) => {
    const data = draw.getAll();

    const mode = draw.getMode();
    if (mode === "draw_polygon") {
      if (data.features.length > 0) {
        const area = turf.area(data);

        // Restrict the area to 2 decimal points.
        const rounded_area = Math.round(area * 10) / 10;

        setArea(rounded_area);
      }
    } else if (mode === "radius_mode") {
      setFromRadius([e.lngLat.lng, e.lngLat.lat]);
    } else if (fromRadius.length > 0) {
      const to = [e.lngLat.lng, e.lngLat.lat];

      const distance = Math.round(
        turf.distance(fromRadius, to, { units: "meters" })
      );

      setRadiusDistance({
        distance,
        position: { x: e.point.x, y: e.point.y },
      });

      setFromRadius([]);

      const button = document.querySelector(".mapboxgl-ctrl-radius");

      button?.classList.remove("active");
    }
  };

  return {
    drawInit,
    area,
    fromRadius,
    radiusDistance,
    ruler,
    draw,
    updateArea,
    onMouseMove,
    handleRulerOn,
  };
};

export default useToolBox;
