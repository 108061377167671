import { useCallback, useEffect, useState } from "react";
import {
  AddContentButton,
  Button,
  LoadingSpinner,
} from "@prop-insight/components";
import { BookMarkItem } from "types/types";
import { BuildingInfo } from "@prop-insight/database";
import useQuery from "hooks/useQuery";
import { getBuildingInfo } from "utils/api/buildingInfoApi";

interface BookmarkDetailPanelProps {
  bookMarkItem: BookMarkItem;
}
const BookmarkDetailPanel = ({ bookMarkItem }: BookmarkDetailPanelProps) => {
  const [buildingInfoLimit, setBuildingInfoLimit] = useState(1);
  const { data: buildingInfo, isLoading } = useQuery<BuildingInfo[]>(
    useCallback(() => getBuildingInfo(bookMarkItem.pnu), [bookMarkItem.pnu])
  );

  const handleBuildingInfoLimit = () => {
    setBuildingInfoLimit((prev) => prev + 1);
  };

  useEffect(() => {
    setBuildingInfoLimit(1);
  }, [bookMarkItem]);

  return (
    <div className="max-h-screen overflow-auto">
      <div className=" mb-[23px]">
        <div className="flex flex-col mt-[70px]">
          <h1 className="text-title2 mr-[11px] ml-[20px] mb-[7px] text-text-darkgray">
            토지 정보
          </h1>
          <div className="h-[70px] w-full bg-background-main-color flex justify-center items-center mb-[18px]">
            <div className="mr-[8px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="32"
                viewBox="0 0 24 32"
                fill="none"
              >
                <path
                  d="M10.7668 31.3544C1.68563 18.1894 0 16.8383 0 12C0 5.37256 5.37256 0 12 0C18.6274 0 24 5.37256 24 12C24 16.8383 22.3144 18.1894 13.2332 31.3544C12.6373 32.2152 11.3626 32.2152 10.7668 31.3544ZM12 17C14.7614 17 17 14.7614 17 12C17 9.23856 14.7614 7 12 7C9.23856 7 7 9.23856 7 12C7 14.7614 9.23856 17 12 17Z"
                  fill="#16B5B8"
                />
              </svg>
            </div>
            <div className="flex flex-col">
              <span className="text-text-darkgray !text-h1 font-semibold h-[20px]">
                {bookMarkItem.jibunName}
              </span>
              <p className="text-text-darkgray !text-p4 font-normal">
                {bookMarkItem.doroNameList.length > 0 ? (
                  bookMarkItem.doroNameList[0]
                ) : (
                  <div className="w-full text-center">-</div>
                )}
              </p>
            </div>
          </div>
          <div className="flex flex-wrap ml-[20px]">
            <div>
              <div className="flex flex-wrap">
                <div className="flex h-[30px]">
                  <Button
                    type="button"
                    color="primary"
                    variant="outlined_transparent"
                    btnStyle="w-[70px] !h-[24px] !text-h3 font-semibold mb-[7px]"
                  >
                    면적
                  </Button>
                  <div className="flex w-[115px] ml-[10px]">
                    <span className="w-[115px] text-text-darkgray whitespace-nowrap text-p3 mb-[7px] flex items-center">
                      {bookMarkItem.area.toFixed(2)}m²
                    </span>
                  </div>
                </div>
                <div className="flex h-[30px]">
                  <Button
                    type="button"
                    color="primary"
                    variant="outlined_transparent"
                    btnStyle="w-[70px] !h-[24px] !text-h3 font-semibold mb-[7px]"
                  >
                    지목
                  </Button>
                  <div className="flex w-[115px] ml-[10px]">
                    <span className="w-[115px] text-text-darkgray whitespace-nowrap text-p3 mb-[7px] flex items-center">
                      {bookMarkItem.jibun}
                    </span>
                  </div>
                </div>
                <div className="flex h-[30px]">
                  <Button
                    type="button"
                    color="primary"
                    variant="outlined_transparent"
                    btnStyle="w-[70px] !h-[24px] !text-h3 font-semibold mb-[7px]"
                  >
                    용도지역
                  </Button>
                  <div className="flex w-[115px] ml-[10px]">
                    <div className="flex items-center justify-center mr-[5px] text-[#F8D4E5] h-[27px]">
                      &#9679;
                    </div>
                    <span className="w-[115px] text-text-darkgray whitespace-nowrap text-p3 mb-[7px] flex items-center">
                      {bookMarkItem.zoneClass}
                    </span>
                  </div>
                </div>
                <div className="flex h-[30px]">
                  <Button
                    type="button"
                    color="primary"
                    variant="outlined_transparent"
                    btnStyle="w-[70px] !h-[24px] !text-h3 font-semibold mb-[7px]"
                  >
                    용도지구
                  </Button>
                  <div className="flex w-[115px] ml-[10px]">
                    <span className="w-[115px] text-text-darkgray whitespace-nowrap text-p3 mb-[7px] flex items-center">
                      {/* 데이터가 없어서 일단 빈칸으로 출력 */}
                      {/* {bookMarkItem.utilityDistrict} */}
                    </span>
                  </div>
                </div>
                <div className="flex h-[30px]">
                  <Button
                    type="button"
                    color="primary"
                    variant="outlined_transparent"
                    btnStyle="w-[70px] !h-[24px] !text-h3 font-semibold mb-[7px]"
                  >
                    도로
                  </Button>
                  <div className="flex w-[115px] ml-[10px]">
                    <span className="w-[115px] text-text-darkgray whitespace-nowrap text-p3 mb-[7px] flex items-center">
                      {/* 데이터가 없어서 일단 빈칸으로 출력 */}
                      {/* {land.street} */}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="w-full h-[300px] flex justify-center items-center">
          <LoadingSpinner panel />
        </div>
      ) : (
        <div className="ml-[20px]">
          {buildingInfo && buildingInfo.length > 0 ? (
            buildingInfo.map((building, index) => {
              if (index + 1 > buildingInfoLimit) {
                return null;
              }

              return (
                <>
                  <h1 className="text-title2 text-text-darkgray">
                    건물 정보 {index > 0 ? `(${index + 1})` : ""}
                  </h1>
                  <div className="flex flex-wrap mt-[10px] mb-[24px]">
                    <div>
                      <div className="flex flex-wrap">
                        <div className="flex h-[30px]">
                          <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            btnStyle="w-[70px] !h-[24px] !text-h3 font-semibold mb-[7px]"
                          >
                            명칭
                          </Button>
                          <div className="flex w-[115px] ml-[10px]">
                            <span className="w-[115px] text-text-darkgray whitespace-nowrap text-p3 mb-[7px] flex items-center">
                              {building.bldNm.trim() || "-"}
                            </span>
                          </div>
                        </div>
                        <div className="flex h-[30px]">
                          <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            btnStyle="w-[70px] !h-[24px] !text-h3 font-semibold mb-[7px]"
                          >
                            연면적
                          </Button>
                          <div className="flex w-[115px] ml-[10px]">
                            <span className="w-[115px] text-text-darkgray whitespace-nowrap text-p3 mb-[7px] flex items-center">
                              {(building.totArea * 0.3025).toFixed(2)}평
                            </span>
                          </div>
                        </div>
                        <div className="flex h-[30px]">
                          <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            btnStyle="w-[70px] !h-[24px] !text-h3 font-semibold mb-[7px]"
                          >
                            주용도
                          </Button>
                          <div className="flex w-[115px] ml-[10px]">
                            <span className="w-[115px] text-text-darkgray whitespace-nowrap text-p3 mb-[7px] flex items-center">
                              {building.mainPurpsCdNm}
                            </span>
                          </div>
                        </div>
                        <div className="flex h-[30px]">
                          <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            btnStyle="w-[70px] !h-[24px] !text-h3 font-semibold mb-[7px]"
                          >
                            세대
                          </Button>
                          <div className="flex w-[115px] ml-[10px]">
                            <span className="w-[115px] text-text-darkgray whitespace-nowrap text-p3 mb-[7px] flex items-center">
                              {building.hhldCnt}세대
                            </span>
                          </div>
                        </div>
                        <div
                          className={`flex  ${
                            building.indrMechUtcnt + building.oudrMechUtcnt >
                              0 &&
                            building.indrAutoUtcnt + building.oudrAutoUtcnt > 0
                              ? "h-[50px]"
                              : "h-[30px]"
                          }`}
                        >
                          <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            btnStyle="w-[70px] !h-[24px] !text-h3 font-semibold mb-[7px]"
                          >
                            주차
                          </Button>
                          <div className="flex flex-col w-[115px] ml-[10px]">
                            {building.indrMechUtcnt +
                              building.oudrMechUtcnt +
                              building.indrAutoUtcnt +
                              building.oudrAutoUtcnt ===
                              0 && "-"}
                            {building.indrAutoUtcnt + building.oudrAutoUtcnt >
                              0 && (
                              <span className="w-[115px] h-[24px] text-text-darkgray whitespace-nowrap text-p3 flex items-center">
                                자주식{" "}
                                {building.indrAutoUtcnt +
                                  building.oudrAutoUtcnt}
                                대
                              </span>
                            )}
                            {building.indrMechUtcnt + building.oudrMechUtcnt >
                              0 && (
                              <span className="w-[115px] h-[24px] text-text-darkgray whitespace-nowrap text-p3 flex items-center">
                                기계식{" "}
                                {building.indrMechUtcnt +
                                  building.oudrMechUtcnt}
                                대
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="flex h-[30px]">
                          <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            btnStyle="w-[70px] !h-[24px] !text-h3 font-semibold mb-[7px]"
                          >
                            승강기
                          </Button>
                          <div className="flex w-[115px] ml-[10px]">
                            <span className="w-[115px] text-text-darkgray whitespace-nowrap text-p3 mb-[7px] flex items-center">
                              {building?.rideUseElvtCnt}대
                            </span>
                          </div>
                        </div>
                        <div className="flex h-[30px]">
                          <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            btnStyle="w-[70px] !h-[24px] !text-h3 font-semibold mb-[7px]"
                          >
                            층수
                          </Button>
                          <div className="flex w-[115px] ml-[10px]">
                            <span className="w-[115px] text-text-darkgray whitespace-nowrap text-p3 mb-[7px] flex items-center">
                              {building.ugrndFlrCnt > 0 &&
                                `지하 ${building.ugrndFlrCnt}층`}
                              {building.ugrndFlrCnt > 0 &&
                                building.grndFlrCnt > 0 &&
                                " - "}
                              {building.grndFlrCnt > 0 &&
                                `지상 ${building.grndFlrCnt}층`}
                            </span>
                          </div>
                        </div>
                        <div className="flex h-[30px]">
                          <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            btnStyle="w-[70px] !h-[24px] !text-h3 font-semibold mb-[7px]"
                          >
                            건축구조
                          </Button>
                          <div className="flex w-[115px] ml-[10px]">
                            <span className="w-[115px] text-text-darkgray whitespace-nowrap text-p3 mb-[7px] flex items-center">
                              {building.strctCdNm}
                            </span>
                          </div>
                        </div>
                        <div className="flex h-[30px]">
                          <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            btnStyle="w-[70px] !h-[24px] !text-h3 font-semibold mb-[7px]"
                          >
                            준공연도
                          </Button>
                          <div className="flex w-[115px] ml-[10px]">
                            <span className="w-[115px] text-text-darkgray whitespace-nowrap text-p3 mb-[7px] flex items-center">
                              {/* {building.completionYear} */}-
                            </span>
                          </div>
                        </div>
                        <div className="flex h-[30px]">
                          <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            btnStyle="w-[70px] !h-[24px] !text-h3 font-semibold mb-[7px]"
                          >
                            연차
                          </Button>
                          <div className="flex w-[115px] ml-[10px]">
                            <span className="w-[115px] text-text-darkgray whitespace-nowrap text-p3 mb-[7px] flex items-center">
                              {/* {building.annual} */}-
                            </span>
                          </div>
                        </div>
                        <div className="flex h-[30px]">
                          <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            btnStyle="w-[70px] !h-[24px] !text-h3 font-semibold mb-[7px]"
                          >
                            대지면적
                          </Button>
                          <div className="flex w-[115px] ml-[10px]">
                            <span className="w-[115px] text-text-darkgray whitespace-nowrap text-p3 mb-[7px] flex items-center">
                              {building.platArea}m²
                            </span>
                          </div>
                        </div>
                        <div className="flex h-[30px]">
                          <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            btnStyle="w-[70px] !h-[24px] !text-h3 font-semibold mb-[7px]"
                          >
                            건축면적
                          </Button>
                          <div className="flex w-[115px] ml-[10px]">
                            <span className="w-[115px] text-text-darkgray whitespace-nowrap text-p3 mb-[7px] flex items-center">
                              {building.archArea}m²
                            </span>
                          </div>
                        </div>
                        <div className="flex h-[30px]">
                          <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            btnStyle="w-[70px] !h-[24px] !text-h3 font-semibold mb-[7px]"
                          >
                            건폐율
                          </Button>
                          <div className="flex w-[115px] ml-[10px]">
                            <span className="w-[115px] text-text-darkgray whitespace-nowrap text-p3 mb-[7px] flex items-center">
                              {building.bcRat}%
                            </span>
                          </div>
                        </div>
                        <div className="flex h-[30px]">
                          <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            btnStyle="w-[70px] !h-[24px] !text-h3 font-semibold mb-[7px]"
                          >
                            용적률
                          </Button>
                          <div className="flex w-[115px] ml-[10px]">
                            <span className="w-[115px] text-text-darkgray whitespace-nowrap text-p3 mb-[7px] flex items-center">
                              {building.vlRat}%
                            </span>
                          </div>
                        </div>
                        <div className="flex h-[30px]">
                          <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            btnStyle="w-[120px] !h-[24px] !text-h3 font-semibold mb-[7px]"
                          >
                            용적률산정용연면적
                          </Button>
                          <div className="flex w-[65px] ml-[10px]">
                            <span className="w-[auto] text-text-darkgray whitespace-nowrap text-p3 mb-[7px] flex items-center">
                              {building.vlRatEstmTotArea}m²
                            </span>
                          </div>
                        </div>
                        <div className="flex h-[30px]">
                          <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            btnStyle="w-[70px] !h-[24px] !text-h3 font-semibold mb-[7px]"
                          >
                            허가일
                          </Button>
                          <div className="flex w-[115px] ml-[10px]">
                            <span className="w-[115px] text-text-darkgray whitespace-nowrap text-p3 mb-[7px] flex items-center">
                              {building.pmsDay > 0
                                ? building.pmsDay.toString().slice(0, 4) +
                                  "." +
                                  building.pmsDay.toString().slice(4, 6) +
                                  "." +
                                  building.pmsDay.toString().slice(6, 8)
                                : "-"}
                            </span>
                          </div>
                        </div>
                        <div className="flex h-[30px]">
                          <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            btnStyle="w-[70px] !h-[24px] !text-h3 font-semibold mb-[7px]"
                          >
                            착공일
                          </Button>
                          <div className="flex w-[115px] ml-[10px]">
                            <span className="w-[115px] text-text-darkgray whitespace-nowrap text-p3 mb-[7px] flex items-center">
                              {building.stcnsDay > 0
                                ? building.stcnsDay.toString().slice(0, 4) +
                                  "." +
                                  building.stcnsDay.toString().slice(4, 6) +
                                  "." +
                                  building.stcnsDay.toString().slice(6, 8)
                                : "-"}
                            </span>
                          </div>
                        </div>
                        <div className="flex h-[30px]">
                          <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            btnStyle="w-[70px] !h-[24px] !text-h3 font-semibold mb-[7px]"
                          >
                            사용승인일
                          </Button>
                          <div className="flex w-[115px] ml-[10px]">
                            <span className="w-[115px] text-text-darkgray whitespace-nowrap text-p3 mb-[7px] flex items-center">
                              {building.useAprDay > 0
                                ? building.useAprDay.toString().slice(0, 4) +
                                  "." +
                                  building.useAprDay.toString().slice(4, 6) +
                                  "." +
                                  building.useAprDay.toString().slice(6, 8)
                                : "-"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <div className="w-full flex flex-col items-center mt-[110px]">
              <span className="text-title text-primary-main-color font-semibold text-center mb-1">
                건물 정보 없음
              </span>
              <span className="text-p2 text-primary-main-color text-center">
                건물 정보가 존재하지 않습니다.
              </span>
            </div>
          )}

          {buildingInfo && buildingInfo.length > buildingInfoLimit && (
            <div className="flex justify-center mb-[24px]">
              <AddContentButton
                variant="outlined"
                btnStyle="w-[80px] h-[30px]"
                size="medium"
                btnTextStyle="mr-1"
                text="더보기"
                onClick={handleBuildingInfoLimit}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BookmarkDetailPanel;
