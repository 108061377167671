export interface BookmarkDeleteProps {
  onCancelDeleteBookmark?: () => void;
  onDeleteBookmark?: () => void;
}

export const BookmarkDelete = ({
  onCancelDeleteBookmark,
  onDeleteBookmark,
}: BookmarkDeleteProps) => {
  return (
    // 즐겨찾기 해제시 toast pop
    <div className="flex justify-center z-[50] mt-[240px] fixed top-0 left-[22px] transform translate-x-1/3 translate-y-full">
      <div className="bg-primary-main-color rounded w-[320px] h-[100px]">
        <div className="pt-[3px]">
          <div className="flex items-center font-bold ml-[16px]" role="alert">
            <svg
              width="34"
              height="36"
              viewBox="0 0 34 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.0834 20.7502H11.9167V22.5835H10.0834V20.7502ZM10.0834 13.4168H11.9167V18.9168H10.0834V13.4168ZM10.9909 8.8335C5.93087 8.8335 1.83337 12.9402 1.83337 18.0002C1.83337 23.0602 5.93087 27.1668 10.9909 27.1668C16.06 27.1668 20.1667 23.0602 20.1667 18.0002C20.1667 12.9402 16.06 8.8335 10.9909 8.8335ZM11 25.3335C6.94837 25.3335 3.66671 22.0518 3.66671 18.0002C3.66671 13.9485 6.94837 10.6668 11 10.6668C15.0517 10.6668 18.3334 13.9485 18.3334 18.0002C18.3334 22.0518 15.0517 25.3335 11 25.3335Z"
                fill="white"
              />
            </svg>

            <h1 className="text-h2 text-white">
              즐겨찾기 해제시 복구 불가능합니다.
            </h1>
          </div>
          <p className="text-p2 text-white ml-[50px]">
            즐겨찾기를 해제하시겠습니까?
          </p>
          <div className="flex justify-end mt-[14px]">
            <button
              onClick={onCancelDeleteBookmark}
              className="flex justify-center bg-white items-center space-x-2 w-[51px] h-[20px] rounded mr-[14px]"
            >
              <span className="text-primary-main-color text-p3">취소</span>
            </button>
            <button
              onClick={onDeleteBookmark}
              className="flex justify-center bg-white items-center space-x-2 w-[51px] h-[20px] rounded mr-[22px]"
            >
              <span className="text-primary-main-color text-p3">확인</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
