import React from "react";

const ToastPop = () => {
  return (
    // 비밀번호 변경 완료 toast pop
    <div className="absolute bottom-[20px] left-[20px] z-[50]">
      <div
        className="flex items-center bg-primary-main-color text-white text-sm/[13px] font-bold px-4 py-3 rounded w-[349px] h-[48px]"
        role="alert"
      >
        <svg
          width="34"
          height="36"
          viewBox="0 0 34 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_506_32475)">
            <path
              d="M11 29C13.9174 29 16.7153 27.8411 18.7782 25.7782C20.8411 23.7153 22 20.9174 22 18C22 15.0826 20.8411 12.2847 18.7782 10.2218C16.7153 8.15893 13.9174 7 11 7C8.08262 7 5.28473 8.15893 3.22183 10.2218C1.15893 12.2847 0 15.0826 0 18C0 20.9174 1.15893 23.7153 3.22183 25.7782C5.28473 27.8411 8.08262 29 11 29ZM15.8555 15.9805L10.3555 21.4805C9.95156 21.8844 9.29844 21.8844 8.89883 21.4805L6.14883 18.7305C5.74492 18.3266 5.74492 17.6734 6.14883 17.2738C6.55273 16.8742 7.20586 16.8699 7.60547 17.2738L9.625 19.2934L14.3945 14.5195C14.7984 14.1156 15.4516 14.1156 15.8512 14.5195C16.2508 14.9234 16.2551 15.5766 15.8512 15.9762L15.8555 15.9805Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_506_32475">
              <rect
                width="22"
                height="22"
                fill="white"
                transform="translate(0 7)"
              />
            </clipPath>
          </defs>
        </svg>
        <p>성공적으로 비밀번호가 변경되었습니다.</p>
      </div>
    </div>
  );
};

export default ToastPop;
