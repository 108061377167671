import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

//@ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import { Land, LandZoneInfo } from "types/types";

import ReactDOM from "react-dom";
import { LandInfoWindow } from "components/LandInfoWIndow";
import { JIJUK_SOURCE_NAME } from "components/Map";
import { useLocation } from "react-router-dom";

interface MapContextState {
  lat?: number;
  lng?: number;
  bounds?: number[][];
  fileId?: number | null;
}

interface MapContextType {
  mapState: MapContextState;
  setMapState: (state: MapContextState) => void;
  map: React.MutableRefObject<mapboxgl.Map | null>;
  land: LandZoneInfo | undefined;
  setLand: (land: LandZoneInfo | undefined) => void;
  handleClickedLand: (land: Land) => void;
  prevClickedFeatureRef: React.MutableRefObject<number | null>;
}

let MapContext = React.createContext<MapContextType>(null!);

let pop: mapboxgl.Popup;

export default function MapProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [mapState, setMapState] = useState<MapContextState>({
    lat: 37.52516540971145,
    lng: 126.92547774899896,
  });
  const [land, setLand] = useState<LandZoneInfo>();
  const map = useRef<mapboxgl.Map | null>(null);
  const prevClickedFeatureRef = useRef<number | null>(null);
  const location = useLocation();

  // 페이지 이동 시 맵 초기화
  useEffect(() => {
    if (location.pathname.replace("/", "") && map.current) {
      map.current = null;
    }
  }, [location]);

  useEffect(() => {
    if (land && map.current) {
      if (pop) {
        pop.remove();
      }

      // 정보 창 HTML
      const infoWindow = document.createElement("div");
      infoWindow.className = "info-window";
      ReactDOM.hydrate(<LandInfoWindow land={land} />, infoWindow);

      pop = new mapboxgl.Popup({ maxWidth: "full" })
        .setLngLat(land.coordinates)
        .setDOMContent(infoWindow)
        .addTo(map.current);
    }
  }, [land, map]);

  const handleClickedLand = useCallback(
    (land: Land) => {
      const id = land.id;

      if (prevClickedFeatureRef.current) {
        setLand(undefined);

        map.current.setFeatureState(
          {
            source: JIJUK_SOURCE_NAME,
            sourceLayer: "default",
            id: prevClickedFeatureRef.current,
          },
          { clicked: false }
        );
      }

      prevClickedFeatureRef.current = id;
      setMapState({
        lat: land.latitude,
        lng: land.longitude,
      });

      map.current.setFeatureState(
        {
          source: JIJUK_SOURCE_NAME,
          sourceLayer: "default",
          id: id,
        },
        { clicked: true }
      );
      setLand({
        id: id,
        area: land.area,
        doroNameList: land.doroNameList,
        jibunName: land.jibunName,
        uname: land.zoneClass,
        coordinates: [land.longitude, land.latitude],
      });
    },
    [setLand, setMapState]
  );

  let value = useMemo(
    () => ({
      mapState,
      setMapState,
      map,
      land,
      setLand,
      handleClickedLand,
      prevClickedFeatureRef,
    }),
    [land, mapState, handleClickedLand]
  );
  return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
}

export function useMap() {
  return React.useContext(MapContext);
}
