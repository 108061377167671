import { useCallback, useState } from "react";
import SmallButton from "./SmallButton";
import {
  PrivateLandMainUse,
  PrivateLandRoad,
  PrivateLandSiteCategory,
  PrivateLandUseDistrict,
  PrivateLandUseZone,
} from "contans/SelectItems";
import moment from "moment";
import { PrivateLandSiteDetail } from "types/types";
import { Button } from "@prop-insight/components";
import JSZip from "jszip";
import axiosInst from "utils/axiosInst";
import { formatPriceWithCommas } from "utils/formatPriceWithCommas";
import { Tooltip as ReactTooltip } from "react-tooltip";
import clsx from "clsx";
import PrivateLandSiteTableData from "./PrivateLandSiteTableData";
import PrivateLandSiteAdvertiseTap from "./PrivateLandSiteAdvertiseTap";

interface PrivateLandSiteDetailPanelProps {
  privateLandSite: PrivateLandSiteDetail;
}

const PrivateLandSiteDetailPanel = ({
  privateLandSite,
}: PrivateLandSiteDetailPanelProps) => {
  const [calcArea, setCalcArea] = useState<boolean>(false);

  const userToken = window.localStorage.getItem("token");

  const downloadFiles = useCallback(async () => {
    if (userToken === null || userToken === "null") {
      return;
    }

    if (privateLandSite.files && privateLandSite.files?.length !== 0) {
      const files = [];

      for (const file of privateLandSite.files) {
        const resp = await axiosInst.get(`/files/${file.id}`);
        const newFile = {
          filename: resp.data.filename,
          mimeType: resp.data.mimeType,
          url: resp.data.url,
        };
        files.push(newFile);
      }

      // JSZip 인스턴스 생성
      const zip = new JSZip();

      // 파일 다운로드 및 zip에 추가
      const promises = files.map(async (fileInfo, index) => {
        const { filename, url } = fileInfo;
        const fileName = `${filename}`;

        try {
          const response = await fetch(url);

          // blob 타입으로 다운로드
          const blob = await response.blob();
          zip.file(fileName, blob, { binary: true }); // binary 옵션을 설정하여 이진 파일로 처리
        } catch (error) {
          console.error(`파일 다운로드 오류: ${error}`);
        }
      });

      // 모든 파일 다운로드 및 압축 후 zip 파일 다운로드
      await Promise.all(promises);
      zip.generateAsync({ type: "blob" }).then((content) => {
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(content);
        a.href = url;
        a.download = "Prop-Insight.zip";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
    }
  }, [privateLandSite.files, userToken]);

  return (
    <>
      <div className="p-[20px]">
        <div className="flex mt-[60px]">
          <h1 className="text-title2 mr-[11px]">상세 내용</h1>
          <div className="flex justify-center items-center">
            <SmallButton
              text={calcArea ? "m²" : "평"}
              onClick={() => {
                setCalcArea((o) => !o);
              }}
            />
          </div>
        </div>
        <div className="mt-5">
          <PrivateLandSiteTableData
            title="매물번호"
            data={privateLandSite.id}
          />
          <PrivateLandSiteTableData
            title="담당자 연락처"
            data={privateLandSite.contactNumber}
          />
        </div>
        <div className="mb-[26px]">
          <h2 className="mt-7 text-title2 mb-4">토지정보</h2>

          <PrivateLandSiteTableData
            title="토지면적"
            data={
              calcArea
                ? `${(privateLandSite.landArea / 3.3025).toFixed(2)} 평`
                : `${privateLandSite.landArea.toFixed(2)} m²`
            }
            type="outlined"
          />

          <PrivateLandSiteTableData
            title="지목"
            data={PrivateLandSiteCategory[privateLandSite.landCategory]}
            type="outlined"
          />

          <PrivateLandSiteTableData
            title="용도지역"
            data={
              <div className="flex gap-1">
                <div
                  className={clsx("flex items-center justify-center", {
                    "text-pink-200":
                      PrivateLandUseZone[privateLandSite.landUseZone].includes(
                        "상업"
                      ),

                    "text-slate-400":
                      PrivateLandUseZone[privateLandSite.landUseZone].includes(
                        "공업"
                      ),
                    "text-yellow-200":
                      PrivateLandUseZone[privateLandSite.landUseZone].includes(
                        "주거"
                      ),
                    "text-lime-400":
                      PrivateLandUseZone[privateLandSite.landUseZone].includes(
                        "관리"
                      ),
                  })}
                >
                  <span className="flex items-center justify-center text-[10px] -mt-[2px]">
                    &#9679;
                  </span>
                </div>
                <span>{PrivateLandUseZone[privateLandSite.landUseZone]}</span>
              </div>
            }
            type="outlined"
          />

          <PrivateLandSiteTableData
            title="용도지구"
            data={PrivateLandUseDistrict[privateLandSite.landUseDistrict]}
            type="outlined"
          />

          <PrivateLandSiteTableData
            title="도로"
            data={PrivateLandRoad[privateLandSite.road]}
            type="outlined"
          />

          <PrivateLandSiteTableData
            title="소재지"
            data={privateLandSite.address}
            type="outlined"
          />

          <PrivateLandSiteTableData
            title="대표지번"
            data={privateLandSite.mainLotNumber}
            type="outlined"
          />

          {privateLandSite.privateLandSiteBlocks.map((block) => (
            <PrivateLandSiteTableData
              key={block}
              title="인접지번"
              data={block}
              type="outlined"
            />
          ))}

          <PrivateLandSiteTableData
            title={`공시지가(총/m²당)`}
            width="long"
            data={`${formatPriceWithCommas(
              privateLandSite.publicLandValue.toString()
            )}/${Math.round(
              parseInt(
                privateLandSite.publicLandValue.toString().slice(0, -3)
              ) / privateLandSite.landArea
            )}천원`}
            type="outlined"
          />

          <PrivateLandSiteTableData
            title={`매매희망가(총/m²당)`}
            width="long"
            data={`${formatPriceWithCommas(
              privateLandSite.expectedSalePrice.toString()
            )}/${Math.round(
              parseInt(
                privateLandSite.expectedSalePrice.toString().slice(0, -3)
              ) / privateLandSite.landArea
            )}천원`}
            type="outlined"
          />
        </div>

        <div className="mb-5">
          <h2 className="mt-7 text-title2 mb-4">건물정보</h2>
          <div className="flex flex-col gap-2">
            <PrivateLandSiteTableData
              title="연면적"
              data={
                privateLandSite.totalFloorArea
                  ? calcArea
                    ? `${(privateLandSite.totalFloorArea / 3.3025).toFixed(
                        2
                      )} 평`
                    : `${privateLandSite.totalFloorArea.toFixed(2)} m²`
                  : "- 없음 -"
              }
            />

            <PrivateLandSiteTableData
              title="주용도"
              data={
                privateLandSite.mainUse
                  ? PrivateLandMainUse[privateLandSite.mainUse]
                  : "- 없음 -"
              }
            />

            <PrivateLandSiteTableData
              title="사용승인일"
              data={
                privateLandSite.approvalDate
                  ? moment(privateLandSite.approvalDate).format("YYYY-MM-DD")
                  : "- 없음 -"
              }
            />

            <PrivateLandSiteTableData
              title="규모"
              data={
                privateLandSite.scale && privateLandSite.scale !== "null"
                  ? privateLandSite.scale
                  : "- 없음 -"
              }
            />

            <PrivateLandSiteTableData
              isTextBox
              title="임차현황"
              data={
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      privateLandSite.rentalStatus &&
                      privateLandSite.rentalStatus !== "null"
                        ? privateLandSite.rentalStatus
                        : "- 없음 -",
                  }}
                />
              }
            />
          </div>
        </div>

        <div className="mb-7">
          <Button
            type="button"
            color="primary"
            variant="outlined"
            btnStyle=" w-[191px] h-[27px]"
            boxShadow
            withRightIcon="fileIcon"
            disabled={
              !privateLandSite.files?.length ||
              privateLandSite.files?.length === 0
            }
            onClick={downloadFiles}
          >
            <span
              data-tooltip-id={
                userToken === null || userToken === "null" ? "info-tooltip" : ""
              }
              data-tooltip-content={
                "로그인 상태에서 첨부파일을 다운로드할 수 있습니다."
              }
            >
              첨부파일 다운로드
            </span>
          </Button>
          <ReactTooltip
            id="info-tooltip"
            place="right"
            style={{
              position: "fixed",
              right: 0,
              backgroundColor: "#16B5B8",
              zIndex: 999,
              padding: "1px 8px",
              borderRadius: 4,
            }}
          />
        </div>

        {/* VR, 로드뷰 탭 */}
        {privateLandSite.isAdvertised && (
          <PrivateLandSiteAdvertiseTap privateLandSite={privateLandSite} />
        )}
      </div>
    </>
  );
};

export default PrivateLandSiteDetailPanel;
