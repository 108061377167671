import { ChangePasswdForm } from "@prop-insight/components";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import { Menu, User } from "types/types";
import axiosInst from "utils/axiosInst";

interface UserPanelProps {
  onOpenToast?: (open: boolean) => void;
  onChangeMenu?: (menu: Menu) => void;
}

export default function UserPanel({
  onOpenToast,
  onChangeMenu,
}: UserPanelProps) {
  const user = useRouteLoaderData("root") as User;
  const navigate = useNavigate();

  return (
    <div className="px-[20px]">
      {/* 계정정보 */}
      <div className="mb-[18px]">
        <span className="text-text-darkgray text-[18px] font-bold tracking-tighter">
          계정 정보
        </span>
        <div className="flex bg-background-main-color rounded-[10px] py-[9px] px-[10px] justify-between items-center mt-[7px]">
          <div className="flex flex-col">
            <span className="text-text-darkgray text-[13px] font-normal mb-[3px]">
              아이디
            </span>
            <span className="text-text-darkgray text-[13px] font-normal mb-[2px]">
              {user && user.username}
            </span>
          </div>
          <button
            className="bg-primary-main-color text-[13px] text-white rounded font-semibold py-[3.5px] px-[20px] h-[30px] mr-[2px]"
            onClick={() => {
              window.localStorage.removeItem("token");
              window.localStorage.removeItem("refreshToken");
              onChangeMenu && onChangeMenu("home");
            }}
          >
            로그아웃
          </button>
        </div>
      </div>

      {/* 비밀번호 변경 */}
      <div>
        <div className="mb-2">
          <span className="text-text-darkgray text-[18px] font-bold tracking-tighter">
            비밀번호 변경
          </span>
        </div>

        <ChangePasswdForm
          onDeleteAccount={() => navigate("/delete-account")}
          changePasswd={async (password, newPassword) => {
            await axiosInst.put("/users/password", {
              password,
              newPassword,
            });
          }}
          onSuccess={() => onOpenToast?.(true)}
        />
      </div>
    </div>
  );
}
