import { Outlet, RouteObject } from "react-router-dom";
import Index from "./index";
import Login from "./login";
import Signup from "./signup";
import EmailConfirm from "./emailConfirm";
import EmailConfirmSuccess from "./emailConfirmSuccess";
import ResetPassword from "./resetPassword";
import ChangePassword from "./changePassword";
import ChangePasswordSuccess from "./changePasswordSuccess";
import PrivacyPolicy from "./privacyPolicy";
import ServiceTerms from "./serviceTerms";
import LegalNotice from "./legalNotice";
import DeleteAccount from "./deleteAccount";
import DeleteAccountSuccess from "./deleteAccountSuccess";
import Analysis from "./analysis";
// import Protected from "./protected";
import axiosInst from "utils/axiosInst";
import { User } from "types/types";
import MapProvider from "providers/MapProvider";
import marketAnalysis from "./market-analysis";
import marketAnalysisCreate from "./market-analysis-create";
import marketAnalysisEdit from "./market-analysis-edit";

const loader = async () => {
  const token = window.localStorage.getItem("token");
  console.log("root loader", "token", token);
  if (token && token !== "null" && token !== "undefined") {
    try {
      const resp = await axiosInst.get("/users/me");
      return resp.data.user as User;
    } catch (e) {
      // TODO: refresh 토큰으로 토큰 재발급...
      window.localStorage.removeItem("token");
    }
  }

  return null;
};

export function Root() {
  return (
    <div>
      <MapProvider>
        <Outlet />
      </MapProvider>
    </div>
  );
}

export default {
  path: "/",
  id: "root",
  element: <Root />,
  loader,
  children: [
    Index,
    Login,
    Signup,
    EmailConfirm,
    EmailConfirmSuccess,
    ResetPassword,
    ChangePassword,
    ChangePasswordSuccess,
    PrivacyPolicy,
    ServiceTerms,
    LegalNotice,
    DeleteAccount,
    DeleteAccountSuccess,
    Analysis,
    marketAnalysis,
    marketAnalysisCreate,
    marketAnalysisEdit,
  ],
} as RouteObject;
