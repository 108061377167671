import axiosInst from "../axiosInst";

export const getPostFive = async () => {
  return (await axiosInst.get(`/boards/1/posts`)).data.posts.slice(0, 5);
};

export const getPost = async (id: number) => {
  const data = await axiosInst.get(`/posts/${id}`);

  return data.data;
};
