import { Button, LoginForm } from "@prop-insight/components";
import { useAuth } from "providers/AuthProvider";
import { RouteObject, redirect, useNavigate } from "react-router-dom";
import Logo from "logo.svg";
import withViewerTemplate from "hoc/withViewerTemplate";

const loader = async () => {
  const token = window.localStorage.getItem("token");
  console.log("Login loader", "token", token);
  if (token && token !== "null") {
    return redirect("/");
  }
  return null;
};

function Login() {
  const { signIn } = useAuth();
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex flex-col items-center">
        <div className="flex flex-col max-w-[390px] w-full my-[150px]">
          <div className="flex flex-col items-center">
            <img src={Logo} alt="logo" className="mb-[9px]" />
            <span className="text-primary-main-color font-semibold text-2xl mb-12">
              로그인
            </span>
          </div>

          <LoginForm
            login={signIn}
            onSuccess={() => {
              window.location.href = "/";
            }}
          />
          <div className="flex justify-center mt-[48px]">
            <Button
              type="submit"
              color="primary"
              variant="text"
              btnStyle="mr-[27px]"
              onClick={() => navigate("/reset-password")}
            >
              <span>비밀번호찾기</span>
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="text"
              onClick={() => navigate("/signup")}
            >
              <span>회원가입</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

const LoginWithSidebar = withViewerTemplate(Login);

export default {
  path: "/login",
  element: <LoginWithSidebar />,
  loader,
} as RouteObject;
