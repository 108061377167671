import { useEffect, useState } from "react";
import { errorToast } from "utils/toast";

const gererateErrorToast = (e: any) => {
  console.log(e);
  errorToast();
};

const useQuery = <T>(queryFunc: any, errorFunc: any = gererateErrorToast) => {
  const [data, setData] = useState<T>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const result = await queryFunc();

        setData(result);
      } catch (e) {
        errorFunc(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [errorFunc, queryFunc]);

  return { data, isLoading };
};

export default useQuery;
