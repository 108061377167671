import { LoadingSpinner } from "@prop-insight/components";
import { Post } from "@prop-insight/database";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import axiosInst from "utils/axiosInst";
import Pagination from "./Pagination";

interface PostPanelProps {
  openPostDetail: (notice: Post) => void;
}

const PostPanel = ({ openPostDetail }: PostPanelProps) => {
  const [posts, setPosts] = useState<Post[]>([]); // 소식 알리미 리스트
  const [postsLoading, setPostsLoading] = useState(false); // 소식 알리미 로딩

  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  // 소식 알리미 리스트 5개 출력
  useEffect(() => {
    const loadNotiInfo = async () => {
      try {
        setPostsLoading(true);
        const resp = await axiosInst.get(
          `/boards/1/posts?page=${currentPage}&limit=10`
        );
        setPosts((resp.data as { posts: Post[] }).posts);
        setMaxPage(Math.ceil((resp.data as { count: number }).count / 10));
      } catch (err) {
        console.log("err", err);
      } finally {
        setPostsLoading(false);
      }
    };
    loadNotiInfo();
  }, [currentPage]);

  if (postsLoading)
    return (
      <div className="h-[60vh] flex justify-center items-center">
        <LoadingSpinner panel />
      </div>
    );

  return (
    <div className="mx-[20px]">
      <div className="pt-[17px] text-lg font-bold leading-[1.125rem] pb-[24px]">
        공지사항
      </div>

      <table className="w-[388px] mx-auto text-center text-sm">
        <thead>
          <tr className="h-[56px] border-b-[1px] border-b-[rgba(0,0,0,0.12)]">
            <th className="w-[40px] h-[56px] flex-inline items-center justify-center text-sm font-normal">
              <div>번호</div>
            </th>
            <th className="w-[210px] h-[56px] flex-inline items-center justify-center text-sm font-normal">
              <div>제목</div>
            </th>
            <th className="w-[70px] h-[56px] flex-inline items-center justify-center text-sm font-normal">
              <div>작성자</div>
            </th>
            <th className="w-[68px] h-[56px] flex-inline items-center justify-center text-sm font-normal">
              <div>작성날짜</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {_.map(posts, (post, index) => (
            <tr
              key={post.id}
              onClick={() => openPostDetail(post)}
              className="hover:bg-background-main-color hover:cursor-pointer border-b-[1px] border-b-[rgba(0,0,0,0.12)]"
            >
              <td className="w-[40px] h-[52px] flex-inline items-center justify-center">
                <div>{post.id}</div>
              </td>
              <td className="w-[210px] h-[52px] flex-inline items-center justify-start">
                <div className="line-clamp-1 text-start ml-2">{post.title}</div>
              </td>
              <td className="w-[70px] h-[52px] flex-inline items-center justify-center">
                <div>관리자</div>
              </td>
              <td className="w-[68px] h-[52px] flex-inline items-center justify-center">
                <div>{moment(post.createdAt).format("YY.MM.DD")}</div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* pagination */}
      <Pagination
        totalPages={maxPage}
        currentPage={currentPage}
        onChangePage={(page) => {
          setCurrentPage(page);
        }}
      />
    </div>
  );
};

export default PostPanel;
