import { EmailConfirmForm } from "@prop-insight/components";
import { RouteObject, useLocation, useNavigate } from "react-router-dom";
import withViewerTemplate from "hoc/withViewerTemplate";
import axiosInst from "utils/axiosInst";

function EmailConfirm() {
  const navigate = useNavigate();
  const location = useLocation();

  console.log(location.state);
  if (!location.state?.username) navigate("/signup", { replace: true });

  return (
    <div>
      <div className="flex flex-col items-center">
        <div className="flex flex-col max-w-[390px] w-full my-[220px]">
          <div className="flex flex-col items-center">
            <span className="text-primary-main-color font-semibold text-2xl mb-[6px]">
              이메일 인증
            </span>
            <span className="text-primary-main-color text-[13px] mb-[47px]">
              이메일로 전송된 인증코드를 입력하세요.
            </span>
          </div>

          <EmailConfirmForm
            confirm={async (code) => {
              const { username } = location.state;
              return axiosInst.post("/users/activate", {
                username,
                code,
              });
            }}
            onSuccess={() => {
              navigate("/email-confirm-success", { replace: true });
            }}
          />
        </div>
      </div>
    </div>
  );
}

const EmailConfirmWithSidebar = withViewerTemplate(EmailConfirm);

export default {
  path: "/email-confirm",
  element: <EmailConfirmWithSidebar />,
} as RouteObject;
