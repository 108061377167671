import React, { Suspense, useEffect, useState } from "react";
import clsx from "clsx";
import VrTap from "./VrTap";
import { PrivateLandSite } from "@prop-insight/database";
import { LoadingSpinner } from "@prop-insight/components";
import { Link } from "react-router-dom";

const RoadViewTap = React.lazy(() => import("./RoadViewTap"));

interface PrivateLandSiteAdvertiseTapProps {
  privateLandSite: PrivateLandSite;
}

const PrivateLandSiteAdvertiseTap = ({
  privateLandSite,
}: PrivateLandSiteAdvertiseTapProps) => {
  const [tapIndex, setTapIndex] = useState<number>(0);

  const userToken = window.localStorage.getItem("token");

  const handleTap = (index: number) => {
    setTapIndex(index);
  };

  useEffect(() => {
    setTapIndex(0);
  }, [privateLandSite]);

  return (
    <div>
      <div className="flex mb-3">
        <button
          onClick={() => handleTap(0)}
          className={clsx([
            "bg-background-sub-color  w-full h-12 rounded-tl-md text-h2",
            tapIndex === 0 &&
              "text-primary-main-color border-b-2 border-b-primary-main-color",
          ])}
        >
          VR 보기
        </button>
        <button
          onClick={() => handleTap(1)}
          className={clsx([
            "bg-background-sub-color  w-full h-12 rounded-tr-md text-h2",
            tapIndex === 1 &&
              "text-primary-main-color border-b-2 border-b-primary-main-color",
          ])}
        >
          로드뷰 보기
        </button>
      </div>
      {tapIndex === 0 && <VrTap vrId={privateLandSite.vrId} />}
      {tapIndex === 1 && (userToken === null || userToken === "null") && (
        <div className="w-full h-[222px] bg-[#F3F2F2] flex flex-col justify-center items-center">
          <p className=" text-center font-semibold text-title text-primary-main-color mb-3">
            로그인이 되어있지 <br /> 않습니다.
          </p>
          <p className=" text-[13px] text-primary-main-color mb-5">
            로드뷰 기능은 사용자의 로그인이 필요합니다.
          </p>
          <Link
            to="/login"
            className=" bg-primary-main-color text-h2 text-white font-semibold py-2 px-6 rounded shadow-sm"
          >
            로그인 페이지로 이동
          </Link>
        </div>
      )}
      {tapIndex === 1 && !(userToken === null || userToken === "null") && (
        <Suspense
          fallback={
            <div className="w-full h-[222px] flex justify-center items-center">
              <LoadingSpinner />
            </div>
          }
        >
          <RoadViewTap lat={privateLandSite.lat} lng={privateLandSite.lng} />
        </Suspense>
      )}
    </div>
  );
};

export default PrivateLandSiteAdvertiseTap;
