import axiosInst from "./axiosInst";

type ViewTimeData = {
  id: number;
  viewTime: Date;
};

export enum LocalStorageKey {
  POST_VIEW_TIME_DATA_LIST = "postViewTimeDataList",
  LANDSITE_VIEW_TIME_DATA_LIST = "landSiteViewTimeDataList",
  REALTYPOLICY_VIEW_TIME_DATA_LIST = "realtyPolicyViewTimeDataList",
  PRIVATELANDSITE_VIEW_TIME_DATA_LIST = "privateLandSiteViewTimeDataList",
}

export const postCountUpApi = async (postId: number) => {
  await axiosInst.post(`/posts/${postId}/views`);
};

export const landSiteCountUpApi = async (landSiteId: number) => {
  await axiosInst.post(`/land-sites/${landSiteId}/views`);
};

export const privateLandSiteCountUpApi = async (privateLandSiteId: number) => {
  await axiosInst.post(`/private-land-sites/${privateLandSiteId}/views`);
};

export const realtyPolicyCountUpApi = async (realtyPolicyId: number) => {
  await axiosInst.post(`/realty-policies/${realtyPolicyId}/views`);
};

const CountUpDelay = 1000 * 10;
// const CountUpDelay = 1000 * 10 * 6 * 60 * 24;

interface CountUpProps {
  id: number;
  localStorageKey: LocalStorageKey;
  countUpApi: (id: number) => Promise<void>;
}

const viewCountUp = async ({
  id,
  localStorageKey,
  countUpApi,
}: CountUpProps) => {
  const viewTimeDataList = localStorage.getItem(localStorageKey);

  if (viewTimeDataList) {
    const viewTimeDataListJson = JSON.parse(viewTimeDataList);

    const viewTimeData = viewTimeDataListJson.find(
      (item: ViewTimeData) => item.id === id
    );

    if (!viewTimeData) {
      countUpApi(id);
      const viewTimeData = {
        id,
        viewTime: new Date(),
      };

      viewTimeDataListJson.push(viewTimeData);

      localStorage.setItem(
        localStorageKey,
        JSON.stringify(viewTimeDataListJson)
      );
    } else if (
      new Date(viewTimeData.viewTime).getTime() + CountUpDelay <
      new Date().getTime()
    ) {
      countUpApi(id);
      viewTimeData.viewTime = new Date();

      const newViewTimeDataList = viewTimeDataListJson.map((item: any) => {
        if (item.id === id) {
          return viewTimeData;
        }
        return item;
      });

      localStorage.setItem(
        localStorageKey,
        JSON.stringify(newViewTimeDataList)
      );
    }
  } else {
    countUpApi(id);
    const viewTimeData = {
      id,
      viewTime: new Date(),
    };

    localStorage.setItem(localStorageKey, JSON.stringify([viewTimeData]));
  }
};

export default viewCountUp;
