import { toast } from "react-toastify";

export const errorToast = (description?: string) => {
  return toast.error(
    description ||
      "알 수 없는 오류가 발생했습니다. 잠시 후에 다시 시도해주세요.",
    {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    }
  );
};
