import { faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LandSite } from "@prop-insight/database";
import _ from "lodash";
import moment from "moment";
import { useMap } from "providers/MapProvider";
import { Fragment, useCallback } from "react";
import clsx from "clsx";
import axiosInst from "utils/axiosInst";
import { LoadingSpinner } from "@prop-insight/components";
import useQuery from "hooks/useQuery";
import { getLandSitePlanned } from "utils/api/landSiteApi";

interface PlannedLandTableProps {
  // 신규 택지 표 디자인을 위한 props
  newLand?: boolean;
  openNewLandDetail: (landSite: LandSite) => void;
}
const PlannedLandTable = ({
  newLand,
  openNewLandDetail,
}: PlannedLandTableProps) => {
  const { setMapState } = useMap();
  const { data: landSites, isLoading } =
    useQuery<LandSite[]>(getLandSitePlanned);

  const handleNewLandDetail = useCallback(
    async (id: number) => {
      try {
        const resp = await axiosInst.get(`/land-sites/${id}`);
        openNewLandDetail(resp.data);
      } catch (error) {
      } finally {
      }
    },
    [openNewLandDetail]
  );
  return (
    <table className="w-[100%]">
      <tbody
        className={`${clsx({
          "flex justify-center items-center h-[40vh]":
            landSites?.length === 0 && !newLand,
        })}`}
      >
        {landSites?.length === 0 ? (
          <div className="flex flex-col items-center">
            <span className="text-title text-primary-main-color font-semibold text-center h-[29px] -ml-1 mb-[13px]">
              택지 항목 없음
            </span>
            <span className="text-p2 text-primary-main-color text-center">
              진행 중인 택지 항목이 존재하지 않습니다.
            </span>
          </div>
        ) : isLoading ? (
          <div className="flex justify-center items-center h-[200px]">
            <LoadingSpinner panel />
          </div>
        ) : (
          _.map(landSites, (landSite) => {
            let status = "접수예정";
            let bcColor = "#FF9737";
            const endDate = landSite.applicationEndDate
              ? moment(landSite.applicationEndDate)
              : landSite.contractEndDate
              ? moment(landSite.contractEndDate)
              : moment(landSite.lotteryDate);
            const startDate = landSite.applicationStartDate
              ? moment(landSite.applicationStartDate)
              : landSite.contractStartDate
              ? moment(landSite.contractStartDate)
              : moment(landSite.createdAt);
            const now = moment();
            if (now.isBetween(startDate, endDate)) {
              status = "접수중";
              bcColor = "#FF6056";
            } else if (now.isAfter(endDate)) {
              status = "접수마감";
              bcColor = "#A5ABB3";
            }
            return (
              <Fragment key={`land-site-${landSite.id}`}>
                <tr
                  className="border-b-[1px] border-[#F3F2F2] h-[78px] hover:cursor-pointer hover:bg-background-main-color"
                  onClick={() => {
                    handleNewLandDetail(landSite.id);
                  }}
                >
                  <td className="w-[41px] h-[60px] pt-2 px-1">
                    <div
                      style={{ backgroundColor: bcColor }}
                      className={
                        "text-[10px] rounded flex items-center justify-center h-[31px] text-white w-[39px] text-center box-border"
                      }
                    >
                      <span className="whitespace-nowrap">{status}</span>
                    </div>
                  </td>
                  <td
                    className={"text-[12px] w-[50px] h-[31px] pt-2 text-center"}
                  >
                    {landSite.purpose}
                  </td>
                  <td className={"text-[12px] w-[165px] h-[31px] pt-2 px-1"}>
                    <p className="font-semibold whitespace-nowrap text-ellipsis max-w-[181px] overflow-hidden">
                      {landSite.title}
                    </p>
                    <p>{landSite.address}</p>
                  </td>
                  <td className={"text-[12px] w-[75px] h-[31px]"}>
                    {landSite.authority}
                  </td>
                  <td className="h-[31px] pl-[5px] pt-2">
                    <FontAwesomeIcon
                      icon={faMapLocationDot}
                      size="lg"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (landSite.imageBounds.length > 2) {
                          console.log(landSite.imageBounds.length);
                          setMapState({
                            bounds: JSON.parse(landSite.imageBounds),
                            fileId: landSite.fileId,
                          });
                        } else {
                          setMapState({
                            lat: landSite.lat,
                            lng: landSite.lng,
                          });
                        }
                      }}
                    />
                  </td>
                </tr>
              </Fragment>
            );
          })
        )}
      </tbody>
    </table>
  );
};

export default PlannedLandTable;
