import axiosInst from "utils/axiosInst";

interface GetRealtyPolicyProps {
  currentPage: number;
  currentSearchKey: string;
  currentDepartment: string | undefined;
  currentRegion: string | undefined;
}

export const getRealtyPolicy = async ({
  currentPage,
  currentSearchKey,
  currentDepartment,
  currentRegion,
}: GetRealtyPolicyProps) => {
  return (
    await axiosInst.get(
      `/realty-policies?page=${currentPage}&search=${currentSearchKey}&department=${currentDepartment}&region=${currentRegion}`
    )
  ).data;
};
