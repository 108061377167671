import { LandInfoDetails, LandInfoType } from "types/types";
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import "mapbox-gl/dist/mapbox-gl.css";
import "../styles/map.css";
import "@mapbox-controls/ruler/src/index.css";

//@ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import { getColorBySubCategory } from "utils";
import _ from "lodash";
import { TILE_SERVER_URI } from "utils/constants";
import { useMap } from "providers/MapProvider";
import axiosInst from "utils/axiosInst";
import useToolBax from "hooks/useToolBox";
import * as turf from "@turf/turf";
import ReactDOM from "react-dom";
import PrivateLandSiteInfoWindow from "./PrivateLandSiteInfoWindow";

mapboxgl.accessToken =
  "pk.eyJ1IjoibWluY2hlb2w4Njg2IiwiYSI6ImNrZWdvdzc0czB4YWszMmp5d3BhYzdyY3IifQ.aO9BHUT05NIK0pSjLY5B5w";

//전용주거지역
const ZONE_RESIDENCE1_COLOR = "#DED34A";
//주거지역
const ZONE_RESIDENCE2_COLOR = "#F7F3A5";
//준주거지역
const ZONE_RESIDENCE3_COLOR = "#EFEB73";
//상업지역
const ZONE_COMMERCE_COLOR = "#FFCFE7";
//중심상업지역
const ZONE_CENTER_COMMERCE_COLOR = "#F7A6C6";

const ZONE_SOURCE_NAME = "zone-source";
export const ZONE_FILL_LAYER_NAME = "zone-fill-layer";
const ZONE_FILL_LAYER: mapboxgl.FillLayer = {
  id: ZONE_FILL_LAYER_NAME,
  type: "fill",
  source: ZONE_SOURCE_NAME,

  "source-layer": "default",
  paint: {
    // 스타일 및 색상 설정
    "fill-color": [
      "match",
      ["get", "ucode"], // 속성값
      ["UQA220"],
      ZONE_COMMERCE_COLOR,
      ["UQA210"],
      ZONE_CENTER_COMMERCE_COLOR,
      ["UQA110", "UQA111", "UQA112"],
      ZONE_RESIDENCE1_COLOR,
      ["UQA120", "UQA121", "UQA122", "UQA123"],
      ZONE_RESIDENCE2_COLOR,
      ["UQA130"],
      ZONE_RESIDENCE3_COLOR,
      /* 기본값 설정 */
      "transparent", // 그 외에는 회색으로 설정
    ],
    "fill-opacity": 0.5,
  },
};

const BUILDING_OUTLINE_LAYER_NAME = "building-outline-layer";
const BUILDING_OUTLINE_LAYER: mapboxgl.LineLayer = {
  id: BUILDING_OUTLINE_LAYER_NAME,
  type: "line",
  source: ZONE_SOURCE_NAME,
  "source-layer": "building",
  paint: {
    // 스타일 및 색상 설정
    "line-color": [
      "case",
      ["boolean", ["feature-state", "clicked"], false],
      "red", // 클릭한 경우 line-width를 5로 설정
      "#A2A28F", // 클릭하지 않은 경우 기본 line-width는 2로 설정
    ], // 테두리 색상
    "line-width": [
      "case",
      ["boolean", ["feature-state", "clicked"], false],
      5, // 클릭한 경우 line-width를 5로 설정
      1, // 클릭하지 않은 경우 기본 line-width는 2로 설정
    ], // 테두리 너비
  },
};

const BUILDING_FILL_LAYER_NAME = "building-fill-layer";
const BUILDING_FILL_LAYER: mapboxgl.FillLayer = {
  id: BUILDING_FILL_LAYER_NAME,
  type: "fill",
  source: ZONE_SOURCE_NAME,

  "source-layer": "building",
  paint: {
    // 스타일 및 색상 설정
    "fill-color": "#A2A28F",
    "fill-opacity": 0.5,
  },
};

const BUILDING_FILLL_EXTRUSION_LAYER_NAME = "building-fill-extrusion-layer";
const BUILDING_FILLL_EXTRUSION_LAYER: mapboxgl.FillExtrusionLayer = {
  id: BUILDING_FILLL_EXTRUSION_LAYER_NAME,
  type: "fill-extrusion",
  source: ZONE_SOURCE_NAME,

  "source-layer": "building",
  minzoom: 17,
  paint: {
    "fill-extrusion-color": "#aaa",

    // Use an 'interpolate' expression to
    // add a smooth transition effect to
    // the buildings as the user zooms in.
    "fill-extrusion-height": [
      "interpolate",
      ["linear"],
      ["zoom"],
      17,
      0,
      17.05,
      ["get", "bHeight"],
    ],
    "fill-extrusion-base": 0,
    "fill-extrusion-opacity": 0.8,
  },
  // filter: [">", "bHeight", "0"],
};

export const JIJUK_SOURCE_NAME = "jijuk-source";
export const JIJUK_FILL_LAYER_NAME = "jijuk-fill-layer";
const JIJUK_FILL_LAYER: mapboxgl.FillLayer = {
  id: JIJUK_FILL_LAYER_NAME,
  type: "fill",
  source: JIJUK_SOURCE_NAME,

  "source-layer": "default",
  paint: {
    // 스타일 및 색상 설정
    "fill-color": "transparent",
  },
};

const JIJUK_STROKE_LAYER_NAME = "jijuk-stroke-layer";
const JIJUK_STROKE_LAYER: mapboxgl.LineLayer = {
  id: JIJUK_STROKE_LAYER_NAME,
  type: "line",
  source: JIJUK_SOURCE_NAME,

  "source-layer": "default",
  paint: {
    // 스타일 및 색상 설정
    "line-color": [
      "case",
      ["boolean", ["feature-state", "clicked"], false],
      "red", // 클릭한 경우 line-width를 5로 설정
      "#A2A28F", // 클릭하지 않은 경우 기본 line-width는 2로 설정
    ], // 테두리 색상
    "line-width": [
      "step",
      ["zoom"],
      [
        "case",
        ["boolean", ["feature-state", "clicked"], false],
        5, // 클릭한 경우 line-width를 5로 설정
        0, // 클릭하지 않은 경우 기본 line-width는 1로 설정
      ],
      15,
      [
        "case",
        ["boolean", ["feature-state", "clicked"], false],
        5, // 클릭한 경우 line-width를 5로 설정
        1, // 클릭하지 않은 경우 기본 line-width는 1로 설정
      ],
    ], // 테두리 너비
    // "line-width": [
    //   "match",
    //   [
    //     "case",
    //     ["boolean", ["feature-state", "clicked"], false],
    //     "true",
    //     "false",
    //   ],
    //   "true",
    //   5, // 클릭한 경우 line-width를 5로 설정
    //   1, // 클릭하지 않은 경우 기본 line-width는 1로 설정
    // ], // 테두리 너비
  },
  // filter: ["any", [[">=", ["zoom"], 15]]],
  filter: [">=", ["zoom"], 14],
};

const JIJUK_JIBUN_LABEL_LAYER_NAME = "jijuk-jibun-label-layer";
const JIJUK_JIBUN_LABEL_LAYER: mapboxgl.SymbolLayer = {
  id: JIJUK_JIBUN_LABEL_LAYER_NAME,
  type: "symbol",
  source: JIJUK_SOURCE_NAME, // MVT 소스 ID
  "source-layer": "centroid", // MVT 소스 내 레이어 이름
  layout: {
    "text-field": ["get", "jibun"], // 텍스트 속성을 사용하여 텍스트를 출력합니다.
    "text-anchor": "center", // 텍스트를 중앙에 정렬합니다.
    // "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"], // 폰트를 설정합니다.
    "text-size": 10, // 텍스트 크기를 설정합니다.
  },
  paint: {
    "text-color": "#000000", // 텍스트 색상을 설정합니다.
  },
  filter: [">=", ["zoom"], 16],
};

const JIJUK_POI_LABEL_LAYER_NAME = "jijuk-poi-label-layer";
const JIJUK_POI_LABEL_LAYER: mapboxgl.SymbolLayer = {
  id: JIJUK_POI_LABEL_LAYER_NAME,
  type: "symbol",
  source: JIJUK_SOURCE_NAME, // MVT 소스 ID
  "source-layer": "poi", // MVT 소스 내 레이어 이름
  layout: {
    "text-field": ["get", "name"], // 텍스트 속성을 사용하여 텍스트를 출력합니다.
    "text-anchor": "center", // 텍스트를 중앙에 정렬합니다.
    // "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"], // 폰트를 설정합니다.
    "text-size": 14, // 텍스트 크기를 설정합니다.
    "symbol-spacing": 700,
  },
  paint: {
    "text-color": "#000000", // 텍스트 색상을 설정합니다.
  },
  // 조건에 따른 필터 설정

  // filter: [
  //   "case",
  //   [">=", ["zoom"], 12],
  //   ["in", ["get", "clDesc"], "403003010300"], // 줌 레벨 12 이상일 때 clDesc가 1, 2, 3인 데이터 필터링
  //   [">=", ["zoom"], 10],
  //   [
  //     "any",
  //     ["in", ["get", "clDesc"], "403003010200"],
  //     [
  //       "all",
  //       ["==", ["get", "clDesc"], "511001010200"],
  //       ["==", ["slice", ["get", "name"], -2], "IC"], // name이 IC로 끝나는 것만 필터링
  //     ],
  //   ], // 줌 레벨 11 이상일 때 clDesc가 1, 2, 3, 4, 5인 데이터 필터링
  //   false, // 그 외의 경우 표시하지 않음
  // ],
  filter: [
    "any",
    [
      "all",
      [">=", ["zoom"], 10],
      [
        "any",
        ["in", ["get", "clDesc"], "403003010200"],
        [
          "all",
          ["==", ["get", "clDesc"], "511001010200"],
          ["==", ["slice", ["get", "name"], -2], "IC"], // name이 IC로 끝나는 것만 필터링
        ],
        [
          "all",
          ["==", ["get", "clDesc"], "403001010000"],
          ["==", ["slice", ["get", "name"], -2], "공항"], // name이 IC로 끝나는 것만 필터링
        ],
      ], // 줌 레벨 11 이상일 때 clDesc가 1, 2, 3, 4, 5인 데이터 필터링
    ],
    [
      "all",
      [">=", ["zoom"], 11],
      [
        "in",
        ["get", "clDesc"],
        [
          "literal",
          [
            "103002010000",
            "103002020000",
            "403002010100",
            "403002010200",
            "403002010300",
            "403002010400",
          ],
        ],
      ],
    ],
    [
      "all",
      [">=", ["zoom"], 12],
      [
        "any",
        [
          "in",
          ["get", "clDesc"],
          [
            "literal",
            [
              "403003010300",
              "103002030000",
              "103002040000",
              "103002050000",
              "102007000000",
              "102008000000",
              "203156010100",
              "203156010200",
              "203164020100",
            ],
          ],
        ],
        [
          "all",
          ["==", ["get", "clDesc"], "103001370800"],
          ["==", ["slice", ["get", "name"], -3], "경찰서"], // name이 IC로 끝나는 것만 필터링
        ],
        [
          "all",
          ["==", ["get", "clDesc"], "103001020000"],
          ["==", ["slice", ["get", "name"], -3], "대학교"], // name이 IC로 끝나는 것만 필터링
        ],
      ],
    ],
    [
      "all",
      [">=", ["zoom"], 13],
      [
        "any",
        [
          "in",
          ["get", "clDesc"],
          [
            "literal",
            [
              "203156020100",
              "203156030100",
              "203156040000",
              "203156050000",
              "203156060000",
              "203156070000",
              "103001021100",
            ],
          ],
        ],
        [
          "all",
          ["==", ["get", "clDesc"], "102009000000"],
          ["==", ["slice", ["get", "name"], -4], "지방법원"], // name이 IC로 끝나는 것만 필터링
        ],
      ],
    ],
    [
      "all",
      [">=", ["zoom"], 14],
      [
        "in",
        ["get", "clDesc"],
        [
          "literal",
          [
            "103002060000",
            "103002070000",
            "103002080000",
            "203046020200",
            "404004040000",
            "103001021200",
            "103001021300",
          ],
        ],
      ],
    ],
    [
      "all",
      [">=", ["zoom"], 15],
      [
        "in",
        ["get", "clDesc"],
        [
          "literal",
          ["203157010000", "203157020000", "203157030000", "203157040000"],
        ],
      ],
    ],
  ],
  // filter: [
  //   // ">=",
  //   // ["zoom"],
  //   // 12,
  //   "all",
  //   [
  //     "any",
  //     // 줌 레벨 12 이상에서 지하쳘역 표시
  //     [
  //       "all",
  //       [">=", ["zoom"], 12],
  //       [
  //         "match",
  //         ["get", "clDesc"],
  //         ["403003010200", "403003010300"],
  //         true,
  //         false,
  //       ],
  //     ],

  //     // 줌 레벨 12 미만 기차/고속도로IC 표시
  //     [
  //       "all",
  //       ["<", ["zoom"], 12],
  //       ["match", ["get", "clDesc"], ["403003010200"], true, false],
  //     ],
  //     // 줌 레벨 11 이상이면서 clDesc 값이 1, 2, 3, 4, 5인 경우
  //     // [
  //     //   "all",
  //     //   [">=", "zoom", 11],
  //     //   [
  //     //     "match",
  //     //     ["get", "clDesc"],
  //     //     ["403002110000", "403003010500", "403003010800", "403003010000"],
  //     //     true,
  //     //     false,
  //     //   ],
  //     // ],
  //   ],
  // ],
};

const POI_SOURCE_NAME = "poi_source";
const POI_POINT_LAYER_NAME = "poi_point_layer";
const POI_POINT_LAYER: mapboxgl.CircleLayer = {
  id: POI_POINT_LAYER_NAME,
  type: "circle",
  source: POI_SOURCE_NAME,
  paint: {
    "circle-color": [
      "match",
      ["get", "category"],
      "center",
      "red",
      "poi",
      ["get", "color"],
      "white",
    ],
    "circle-radius": 4,
    "circle-stroke-width": 1,
    "circle-stroke-color": "black",
  },
  filter: [
    "all",
    ["==", "$type", "Point"],
    ["any", ["==", "category", "center"], ["==", "category", "poi"]],
  ],
};
const POI_LINE_LAYER_NAME = "poi_line_layer";
const POI_LINE_LAYER: mapboxgl.LineLayer = {
  id: POI_LINE_LAYER_NAME,
  type: "line",
  source: POI_SOURCE_NAME,
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: { "line-color": "black", "line-width": 2, "line-dasharray": [2, 2] },
  filter: ["==", "$type", "LineString"],
};

const POI_CIRCLE_LAYER_NAME = "poi_circle_layer";
const POI_CIRCLE_LAYER: mapboxgl.LineLayer = {
  id: POI_CIRCLE_LAYER_NAME,
  type: "line",
  source: POI_SOURCE_NAME,
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: { "line-color": "black", "line-width": 2, "line-dasharray": [2, 2] },
  filter: ["==", "$type", "Polygon"],
};

const POI_LABEL_LAYER_NAME = "poi-label-layer";
const POI_LABEL_LAYER: mapboxgl.SymbolLayer = {
  id: POI_LABEL_LAYER_NAME,
  type: "symbol",
  source: POI_SOURCE_NAME,
  layout: {
    "text-field": ["get", "name"], // 텍스트 속성을 사용하여 텍스트를 출력합니다.
    "text-anchor": "center", // 텍스트를 중앙에 정렬합니다.
    // "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"], // 폰트를 설정합니다.
    "text-size": 16, // 텍스트 크기를 설정합니다.
  },
  paint: {
    "text-color": "#000000", // 텍스트 색상을 설정합니다.
  },
};

const DONG_MARKER_LAYER_NAME = "dongmarker";
const DONG_MARKER_LAYER: mapboxgl.SymbolLayer = {
  id: DONG_MARKER_LAYER_NAME,
  type: "symbol",
  source: JIJUK_SOURCE_NAME,
  "source-layer": "dongmarker", // MVT 소스 내 레이어 이름
  // minzoom: 13,

  layout: {
    "icon-size": 1,
    "text-font": [
      "Noto Sans SemiCondensed SemiBold",
      "Arial Unicode MS Regular",
    ],
    "icon-image": DONG_MARKER_LAYER_NAME,
    // get the title name from the source's "title" property
    "text-field": ["get", "name"],
    "text-max-width": 2,
    "icon-text-fit": "width",
    "icon-text-fit-padding": [4, 12, 4, 12],
    "text-padding": 3,
  },
  // maxzoom: 22,
  paint: {
    "text-color": "rgb(255, 255, 255)",
  },

  filter: [">=", ["zoom"], 14],
};

const SUBWAY_LINE_LAYER_NAME = "subway";
const SUBWAY_LINE_LAYER: mapboxgl.LineLayer = {
  id: SUBWAY_LINE_LAYER_NAME,
  type: "line",
  source: JIJUK_SOURCE_NAME,
  "source-layer": "subway",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": ["get", "colour"],
    "line-width": 2,
  },
};

const PLAN_LAYER_NAME = "plan-layer";
const PLAN_SOURCE_NAME = "plan-source";
const PLAN_LAYER: mapboxgl.RasterLayer = {
  id: PLAN_LAYER_NAME,
  type: "raster",
  source: PLAN_SOURCE_NAME,
  paint: {
    "raster-fade-duration": 0,
    "raster-opacity": 0.5,
  },
};

const DEFAULT_LNG = 126.92547774899896;
const DEFAULT_LAT = 37.52516540971145;
const DEFAULT_ZOOM = 11;

// 광고 택지 정보를 가져오는 함수
async function fetchAdvertiseData(bounds: any) {
  const url = `/private-land-sites/advertise?xMax=${bounds.getEast()}&xMin=${bounds.getWest()}&yMax=${bounds.getSouth()}&yMin=${bounds.getNorth()}`;
  const res = await axiosInst(url);

  return res.data.privateLandSites;
}

// 광고 택지 마커를 업데이트하는 함수
function updateAdvertiseMarker(
  data: any,
  map: any,
  openPrivateLandSiteDetail: (privateLandSite: any) => void,
  privateLandSitePopRef: MutableRefObject<any>
) {
  const geojson = {
    type: "FeatureCollection",
    features: data.map((landSite: any) => ({
      type: "Feature",
      geometry: JSON.parse(landSite.geom_geojson),
      properties: { ...landSite }, // 모든 landSite 속성을 properties에 복사
    })),
  };

  if (map.getSource("advertise")) {
    map.getSource("advertise").setData(geojson);
  } else {
    map.loadImage("/images/marker_ad.png", (error: any, image: any) => {
      if (error) throw error;

      map.addImage("advertise", image);

      map.addSource("advertise", {
        type: "geojson",
        data: geojson,
      });

      map.addLayer({
        id: "advertise",
        type: "symbol",
        source: "advertise",
        layout: {
          "icon-size": 0.8,
          "icon-image": "advertise",
          "icon-anchor": "bottom",
        },
      });

      // Add click event listener for the points layer
      map.on("click", "advertise", (e: any) => {
        const features = e.features;

        const site = features[0].properties;

        site.jijukIds = JSON.parse(site.jijukIds);
        site.privateLandSiteBlocks = JSON.parse(site.privateLandSiteBlocks);

        // 정보 창 HTML
        const infoWindow = document.createElement("div");
        infoWindow.className = "info-window";
        ReactDOM.hydrate(
          <PrivateLandSiteInfoWindow
            privateLandSite={site}
            openPrivateLandSiteDetail={openPrivateLandSiteDetail}
          />,
          infoWindow
        );

        if (privateLandSitePopRef.current)
          privateLandSitePopRef.current.remove();

        privateLandSitePopRef.current = new mapboxgl.Popup({
          maxWidth: "full",
          offset: 50,
          anchor: "top",
        })
          .setLngLat([site.lng, site.lat])
          .setDOMContent(infoWindow)
          .addTo(map);
      });
    });
  }
}

let debounceTimer: NodeJS.Timeout | null = null;

interface MapProps {
  landDetails?: LandInfoDetails;
  bounds?: mapboxgl.LngLatBounds;
  onChangeLandInfo?: (info?: LandInfoType) => void;
  onChangeBounds?: (b: mapboxgl.LngLatBounds) => void;
  openPrivateLandSiteDetail: (privateLandSite: any) => void;
}

const Map = ({
  landDetails,
  onChangeLandInfo,
  onChangeBounds,
  openPrivateLandSiteDetail,
}: MapProps) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const privateLandSitePopRef = useRef<mapboxgl.Popup | null>(null);

  const {
    mapState: { lat, lng, bounds, fileId },
    map,
    land,
    prevClickedFeatureRef,
    setLand,
  } = useMap();

  const {
    drawInit,
    draw,
    area,
    updateArea,
    radiusDistance,
    onMouseMove,
    ruler,
    handleRulerOn,
  } = useToolBax(map);

  const [zoom, setZoom] = useState(DEFAULT_ZOOM);

  const initImage = useCallback(
    (imageSrc: string, imageBounds: number[][]) => {
      if (!map.current) return;

      const source = map.current?.getSource(
        PLAN_SOURCE_NAME
      ) as mapboxgl.ImageSource;
      if (source) {
        source.updateImage({ url: imageSrc, coordinates: imageBounds });
      } else {
        map.current?.addSource(PLAN_SOURCE_NAME, {
          type: "image",
          // url: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=",
          url: imageSrc,
          coordinates: imageBounds,
        });

        map.current?.addLayer(PLAN_LAYER);
      }
    },
    [map]
  );

  // 디바운스 초기화
  useEffect(() => {
    return () => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
    };
  }, []);

  useEffect(() => {
    if (!map.current) return;
    if (!lat || !lng) return;
    map.current.setCenter({ lat, lng });
  }, [lat, lng, map]);

  useEffect(() => {
    if (!map.current) return;
    if (!bounds) return;

    if (bounds.length > 0) {
      //@ts-ignore
      map.current.fitBounds([bounds[0], bounds[2]], { padding: 100 });
    }
  }, [bounds, map]);

  useEffect(() => {
    if (fileId && bounds && bounds.length > 0) {
      const getImage = async (id: number) => {
        const resp = await axiosInst.get(`/files/${id}`);
        console.log(resp.data);
        initImage(resp.data.url, bounds);
      };
      getImage(fileId);
    }
  }, [bounds, fileId, initImage]);

  //   const source = map.current?.getSource(POI_SOURCE_NAME);
  //   if (source) {
  //     //@ts-ignore
  //     source.setData(turf.featureCollection([]));
  //   }
  //   if (prevClickedFeatureRef.current) {
  //     map.current?.setFeatureState(
  //       {
  //         source: JIJUK_SOURCE_NAME,
  //         sourceLayer: "default",
  //         id: prevClickedFeatureRef.current,
  //       },
  //       { clicked: false }
  //     );
  //     prevClickedFeatureRef.current = null;
  //   }

  useEffect(() => {
    if (!map.current) return;
    const source = map.current.getSource(POI_SOURCE_NAME);
    if (landDetails && source) {
      //@ts-ignore
      const centerFeature = turf.feature(landDetails.center, {
        category: "center",
      });
      const circleFeatures = _.map([100, 300, 500, 1000], (dist) => {
        const properties = {
          category: "circle",
          name: `${dist}m`,
        };
        //@ts-ignore
        const circle = turf.circle(centerFeature, dist, {
          steps: 64,
          units: "meters",
          properties,
        });
        const symbols = _.map([0, 15, 31, 47], (p) =>
          turf.point(circle.geometry.coordinates[0][p], properties)
        );
        return [circle, ...symbols];
      }).flat();

      const poisFeatures = Object.values(landDetails.infras)
        .map((category: any) =>
          Object.keys(category).map((key: any) => {
            const poi = category[key];
            return turf.feature(poi.geometry, {
              ...poi,
              category: "poi",
              geometry: undefined,
              color: getColorBySubCategory(key),
              class: key,
            });
          })
        )
        .flat();
      const lineFeatures = Object.values(landDetails.infras)
        .map((category: any) =>
          Object.values(category).map((poi: any) =>
            turf.lineString([
              //@ts-ignore
              centerFeature.geometry.coordinates,
              poi.geometry.coordinates,
            ])
          )
        )
        .flat();

      const combinedGeoJSON = turf.featureCollection([
        centerFeature,
        ...poisFeatures,
        ...lineFeatures,
        ...circleFeatures,
      ]);

      // const bbox = turf.bbox(combinedGeoJSON) as mapboxgl.LngLatBoundsLike;

      // map.current.fitBounds(bbox, {
      //   animate: false,
      //   padding: { left: 256, top: 0, right: 0, bottom: 0 },
      // });

      //@ts-ignore
      source.setData(combinedGeoJSON);
    }
    if (!landDetails && source) {
      //@ts-ignore
      source.setData(turf.featureCollection([]));
      if (prevClickedFeatureRef.current) {
        map.current?.setFeatureState(
          {
            source: JIJUK_SOURCE_NAME,
            sourceLayer: "default",
            id: prevClickedFeatureRef.current,
          },
          { clicked: false }
        );
        prevClickedFeatureRef.current = null;
      }
    }
  }, [landDetails, map, prevClickedFeatureRef]);

  useEffect(() => {
    if (map.current === null && mapContainer.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v12",
        center: [DEFAULT_LNG, DEFAULT_LAT],
        zoom: DEFAULT_ZOOM,
        // bearing: 11.200000000000273,
        // pitch: 72.99999999999999,
      });
    }

    const load = async () => {
      console.log("load", map.current);
      if (!map.current) return;

      // 광고 마커 이미지를 로드합니다.
      const bounds = map.current.getBounds();
      const data = await fetchAdvertiseData(bounds);
      updateAdvertiseMarker(
        data,
        map.current,
        openPrivateLandSiteDetail,
        privateLandSitePopRef
      );

      map.current.loadImage(
        "/images/town_name.png",
        (error: any, image: any) => {
          if (error) throw error;

          console.log("loadImage");

          map.current.addImage(DONG_MARKER_LAYER_NAME, image);
          // Add a GeoJSON source with 2 points
          map.current.addSource(DONG_MARKER_LAYER_NAME, {
            type: "vector",
            tiles: [`${TILE_SERVER_URI}/tiles/{z}/{x}/{y}.pbf`],
          });

          // Add a symbol layer
          map.current.addLayer(DONG_MARKER_LAYER);
        }
      );

      if (!map.current.getSource(JIJUK_SOURCE_NAME)) {
        map.current.addSource(JIJUK_SOURCE_NAME, {
          type: "vector",
          tiles: [`${TILE_SERVER_URI}/tiles/{z}/{x}/{y}.pbf`],
        });
      }

      if (!map.current.getSource(POI_SOURCE_NAME)) {
        map.current.addSource(POI_SOURCE_NAME, {
          type: "geojson",
          data: turf.featureCollection([]),
        });
      }

      if (!map.current.getSource(ZONE_SOURCE_NAME)) {
        map.current.addSource(ZONE_SOURCE_NAME, {
          type: "vector",
          tiles: [`${TILE_SERVER_URI}/zones/{z}/{x}/{y}.pbf`],
        });
      }

      if (!map.current.getLayer(ZONE_FILL_LAYER_NAME)) {
        map.current.addLayer(ZONE_FILL_LAYER);
      }

      if (!map.current.getLayer(JIJUK_FILL_LAYER_NAME)) {
        map.current.addLayer(JIJUK_FILL_LAYER);
      }

      if (!map.current.getLayer(JIJUK_STROKE_LAYER_NAME)) {
        map.current.addLayer(JIJUK_STROKE_LAYER);
      }

      if (!map.current.getLayer(BUILDING_OUTLINE_LAYER_NAME)) {
        map.current.addLayer(BUILDING_OUTLINE_LAYER);
      }

      if (!map.current.getLayer(BUILDING_FILL_LAYER_NAME)) {
        map.current.addLayer(BUILDING_FILL_LAYER);
      }

      if (!map.current.getLayer(BUILDING_FILLL_EXTRUSION_LAYER_NAME)) {
        map.current.addLayer(BUILDING_FILLL_EXTRUSION_LAYER);
      }

      if (!map.current.getLayer(JIJUK_JIBUN_LABEL_LAYER_NAME)) {
        map.current.addLayer(JIJUK_JIBUN_LABEL_LAYER);
      }

      if (!map.current.getLayer(JIJUK_POI_LABEL_LAYER_NAME)) {
        map.current.addLayer(JIJUK_POI_LABEL_LAYER);
      }

      if (!map.current.getLayer(POI_CIRCLE_LAYER_NAME)) {
        map.current.addLayer(POI_CIRCLE_LAYER);
      }

      if (!map.current.getLayer(POI_LINE_LAYER_NAME)) {
        map.current.addLayer(POI_LINE_LAYER);
      }

      if (!map.current.getLayer(SUBWAY_LINE_LAYER)) {
        map.current.addLayer(SUBWAY_LINE_LAYER);
      }

      if (!map.current.getLayer(POI_LABEL_LAYER_NAME)) {
        map.current.addLayer(POI_LABEL_LAYER);
      }

      if (!map.current.getLayer(POI_POINT_LAYER_NAME)) {
        map.current.addLayer(POI_POINT_LAYER);
      }
      onChangeBounds?.(map.current.getBounds());

      setZoom(map.current.getZoom());

      // 지도 확대 축소 컨트롤을 생성합니다.
      map.current.addControl(
        new mapboxgl.NavigationControl({
          showCompass: false,
        }),
        "bottom-right"
      );

      // 현재 위치 컨트롤을 생성합니다.
      map.current.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
          showUserHeading: true,
        }),
        "bottom-right"
      );

      // 거리 측정 콘트롤을 생성합니다.
      map.current.addControl(ruler, "top-right");
      // 면적 계산 컨트롤을 생성합니다.
      drawInit();
    };

    // const onLandClick = (e: any) => {
    //   if (!map.current) return;
    //   if (prevClickedFeatureRef.current) {
    //     map.current.setFeatureState(
    //       {
    //         source: JIJUK_SOURCE_NAME,
    //         sourceLayer: "default",
    //         id: prevClickedFeatureRef.current,
    //       },
    //       { clicked: false }
    //     );
    //     prevClickedFeatureRef.current = null;
    //   }
    //   //   setLandDetails(undefined);
    //   const source = map.current?.getSource(POI_SOURCE_NAME);
    //   if (source) {
    //     //@ts-ignore
    //     source.setData(turf.featureCollection([]));
    //   }
    //   const features = map.current.queryRenderedFeatures(e.point);
    //   console.log("features", features);
    //   const land = _.find(
    //     features,
    //     (f) => f.layer.id === JIJUK_FILL_LAYER_NAME
    //   );
    //   const jibun = land?.properties?.jibun;
    //   if (_.last(jibun) === "도") {
    //     onChangeLandInfo?.(undefined);
    //     return;
    //   }
    //   const zone = _.find(features, (f) => f.layer.id === ZONE_FILL_LAYER_NAME);
    //   if (land) {
    //     const id = land.id as number;
    //     prevClickedFeatureRef.current = id;
    //     map.current.setFeatureState(
    //       {
    //         source: JIJUK_SOURCE_NAME,
    //         sourceLayer: "default",
    //         id: id,
    //       },
    //       { clicked: true }
    //     );
    //     onChangeLandInfo?.({ id: land.id as number });
    //   } else {
    //     onChangeLandInfo?.(undefined);
    //   }
    //   console.log("onLandClick", land, zone);
    // };

    const onMoveEnd = async () => {
      if (!map.current) return;

      const z = map.current.getZoom();

      onChangeBounds?.(map.current.getBounds());
      setZoom(z);

      if (z < 14) {
        setLand(undefined);
      }

      const bounds = map.current.getBounds();

      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }

      debounceTimer = setTimeout(async () => {
        const data = await fetchAdvertiseData(bounds);
        updateAdvertiseMarker(
          data,
          map.current,
          openPrivateLandSiteDetail,
          privateLandSitePopRef
        );
      }, 1000);
    };

    const onLandClick = (e: any) => {
      if (!map.current || ruler.isActive || draw.getMode() !== "simple_select")
        return;

      if (zoom < 14) {
        return;
      }

      const features = map.current.queryRenderedFeatures(e.point);

      if (features.find((f: any) => f.layer.id === "advertise")) {
        return;
      }

      if (prevClickedFeatureRef.current) {
        map.current.setFeatureState(
          {
            source: JIJUK_SOURCE_NAME,
            sourceLayer: "default",
            id: prevClickedFeatureRef.current,
          },
          { clicked: false }
        );
        prevClickedFeatureRef.current = null;
      }
      // console.log("features", features);
      const fland = _.find(
        features,
        (f) => f.layer.id === JIJUK_FILL_LAYER_NAME
      );

      const jibun = fland?.properties?.jibun;
      if (_.last(jibun) === "도") {
        setLand(undefined);
        return;
      }

      if (land !== undefined && land?.id === fland?.id) {
        setLand(undefined);
        return;
      }
      const zone = _.find(features, (f) => f.layer.id === ZONE_FILL_LAYER_NAME);
      var f = map.current.querySourceFeatures(JIJUK_SOURCE_NAME, {
        sourceLayer: "centroid",
        filter: ["==", "oid", fland?.id],
      });

      // features 배열에서 첫 번째 특성의 좌표를 가져옵니다.
      let coordinates;
      if (f.length > 0) {
        coordinates = f[0].geometry.coordinates;
        console.log("Coordinates:", coordinates);
      } else {
        console.log("Feature not found.");
      }
      console.log("land", fland, "zone", zone, "f", f);
      if (fland && zone) {
        const id = fland.id as number;
        prevClickedFeatureRef.current = id;
        map.current.setFeatureState(
          {
            source: JIJUK_SOURCE_NAME,
            sourceLayer: "default",
            id: id,
          },
          { clicked: true }
        );
        setLand({
          id: fland.id,
          ...fland?.properties,
          ...zone?.properties,
          coordinates,
        });
      } else {
        setLand(undefined);
      }
    };

    map.current?.on("load", load);
    map.current?.on(
      "click",
      // [JIJUK_FILL_LAYER_NAME, ZONE_FILL_LAYER_NAME],
      onLandClick
    );
    map.current?.on("moveend", onMoveEnd);
    map.current?.on("click", updateArea);
    map.current?.on("mousemove", onMouseMove);
    map.current?.on("ruler.on", handleRulerOn);

    return () => {
      map.current?.off("load", load);
      map.current?.off("click", onLandClick);
      map.current?.off("moveend", onMoveEnd);
      map.current?.off("click", updateArea);
      map.current?.off("mousemove", onMouseMove);
      map.current?.off("ruler.on", handleRulerOn);

      // map.current?.off("click", JIJUK_FILL_LAYER_NAME, onLandClick);
      // map.current?.off("click", ZONE_FILL_LAYER_NAME, onLandClick);
    };
  }, [
    draw,
    drawInit,
    handleRulerOn,
    land,
    map,
    onChangeBounds,
    onChangeLandInfo,
    onMouseMove,
    openPrivateLandSiteDetail,
    prevClickedFeatureRef,
    ruler,
    setLand,
    updateArea,
    zoom,
  ]);

  return (
    <>
      <div
        ref={mapContainer}
        className="map-container w-full h-full min-w-screen min-h-screen "
      />
      {(window.location.hostname === "localhost" ||
        window.location.hostname === "dev.prop-insight.com") && (
        <div className="absolute bottom-20 right-20 h-10 px-2.5 py-1.5 bg-white rounded shadow justify-center items-center">
          Zoom Level : {zoom.toFixed(2)}
        </div>
      )}
      <div className=" absolute bottom-5 right-20 h-10 px-2.5 py-1.5 bg-white rounded shadow justify-center items-center gap-3 inline-flex">
        <div className="justify-center items-center gap-1 flex">
          <div className="w-2.5 h-2.5 bg-pink-200 rounded-full" />
          <div className="text-zinc-800 text-xs font-normal font-['Noto Sans'] lowercase leading-3">
            일반상업지역
          </div>
        </div>
        <div className="justify-center items-center gap-1 flex">
          <div className="w-2.5 h-2.5 bg-amber-300 rounded-full" />
          <div className="text-zinc-800 text-xs font-normal font-['Noto Sans'] lowercase leading-3">
            전용주거지역
          </div>
        </div>
        <div className="justify-start items-center gap-1 flex">
          <div className="w-2.5 h-2.5 bg-slate-400 rounded-full" />
          <div className="text-zinc-800 text-xs font-normal font-['Noto Sans'] lowercase leading-3">
            공업지역
          </div>
        </div>
        <div className="justify-start items-center gap-1 flex">
          <div className="w-2.5 h-2.5 bg-yellow-200 rounded-full" />
          <div className="text-zinc-800 text-xs font-normal font-['Noto Sans'] lowercase leading-3">
            일반주거지역
          </div>
        </div>
        <div className="justify-start items-center gap-1 flex">
          <div className="w-2.5 h-2.5 bg-lime-400 rounded-full" />
          <div className="text-zinc-800 text-xs font-normal font-['Noto Sans'] lowercase leading-3">
            녹지지역
          </div>
        </div>
      </div>
      {area > 0 && (
        <div className="absolute top-[22px] w-[147px] h-[60px] bg-white right-20 px-[14px] py-[11px] rounded text-base z-50 shadow">
          <div className="text-text-darkgray text-h2 leading-3 mb-[5px]">
            총면적
          </div>
          <div className=" text-[20px]">
            <span className="text-primary-main-color">
              {area.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
            </span>
            m²
          </div>
        </div>
      )}
      {radiusDistance.distance > 0 && (
        <div className="absolute top-[22px] w-[120px] h-[60px] bg-white right-20 px-[14px] py-[11px] rounded text-base z-50 shadow">
          <div className="text-text-darkgray text-h2 leading-3 mb-[5px]">
            반경
          </div>
          <div className=" text-[20px]">
            <span className="text-primary-main-color">
              {radiusDistance.distance > 1000
                ? `${(radiusDistance.distance / 1000)
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}`
                : `${radiusDistance.distance}`}
            </span>
            {radiusDistance.distance > 1000 ? "km" : "m"}
          </div>
        </div>
      )}
    </>
  );
};

export default Map;
