import { faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PrivateLandSite } from "@prop-insight/database";
import {
  PrivateLandSiteCategory,
  PrivateLandUseZone,
} from "contans/SelectItems";
import { useMoveMap } from "hooks/useMoveMap";

interface PrivateLandSiteTableProps {
  privateLandSites: PrivateLandSite[];
  onClickDetailPanel: (privateLandSite: PrivateLandSite) => void;
}

const PrivateLandSiteTable = ({
  privateLandSites,
  onClickDetailPanel,
}: PrivateLandSiteTableProps) => {
  const { moveMap } = useMoveMap();

  return (
    <div>
      {privateLandSites.map((privateLandSite) => {
        const getColor = (privateLandSite: PrivateLandSite) => {
          const category =
            PrivateLandSiteCategory[privateLandSite.landCategory];

          if (category === "임야") {
            return "bg-[#427EB5]";
          } else if (category === "대지") {
            return "bg-[#FF9737]";
          } else if (category === "공장") {
            return "bg-[#FF6056]";
          } else if (category === "종교") {
            return "bg-[#1A6667]";
          }
        };

        return (
          <div
            onClick={() => {
              onClickDetailPanel(privateLandSite);
            }}
            className="w-full h-20 cursor-pointer flex items-center justify-between border-b-[1px]"
            key={privateLandSite.id}
          >
            <div
              className={`w-[38.9px] h-[31px] rounded flex items-center justify-center text-white text-p4 font-semibold ${getColor(
                privateLandSite
              )}`}
            >
              {PrivateLandSiteCategory[privateLandSite.landCategory]}
            </div>
            <div className="w-[46px] text-p3 text-center">
              {PrivateLandUseZone[privateLandSite.landUseZone]}
            </div>
            <div className="w-[181px]">
              <p className="text-p3 font-bold">{privateLandSite.title}</p>
              <p className="text-p3">{privateLandSite.address}</p>
            </div>
            <div className="w-[72px] text-p3 text-center">
              {privateLandSite.address.split(" ")[0]}
            </div>
            <div className="h-[31px] flex items-center">
              <FontAwesomeIcon
                icon={faMapLocationDot}
                size="lg"
                onClick={(event) => {
                  event.stopPropagation();

                  moveMap({
                    jijukIds: privateLandSite.jijukIds,
                    lat: privateLandSite.lat,
                    lng: privateLandSite.lng,
                  });
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PrivateLandSiteTable;
