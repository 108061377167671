import { Button } from "@prop-insight/components";
import clsx from "clsx";
import React from "react";

interface PrivateLandSiteTableDataProps {
  title: string;
  data: React.ReactNode;
  type?: "contained" | "outlined";
  width?: "long" | "short";
  isTextBox?: boolean;
}

const PrivateLandSiteTableData = ({
  title,
  data,
  type = "contained",
  width = "short",
  isTextBox = false,
}: PrivateLandSiteTableDataProps) => {
  if (type === "contained") {
    if (width === "long") {
      return (
        <div
          className={clsx(
            "flex gap-[10px] mb-[7px] ",
            isTextBox ? "items-start" : "items-center"
          )}
        >
          <Button
            type="button"
            color="primary"
            variant="contained"
            btnStyle={`w-[110px] !h-[24px] !text-h3`}
          >
            <p className="text-h3">{title}</p>
          </Button>
          <span className="text-p3">{data}</span>
        </div>
      );
    } else {
      return (
        <div
          className={clsx(
            "flex gap-[10px] mb-[7px] ",
            isTextBox ? "items-start" : "items-center"
          )}
        >
          <Button
            type="button"
            color="primary"
            variant="contained"
            btnStyle={`w-[70px] !h-[24px] !text-h3`}
          >
            <p className="text-h3">{title}</p>
          </Button>
          <span className="text-p3">{data}</span>
        </div>
      );
    }
  } else {
    if (width === "long") {
      return (
        <div
          className={clsx(
            "flex gap-[10px] mb-[7px] ",
            isTextBox ? "items-start" : "items-center"
          )}
        >
          <Button
            type="button"
            color="primary"
            variant="outlined"
            btnStyle="w-[110px] !h-[24px] !text-h3"
          >
            <p className="text-h3">{title}</p>
          </Button>
          <span className="text-p3">{data}</span>
        </div>
      );
    } else {
      return (
        <div
          className={clsx(
            "flex gap-[10px] mb-[7px] ",
            isTextBox ? "items-start" : "items-center"
          )}
        >
          <Button
            type="button"
            color="primary"
            variant="outlined"
            btnStyle="w-[70px] !h-[24px] !text-h3"
          >
            <p className="text-h3">{title}</p>
          </Button>
          <span className="text-p3">{data}</span>
        </div>
      );
    }
  }
};

export default PrivateLandSiteTableData;
