import { Button } from "@prop-insight/components";
import { RouteObject, useLocation, useNavigate } from "react-router-dom";
import withViewerTemplate from "hoc/withViewerTemplate";

function LegalNotice() {
  const navigate = useNavigate();
  const { state } = useLocation();

  return (
    <div className="px-[57px] py-[46px]">
      <div className="flex flex-col mb-10">
        <span className="text-primary-main-color font-semibold text-2xl mb-8">
          법적고지
        </span>
        <div className="bg-background-sub-color px-[20px] py-[10px] text-sm overflow-y-auto h-[68.5vh] rounded-[10px] border border-solid border-secondary-main-color">
          <div className="mb-6">
            <p className="mb-2">
              프롭인사이트 방문을 환영합니다. 이 서비스를 이용하기에 앞서
              이용약관 및 모든 관련법규를 주의 깊 게 읽고 검토해 주시기
              바랍니다. 이 웹사이트 서비스에 포함된 정보에 대한 접근 및 사용은
              다음의 약관 및 모든 관련법규를 준수하는 것을 전제로 합니다. 특별한
              제한이나 자격요건이 없다면, 이 사이트를 접 근하여 이용하는 것은
              귀하와 프롭인사이트의 약관 및 법규를 동의하는 것으로 봅니다.
            </p>

            <p className="mb-2">서비스 주체 : (주)띵스워크샵</p>
            <p className="mb-2">서비스명 : 프롭인사이트 (prop-insight)</p>
            <p className="mb-2">웹사이트 : www.prop-insight.com</p>
          </div>
          <div className="mb-2">
            <p>1. 서비스 이용의 승낙</p>
            <p>
              이용자는 (주)띵스워크샵이 제공하는 프롭인사이트의 서비스를
              이용함에 있어 이 이용약관을 준수해 야 합니다. 이 웹사이트를
              접속하여 서비스를 이용하는 것은 본 약관에 동의하는 것으로
              간주됩니다.
            </p>
          </div>
          <div className="mb-2">
            <p>2. 정보의 사용</p>
            <p>
              (주)띵스워크샵이 제공하는 프롭인사이트는 본 웹사이트 서비스에
              게시되어 있는 자료들을 비영리적, 개인용도로 다운로드, 출력하는
              것을 허락합니다. 비영리적인 목적으로 사용할 경우에도 본 사이트의
              저작권, 공지사항을 유지하여야 합니다. 공공 및 영리적인 목적으로
              사용할 경우에는 사전 서면 동의 없 이 텍스트, 이미지, 영상 등을
              포함한 정보를 배포, 편집, 전달, 재사용 및 게재하는 것을 금합니다.
            </p>
          </div>
          <div className="mb-2">
            <p>3. 등록상표 및 지적재산권</p>
            <p>
              모든 제품이름은 특별한 명시가 없으면 큰 사이즈로 표시되든,
              등록상표로 표시되든 상관없이 (주)띵 스워크샵의 등록상표이며, 상표,
              특허, 기술, 지적정보, 제품 등에 대한 사항은 이 사이트에 있는
              내용을 그대로 유지해야 합니다.
            </p>
          </div>
          <div className="mb-2">
            <p>4. 데이터의 보증</p>
            <p>
              (주)띵스워크샵이 제공하는 프롭인사이트는 정확하고 최신의 정보를
              제공하고자 합리적인 조치를 취 합니다. 그러나, 본 서비스에서
              제공하는 정보의 정확성, 무결점, 타당성, 적시성, 합법성에 대해
              어떠한 보증도 하지 않습니다. 본 웹사이트에 접속하여 서비스를
              이용하는 동안, 사이트 내 정보를 다운로드 진 행시, 귀하의 컴퓨터
              부품이나 저작물을 감염시킬 수 있는 어떤 손상이나 바이러스에 대한
              책임을 지지 않습니다. 본 웹사이트에는 기술적 오류가 있을 수
              있습니다.
            </p>
          </div>
          <div className="mb-2">
            <p>5. 비기밀성</p>
            <p>
              정보개인정보보호정책에 반영되는 정보 이외에 귀하가
              전자메일(contact@thingsw.com) 등으로 본 웹사이트로 전달한 데이터,
              질문, 의견, 제언 등 정보 등은 비밀유지 되지 않으며 그에 대한
              소유권을 주 장할 수 없습니다. 귀하가 전달한 사항은
              (주)띵스워크샵의 소유가 되며, 서비스 개선을 위한 근거로 사용 되며
              복제, 발표, 방송, 게시 등의 목적으로 이용될 수 있습니다.
            </p>
          </div>
          <div className="mb-2">
            <p>6. 다른 사이트와의 연계</p>
            <p>
              (주)띵스워크샵은 프롭인사이트 사이트에 링크 된 다른 사이트를
              제제할 수 없으며, 일부 또는 전부를 검토하지 않았으므로, 링크 된
              사이트의 내용에 대해 책임지지 않습니다.
            </p>
          </div>
          <div className="mb-2">
            <p>7. 의견 게재시 주의사항</p>
            <p>
              (주)띵스워크샵이 제공하는 프롭인사이트는 사이트를 통한 토론,
              게시물 등을 때때로 모니터 및 검토 하 지만, 이는 반드시 의무조항이
              아니며, 다른 사이트로부터 전달된 정보의 삭제, 오류, 음란물,
              위험성, 결 함등에 대해 책임을 지지 않습니다. 공공질서 또는
              미풍약속에 위배되는 내용의 정보, 문장, 도형, 음성 등을 유포하거나
              컴퓨터 바이러스 감염자료를 등록하거나 지적재산권을 침해하는
              위법행위를 해서는 안됩니다. (주)띵스워크샵은 위의 위해 정보나
              자료를 게시한 사람 및 단체에 대해 관계기관이 적법하고 정당하게
              수사에 필요한 서류를 열람 및 제출을 요청할 경우 적극적으로 협조할
              것입니다.
            </p>
          </div>
          <div className="mb-2">
            <p>10. 손해배상</p>
            <p>
              (주)띵스워크샵이 제공하는 프롭인사이트는 이용자가 이용약관 동의를
              위반할 경우, 이용자의 계정을 중 지 또는 말소시키는 등 적절한
              권리를 갖고 있습니다. 귀하의 위반이 타인에게 위해를 준 이용자는
              모든 손실, 손해 및 경비를 배상 할 수 있으며, 이용자는
              (주)띵스워크샵에 손해를 끼치지 않을 것에 동의합니 다.
            </p>
          </div>
          <div className="mb-2">
            <p>11. 업데이트</p>
            <p>
              (주)띵스워크샵은 언제든지 본 게시물을 업데이트하여 변경할 수
              있으며, 변경된 약관은 웹사이트에 공지 하며, 이용자는 변경된 약간을
              확인 할 수 있습니다.
            </p>
          </div>
          <div className="mb-2">
            <p>{"<부칙>"}</p>
            <p>본 고지사항은 2024년 09월 10일부터 적용한다.</p>
          </div>
        </div>
      </div>
      <Button
        type="button"
        color="primary"
        variant="contained"
        onClick={() => navigate(state?.from || "/")}
        btnStyle="px-[21px]"
      >
        <span className="text-[13px]">확인</span>
      </Button>
    </div>
  );
}

const LegalNoticeWithSidebar = withViewerTemplate(LegalNotice);

export default {
  path: "/legal-notice",
  element: <LegalNoticeWithSidebar />,
} as RouteObject;
