import React, { useState, useMemo } from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import ClickAwayListener from "./ClickAwayListener";

export interface SelectorItem {
  key: string;
  value: string;
}

interface AnalysisSelectBoxProps {
  keyValue: string;
  defaultValue: string;
  onChange?: (item: SelectorItem) => void;
  items: SelectorItem[];
  className?: string;
  textClassName?: string;
  MenuClassName?: string;
}

const AnalysisSelectBox = ({
  keyValue,
  defaultValue,
  onChange,
  className,
  textClassName,
  MenuClassName,
  items,
}: AnalysisSelectBoxProps) => {
  const [openMenu, setOpenMenu] = useState(false);

  const currentValue = useMemo(() => {
    const val =
      _.find(items, (item) => item.key === keyValue)?.value ?? defaultValue;
    return val;
  }, [defaultValue, items, keyValue]);

  return (
    <div>
      <div
        onClick={(event) => {
          setOpenMenu((o) => !o);
          event.stopPropagation();
        }}
        className={`flex items-center justify-between rounded w-[256px] border-[1.5px] border-secondary-main-color ${
          currentValue === defaultValue ? "py-[9px]" : "py-[7px]"
        } pr-[18px] pl-[11px] mr-[4px] cursor-pointer bg-white ${className}`}
      >
        {currentValue === defaultValue ? (
          <span
            className={`text-p3 text-secondary-main-color mt-[2px] ${textClassName}`}
          >
            {currentValue}
          </span>
        ) : (
          <div className="flex items-center">
            <FontAwesomeIcon
              icon={faStar}
              className="text-primary-main-color mr-[3px]"
            />
            <span
              className={`text-text-darkgray text-p2 mt-[2px] ${textClassName}`}
            >
              {currentValue}
            </span>
          </div>
        )}

        {openMenu ? (
          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 5.10449L5 0.104492L10 5.10449H0Z" fill="#A5ABB3" />
          </svg>
        ) : (
          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 0.604492L5 5.60449L10 0.604492H0Z" fill="#A5ABB3" />
          </svg>
        )}
      </div>
      <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
        {openMenu && (
          <ul
            className={`absolute bg-white py-2 rounded mt-[2px] ${MenuClassName}`}
            style={{ boxShadow: "2px 2px 4px 0px #00000033" }}
          >
            {_.map(items, (item, index) => (
              <React.Fragment key={index}>
                <li
                  className={`px-[9px] py-[7px] cursor-pointer hover:bg-background-main-color`}
                  onClick={() => {
                    setOpenMenu(false);
                    onChange?.(item);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faStar}
                    className="text-primary-main-color mr-[3px]"
                  />
                  <span className="text-text-darkgray text-p1">
                    {item.value}
                  </span>
                </li>
                {index !== items.length - 1 && (
                  <hr className="w-[256px] border-background-sub-color border-[thin]" />
                )}
              </React.Fragment>
            ))}
          </ul>
        )}
      </ClickAwayListener>
    </div>
  );
};

export default AnalysisSelectBox;
