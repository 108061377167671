import axiosInst from "utils/axiosInst";

export const getBuildingInfo = async (pnu: string) => {
  return (
    await axiosInst.get("/building-info", {
      params: {
        pnu,
      },
    })
  ).data;
};
