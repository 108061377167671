import { UsersOnLands } from "@prop-insight/database";
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { LandZoneInfo } from "types/types";
import axiosInst from "utils/axiosInst";

interface LandInfoWindowProps {
  land: LandZoneInfo;
}

export const LandInfoWindow = ({ land }: LandInfoWindowProps) => {
  const [favorite, setFavorite] = useState<UsersOnLands>();
  const [isLogin, setIsLogin] = useState(false);

  let doroNameList: string[] = [];

  if (typeof land.doroNameList === "string") {
    doroNameList = JSON.parse(land.doroNameList)[0] as string[];
  } else {
    doroNameList = land.doroNameList;
  }

  useEffect(() => {
    if (
      window.localStorage.getItem("token") !== null &&
      window.localStorage.getItem("token") !== "null"
    ) {
      setIsLogin(true);
    }
  }, []);

  useEffect(() => {
    const getFavorite = async () => {
      try {
        const resp = await axiosInst.get(`/lands/${land.id}/favorite`);
        setFavorite(resp.data);
        // setIsFavorite(true);
      } catch (e: any) {
        if (e.response?.status === 404) {
          setFavorite(undefined);
        } else if (e.response?.status === 401) {
          setFavorite(undefined);
        }
      }
    };
    if (isLogin) {
      getFavorite();
    }
  }, [isLogin, land.id]);

  const onFavorite = useCallback(async () => {
    if (!isLogin) {
      window.location.href = "/login";
    }

    if (favorite) {
      await axiosInst.delete(`/users/favorite-lands/${favorite.id}`);
      setFavorite(undefined);
    } else {
      const resp = await axiosInst.post(`/lands/${land.id}/favorite`);
      setFavorite(resp.data);
    }
  }, [favorite, isLogin, land]);

  return (
    <div className="bg-white w-full h-full flex items-start gap-[12px] rounded-[10px] shadow-[2px_2px_4px_rgba(0,0,0,0.2)] p-[12px]">
      <div className="w-full">
        {doroNameList && doroNameList.length > 0 ? (
          doroNameList.map((doroName) => (
            <div key={doroName} className="flex mb-[5px] h-[18px] gap-[12px]">
              <div className="px-[16px] py-[2px]  border border-primary-main-color flex items-center justify-center rounded-[5px]">
                <span className="text-primary-main-color font-semibold text-[13px] mt-[2px] tracking-tighter">
                  도로명주소
                </span>
              </div>
              <div className="flex h-[18px]">
                <p className="!text-p3 font-normal whitespace-nowrap tracking-tighter">
                  {doroName}
                </p>
              </div>
            </div>
          ))
        ) : (
          <div></div>
        )}
        <div className="flex mb-[5px] h-[18px]">
          <div className="px-[14px] py-[2px] mr-[11px] border border-primary-main-color flex items-center justify-center rounded-[5px]">
            <span className="text-primary-main-color font-semibold text-[13px] mt-[1px] tracking-tighter">
              지번주소
            </span>
          </div>
          <div className="flex h-[18px]">
            <p className="!text-p3 font-normal whitespace-nowrap tracking-tighter">
              {land.jibunName}
            </p>
          </div>
        </div>
        <div className="flex mb-[5px] h-[18px]">
          <div className="px-[14px] py-[2px] mr-[11px] border border-primary-main-color flex items-center justify-center rounded-[5px] bg-primary-main-color">
            <span className="text-white font-semibold text-[13px] mt-[2px] tracking-tighter">
              토지면적
            </span>
          </div>
          <div className="flex h-[18px]">
            <p className="!text-p3 font-normal whitespace-nowrap tracking-tighter">
              {land.area.toFixed(2)}m²
            </p>
          </div>
        </div>
        <div className="flex mb-[5px] h-[18px]">
          <div className="px-[14px] py-[2px] mr-[11px] border border-primary-main-color flex items-center justify-center rounded-[5px] bg-primary-main-color">
            <span className="text-white font-semibold text-[13px] mt-[2px] tracking-tighter">
              용도지역
            </span>
          </div>
          <div className="flex h-[18px]">
            {/* 원형 추가 */}
            <div
              className={clsx("flex items-center justify-center mr-[1px]", {
                "text-pink-200": land?.uname?.includes("일반상업"),
                "text-amber-300": land?.uname?.includes("전용주거"),
                "text-slate-400": land?.uname?.includes("공업"),
                "text-yellow-200": land?.uname?.includes("일반주거"),
                "text-lime-400": land?.uname?.includes("녹지"),
              })}
            >
              <span className="flex items-center justify-center text-[23px] -mt-[2px]">
                &#9679;
              </span>
            </div>

            <p className="!text-p3 font-normal whitespace-nowrap tracking-tighter">
              {land?.uname
                ?.replace("제1종", "")
                .replace("제2종", "")
                .replace("제3종", "")}
            </p>
          </div>
        </div>
      </div>
      <button onClick={onFavorite}>
        {isLogin ? (
          favorite ? (
            <svg
              width="20"
              height="17"
              viewBox="0 0 20 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.3377 14.0198L14.9502 16.5298C15.7949 16.9898 16.8285 16.3098 16.6062 15.4498L15.3836 10.7298L19.4626 7.54977C20.2073 6.96977 19.8072 5.86977 18.8291 5.79977L13.4608 5.38977L11.3602 0.929766C10.9823 0.119766 9.69306 0.119766 9.31517 0.929766L7.21455 5.37977L1.84629 5.78977C0.868224 5.85977 0.468106 6.95977 1.21277 7.53977L5.29175 10.7198L4.06917 15.4398C3.84688 16.2998 4.88052 16.9798 5.72522 16.5198L10.3377 14.0198V14.0198Z"
                fill="#16B5B8"
              />
            </svg>
          ) : (
            <svg
              width="20"
              height="17"
              viewBox="0 0 20 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.8402 5.54L13.4608 5.12L11.3602 0.67C10.9823 -0.14 9.69306 -0.14 9.31517 0.67L7.21455 5.13L1.84629 5.54C0.868224 5.61 0.468106 6.71 1.21277 7.29L5.29175 10.47L4.06917 15.19C3.84688 16.05 4.88052 16.73 5.72522 16.27L10.3377 13.77L14.9502 16.28C15.7949 16.74 16.8285 16.06 16.6062 15.2L15.3836 10.47L19.4626 7.29C20.2073 6.71 19.8183 5.61 18.8402 5.54ZM10.3377 11.9L6.15868 14.17L7.27012 9.89L3.58014 7.01L8.44824 6.63L10.3377 2.6L12.2383 6.64L17.1064 7.02L13.4164 9.9L14.5278 14.18L10.3377 11.9Z"
                fill="#A5ABB3"
              />
            </svg>
          )
        ) : (
          <></>
        )}
      </button>
    </div>
  );
};
