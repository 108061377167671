import React, { useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import SmallButton from "./SmallButton";
import { Button } from "@prop-insight/components";
import { NewlandBlurImgBase64 } from "./NewlandBlurImg";
import moment from "moment";
import { LandSiteDetail } from "types/types";
import axiosInst from "utils/axiosInst";
import JSZip from "jszip";
import { formatPriceWithCommas } from "utils/formatPriceWithCommas";

interface NewLandDetailPanelProps {
  user?: boolean;
  landSite: LandSiteDetail;
}
const NewLandDetailPanel = ({ user, landSite }: NewLandDetailPanelProps) => {
  // 평단위로 변경
  const [calcArea, setCalcArea] = useState<boolean>(false);

  // console.log("landSiteDetail", landSite);
  return (
    <>
      <div className="p-[20px]">
        <div className="flex mt-[60px]">
          <h1 className="text-title2 mr-[11px]">상세 내용</h1>
          <div className="flex justify-center items-center">
            <SmallButton
              text={calcArea ? "m²" : "평"}
              onClick={() => {
                setCalcArea((o) => !o);
              }}
            />
          </div>
        </div>
        <div className="mt-[21px]">
          <table>
            <thead>
              <tr className="flex flex-row text-text-darkgray h-[40px] text-center border-b-[1px]">
                <th className="flex justify-center items-center !text-p5 !font-normal whitespace-nowrap w-[23px] h-[40px]">
                  블록명
                </th>
                <th className="flex justify-center items-center !text-p5 !font-normal w-[30px]">
                  도면
                  <br />
                  번호
                </th>
                <th className="flex justify-center items-center !text-p5 !font-normal whitespace-nowrap w-[42px]">
                  면적
                </th>
                <th className="flex justify-center items-center !text-p5 !font-normal whitespace-nowrap w-[62px]">
                  공급가격
                </th>
                <th className="flex justify-center items-center !text-p5 !font-normal whitespace-nowrap w-[26px]">
                  건폐율
                </th>
                <th className="flex justify-center items-center !text-p5 !font-normal whitespace-nowrap w-[26px]">
                  용적률
                </th>
                <th className="flex justify-center items-center !text-p5 !font-normal w-[25px]">
                  고층
                  <br />
                  제한
                </th>
                <th className="flex justify-center items-center !text-p5 !font-normal whitespace-nowrap w-[26px]">
                  가구수
                </th>
                <th className="flex justify-center items-center !text-p5 !font-normal whitespace-nowrap w-[57px]">
                  낙찰가
                </th>
                <th className="flex justify-center items-center !text-p5 !font-normal whitespace-nowrap w-[33px]">
                  경쟁률
                </th>
                <th className="flex justify-center items-center !text-p5 !font-normal whitespace-nowrap w-[47px]">
                  용도지역
                </th>
              </tr>
            </thead>
            <tbody>
              {landSite?.landSiteBlocks &&
                landSite.landSiteBlocks.map((item) => (
                  <tr
                    className="flex flex-row text-text-darkgray text-p4 h-[40px] text-center"
                    key={item.id}
                  >
                    <td className="flex justify-center items-center w-[23px]">
                      {item.name}
                    </td>
                    <td className="flex justify-center items-center w-[30px]">
                      {item.blueprintNumber}
                    </td>
                    <td className="flex justify-center items-center w-[42px]">
                      {calcArea
                        ? `${(item.area / 3.3025).toFixed(2)}`
                        : `${item.area.toFixed(2)}`}
                    </td>
                    <td className="flex justify-center items-center w-[62px]">
                      {formatPriceWithCommas(item.price)}
                    </td>
                    <td className="flex justify-center items-center w-[26px]">
                      {`${item.siteCoverage}% 이하`}
                    </td>
                    <td className="flex justify-center items-center w-[26px]">
                      {`${item.floorAreaRatio}% 이하`}
                    </td>
                    <td className="flex justify-center items-center w-[25px]">
                      {item.hasHeightLimitation ? "O" : "-"}
                    </td>
                    <td className="flex justify-center items-center w-[26px]">
                      {item.households !== null && item.households !== 0
                        ? `${item.households}가구 이하`
                        : "-"}
                    </td>
                    {user ? (
                      <>
                        <td className="flex justify-center items-center w-[44px] ">
                          {item.winningBid ? item.winningBid.toString() : "-"}
                        </td>
                        {/* 경쟁률 / 아직 어드민에서 입력란이 없어서 일단 -로 출력 */}
                        <td className="flex justify-center items-center w-[44px] ">
                          {item.competitionRate ?? "-"}
                        </td>
                      </>
                    ) : (
                      <td
                        colSpan={2}
                        className="flex justify-center items-center w-[89px]"
                      >
                        <div
                          data-tooltip-id="winningPrice-tooltip"
                          data-tooltip-content="낙찰가와 경쟁률 정보는 로그인 시 확인 가능합니다."
                        >
                          <img src={NewlandBlurImgBase64} alt="blur" />
                        </div>

                        <ReactTooltip
                          id="winningPrice-tooltip"
                          place="right"
                          style={{
                            position: "fixed",
                            right: 0,
                            backgroundColor: "#16B5B8",
                            zIndex: 999,
                            padding: "1px 8px",
                            borderRadius: 4,
                          }}
                        />
                      </td>
                    )}
                    <td className="flex justify-center items-center w-[44px] ">
                      {item.zoningArea}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div className="p-[20px] bg-background-sub-color mt-[16px] rounded-[10px]">
          <div className="flex">
            <div className="flex flex-col items-center content-center">
              <Button
                type="button"
                color="darkgray"
                variant="outlined_transparent"
                btnStyle="w-[66px] !h-[18px] mr-[7px] mb-[10px] text-h3 flex items-center content-center justify-center"
              >
                용도지역
              </Button>
              {landSite.applicationStartDate && (
                <Button
                  type="button"
                  color="darkgray"
                  variant="outlined_transparent"
                  btnStyle="w-[66px] !h-[18px] mr-[7px] mb-[10px] text-h3 flex items-center content-center justify-center"
                >
                  신청접수
                </Button>
              )}
              {landSite.lotteryDate && (
                <Button
                  type="button"
                  color="darkgray"
                  variant="outlined_transparent"
                  btnStyle="w-[66px] !h-[18px] mr-[7px] mb-[10px] text-h3 flex items-center content-center justify-center"
                >
                  필지추첨
                </Button>
              )}
              {landSite.resultPostedDate && (
                <Button
                  type="button"
                  color="darkgray"
                  variant="outlined_transparent"
                  btnStyle="w-[66px] !h-[18px] mr-[7px] mb-[10px] text-h3 flex items-center content-center justify-center"
                >
                  결과게시
                </Button>
              )}
              {landSite.contractStartDate && (
                <Button
                  type="button"
                  color="darkgray"
                  variant="outlined_transparent"
                  btnStyle="w-[66px] !h-[18px] mr-[7px] mb-[10px] text-h3 flex items-center content-center justify-center"
                >
                  계약체결
                </Button>
              )}
            </div>

            <div>
              <div className="!text-h3 font-semibold mb-[10px] h-[18px] leading-[18px] text-text-darkgray">
                {landSite.purpose}
              </div>
              {landSite.applicationStartDate && (
                <div className="!text-h3 font-semibold mb-[10px] h-[18px] leading-[18px] text-text-darkgray">
                  {moment(landSite.applicationStartDate).format(
                    "YY.MM.DD (dd)"
                  )}{" "}
                  -{moment(landSite.applicationEndDate).format("YY.MM.DD (dd)")}{" "}
                  {moment(landSite.applicationStartDate).format("HH:mm")} ~
                  {moment(landSite.applicationEndDate).format("HH:mm")}
                </div>
              )}
              {landSite.lotteryDate && (
                <div className="!text-h3 font-semibold mb-[10px] h-[18px] leading-[18px] text-text-darkgray">
                  {moment(landSite.lotteryDate).format("YY.MM.DD (dd) HH:mm")}
                </div>
              )}
              {landSite.resultPostedDate && (
                <div className="!text-h3 font-semibold mb-[10px] h-[18px] leading-[18px] text-text-darkgray">
                  {moment(landSite.resultPostedDate).format(
                    "YY.MM.DD (dd) HH:mm"
                  )}
                </div>
              )}
              {landSite.contractStartDate && landSite.contractEndDate && (
                <div className="!text-h3 font-semibold mb-[10px] h-[18px] leading-[18px] text-text-darkgray">
                  {`${moment(landSite.contractStartDate).format(
                    "YY.MM.DD (dd)"
                  )} - ${moment(landSite.contractEndDate).format(
                    "YY.MM.DD (dd)"
                  )} ${moment(landSite.contractStartDate).format(
                    "HH:mm"
                  )} ~ ${moment(landSite.contractEndDate).format(
                    "HH:mm"
                  )}`}{" "}
                  <br />[ ※12:00 ~ 13:00 제외 ]
                </div>
              )}
              {landSite.contractStartDate && !landSite.contractEndDate && (
                <div className="!text-h3 font-semibold mb-[10px] h-[18px] leading-[18px] text-text-darkgray">
                  {`${moment(landSite.contractStartDate).format(
                    "YY.MM.DD (dd)"
                  )} ${moment(landSite.contractStartDate).format(
                    "HH:mm"
                  )} ~ 체결 시까지`}{" "}
                  <br />[ ※12:00 ~ 13:00 제외 ]
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex mt-[16px]">
          <Button
            type="button"
            color="primary"
            variant="outlined"
            btnStyle=" w-[191px] h-[27px]"
            boxShadow
            withRightIcon="fileIcon"
            disabled={landSite.files?.length === 0}
            onClick={async () => {
              if (landSite.files && landSite.files?.length !== 0) {
                const files = [];

                for (const file of landSite.files) {
                  const resp = await axiosInst.get(`/files/${file.id}`);
                  const newFile = {
                    filename: resp.data.filename,
                    mimeType: resp.data.mimeType,
                    url: resp.data.url,
                  };
                  files.push(newFile);
                }

                // JSZip 인스턴스 생성
                const zip = new JSZip();

                // 파일 다운로드 및 zip에 추가
                const promises = files.map(async (fileInfo, index) => {
                  const { filename, url } = fileInfo;
                  const fileName = `${filename}`;

                  try {
                    const response = await fetch(url);

                    // blob 타입으로 다운로드
                    const blob = await response.blob();
                    zip.file(fileName, blob, { binary: true }); // binary 옵션을 설정하여 이진 파일로 처리
                  } catch (error) {
                    console.error(`파일 다운로드 오류: ${error}`);
                  }
                });

                // 모든 파일 다운로드 및 압축 후 zip 파일 다운로드
                await Promise.all(promises);
                zip.generateAsync({ type: "blob" }).then((content) => {
                  const a = document.createElement("a");
                  const url = window.URL.createObjectURL(content);
                  a.href = url;
                  a.download = "Prop-Insight.zip";
                  document.body.appendChild(a);
                  a.click();
                  window.URL.revokeObjectURL(url);
                });
              }
            }}
          >
            <span>첨부파일 다운로드</span>
          </Button>

          <Button
            type="button"
            color="primary"
            variant="outlined"
            btnStyle=" w-[191px] h-[27px] ml-[6px] text-h4"
            boxShadow
            withRightIcon="arrowIcon"
            onClick={() => {
              const newWindow = window.open(`${landSite.link}`, "_blank");
              // 보안 설정 (rel="noopener noreferrer")
              if (newWindow) {
                newWindow.opener = null;
              }
            }}
          >
            <span>원본게시글로 이동</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default NewLandDetailPanel;
