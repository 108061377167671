import axios from "axios";
import { API_SERVER_URI } from "./constants";

const axiosInst = axios.create({
  baseURL: API_SERVER_URI,
});

axiosInst.interceptors.request.use((config) => {
  const token = window.localStorage.getItem("token");
  if (token && token !== "null") {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, null);

export default axiosInst;
