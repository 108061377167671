import { Land, Location } from "types/types";
import axiosInst from "utils/axiosInst";

export const getPoi = async (
  searchKey: string
): Promise<{
  place: Location[];
  subway: Location[];
  jijuk: Land[];
}> => {
  return (await axiosInst.get(`/poi?search=${searchKey}`)).data;
};
