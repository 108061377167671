import { MAIN_LOT_LAYER_NAME, MAIN_LOT_SOURCE_NAME } from "contans/Layout";
import { useMap } from "providers/MapProvider";
import axiosInst from "utils/axiosInst";

export const useMoveMap = () => {
  const { setMapState, map } = useMap();

  const moveMap = ({
    jijukIds,
    lat,
    lng,
  }: {
    jijukIds: number[];
    lat: number;
    lng: number;
  }) => {
    // Remove previous layers
    map.current.getStyle().layers.forEach((key: any) => {
      if (key.id.includes(MAIN_LOT_LAYER_NAME)) {
        map.current.removeLayer(key.id);
      }
    });

    // Remove previous sources
    Object.keys(map.current.getStyle().sources).forEach((key) => {
      if (key.includes(MAIN_LOT_SOURCE_NAME)) {
        map.current.removeSource(key);
      }
    });

    const coordinates = jijukIds.map(async (jijukId) => {
      const res = await axiosInst.get(`/jijuk/${jijukId}`);

      const jibunName = res.data.jijuk[0].jibunName;
      const newCoordinates = JSON.parse(res.data.jijuk[0].geometry).coordinates;

      return {
        id: jibunName,
        coordinates: newCoordinates,
      };
    });

    Promise.all(coordinates).then((coordinates) => {
      coordinates.forEach(({ id, coordinates }: any) => {
        const SOURCE_NAME = MAIN_LOT_SOURCE_NAME + "-" + id;
        const LAYER_NAME = MAIN_LOT_LAYER_NAME + "-" + id;

        if (map.current.getSource(SOURCE_NAME)) {
          map.current.getSource(SOURCE_NAME).setData({
            type: "Feature",
            geometry: {
              type: "Polygon",
              // These coordinates outline Maine.
              coordinates,
            },
          });
        } else {
          // Add a data source containing GeoJSON data.
          map.current.addSource(SOURCE_NAME, {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: {
                type: "Polygon",
                // These coordinates outline Maine.
                coordinates,
              },
            },
          });

          // Add a new layer to visualize the polygon.
          map.current.addLayer({
            id: LAYER_NAME,
            type: "fill",
            source: SOURCE_NAME, // reference the data source
            layout: {},
            paint: {
              "fill-color": "#0080ff", // blue color fill
              "fill-opacity": 0.5,
            },
          });
        }
      });
    });

    setMapState({
      lat,
      lng,
    });
  };

  return { moveMap };
};
