import { faLocationPin } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

import { Autoplay } from "swiper/modules";
import { useMap } from "providers/MapProvider";
import useQuery from "hooks/useQuery";
import { getFavorite } from "utils/api/favoriteApi";
import { LoadingSpinner } from "@prop-insight/components";
import { FavoriteLand } from "types/types";

const FavoriteSlider = () => {
  const { handleClickedLand } = useMap();
  const { data: favoriteLand, isLoading } =
    useQuery<FavoriteLand[]>(getFavorite);

  if (isLoading)
    return (
      <div className="w-[270px] flex justify-center items-center">
        <LoadingSpinner color="primary" />
      </div>
    );

  return (
    <Swiper
      direction={"vertical"}
      pagination={{
        clickable: true,
      }}
      loop={true}
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
      }}
      modules={[Autoplay]}
      className="h-[30px] w-[270px]"
    >
      {favoriteLand?.map((land, index) => {
        const getJibunName = () => {
          const jibunNameList = land.jibunName.split(" ");

          jibunNameList.shift();

          return jibunNameList.join(" ");
        };
        return (
          <SwiperSlide key={land.id}>
            <div
              onClick={() => handleClickedLand(land)}
              role="button"
              className="flex justify-between items-center cursor-pointer"
            >
              <div className="flex items-center gap-[5px]">
                <div className="relative flex justify-center items-center mr-[5px]">
                  <FontAwesomeIcon
                    size="2x"
                    icon={faLocationPin}
                    className="text-primary-sub-color w-[19px]"
                  />
                  <span className=" absolute font-semibold text-white text-[12px] mb-[4px]">
                    {index + 1}
                  </span>
                </div>

                <span className="text-[13px] mt-[1px] mr-[24px] text-text-darkgray">
                  {getJibunName()}
                </span>
              </div>

              <div className="flex items-center">
                <span className="text-[13px] mt-[1px] text-text-darkgray">
                  {land.count}명 보는 중
                </span>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default FavoriteSlider;
