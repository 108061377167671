import { useCallback, useState } from "react";

function isJSON(str: string): boolean {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const useLocalStorage = <T>(
  keyName: string,
  defaultValue: T
): [T, (value: T) => void] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(keyName);
      if (item) {
        return isJSON(item) ? (JSON.parse(item) as T) : (item as unknown as T);
      } else {
        const valueToStore =
          typeof defaultValue === "string"
            ? (defaultValue as string)
            : JSON.stringify(defaultValue);
        window.localStorage.setItem(keyName, valueToStore);
        return defaultValue;
      }
    } catch (error) {
      console.log(error);
      return defaultValue;
    }
  });

  const setValue = useCallback(
    (value: T): void => {
      try {
        const valueToStore =
          typeof value === "string" ? (value as string) : JSON.stringify(value);
        window.localStorage.setItem(keyName, valueToStore);
        setStoredValue(value);
      } catch (error) {
        console.log(error);
      }
    },
    [keyName]
  );

  return [storedValue, setValue];
};

export default useLocalStorage;
