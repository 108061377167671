import { useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BookMarkTable from "./BookMarkTable";
import { faArrowRotateRight } from "@fortawesome/free-solid-svg-icons";
import Pagination from "./Pagination";
import { BookMarkItem } from "types/types";
import { BookmarkDelete } from "./BookmarkDelete";
import { LoadingSpinner } from "@prop-insight/components";
import { errorToast } from "utils/toast";
import useQuery from "hooks/useQuery";
import {
  deleteFavorite,
  getMyFavorite,
  updateFavoriteMemo,
} from "utils/api/favoriteApi";
import { calcTotalPages } from "utils/calcMaxPage";

export interface BookmarkPanelProps {
  openRealtyPolicyDetail: (realtyPolicy: BookMarkItem) => void;
  userToken?: boolean;
}

export default function BookmarkPanel({
  openRealtyPolicyDetail,
  userToken,
}: BookmarkPanelProps) {
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectBookMarkID, setSelectBookMarkID] = useState<number>();

  const [currentPage, setCurrentPage] = useState(1);
  // 평단위로 변경
  const [calcArea, setCalcArea] = useState<boolean>(false);

  const [isInvalidBookmark, setIsInvalidBookmark] = useState<boolean>(false);

  const getBookmarks = useCallback(async () => {
    if (userToken) {
      setIsInvalidBookmark(true);
      return await getMyFavorite(currentPage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, isInvalidBookmark, userToken]);

  const { data: bookMarkList, isLoading } = useQuery<{
    lands: BookMarkItem[];
    count: number;
  }>(getBookmarks);

  const totalPages = calcTotalPages(bookMarkList?.count);

  // 즐겨찾기 삭제
  const onDeleteFavorite = useCallback(
    async (id: number) => {
      if (bookMarkList) {
        try {
          await deleteFavorite(id);
          setOpenDeleteModal(false);
          setIsInvalidBookmark(false);
        } catch (error) {
          console.error("API 호출 오류:", error);
          errorToast();
        }
      }
    },
    [bookMarkList]
  );

  // 즐겨찾기 메모 저장 / 삭제
  const saveMemo = useCallback(async (bookmarkId: number, memo: string) => {
    // 메모 삭제하는 경우 memo를 빈 string으로 저장
    try {
      await updateFavoriteMemo(bookmarkId, memo);
      setIsInvalidBookmark(false);
    } catch (error) {
      console.error("API 호출 오류:", error);
      errorToast();
    }
  }, []);

  return (
    <div className="h-full mx-[20px]">
      <div className="mb-[24px] flex">
        <span className="text-text-darkgray text-title2 mr-[11px]">
          즐겨찾기 등록한 장소
        </span>

        <button
          className="flex items-center bg-secondary-main-color py-[2px] px-[5px] rounded h-[19px] mt-[2px]"
          onClick={() => {
            setCalcArea((o) => !o);
          }}
        >
          <FontAwesomeIcon
            size="2x"
            icon={faArrowRotateRight}
            className="text-text-darkgray mr-[2px] h-[12px]"
          />
          <span
            className="text-text-darkgray text-[10px]"
            style={{ transform: "scale(0.9)" }}
          >
            {calcArea ? "m²" : "평"}
          </span>
        </button>
      </div>

      {/* 즐겨찾기 테이블 */}
      {isLoading ? (
        <div className="flex items-center justify-center mt-[325px]">
          <LoadingSpinner panel />
        </div>
      ) : (
        <BookMarkTable
          calcArea={calcArea}
          userToken={userToken}
          bookMarkList={bookMarkList ? bookMarkList.lands : []}
          onClickDetailPanel={(bookMark) => openRealtyPolicyDetail(bookMark)}
          onSaveMemo={(id, memo) => {
            saveMemo(id, memo);
          }}
          onDeleteFavoriteModal={(id) => {
            setOpenDeleteModal(true);
            setSelectBookMarkID(id);
          }}
        />
      )}

      {/* 즐겨찾기 해제시 팝업 */}
      {openDeleteModal && (
        <BookmarkDelete
          onCancelDeleteBookmark={() => {
            setOpenDeleteModal(false);
            setSelectBookMarkID(undefined);
          }}
          onDeleteBookmark={() => {
            selectBookMarkID && onDeleteFavorite(selectBookMarkID);
          }}
        />
      )}

      {/* pagination */}
      {userToken && (
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onChangePage={(page) => {
            setCurrentPage(page);
          }}
        />
      )}
    </div>
  );
}
