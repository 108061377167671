import { Button } from "@prop-insight/components";
import { RouteObject, useNavigate } from "react-router-dom";
import withViewerTemplate from "hoc/withViewerTemplate";

function EmailConfirmSuccess() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex flex-col items-center py-[220px] h-screen">
        {/* <div className="flex flex-col max-w-[390px] w-full h-screen mt-[220px]"> */}
        <div className="flex flex-col items-center">
          <span className="text-primary-main-color font-semibold text-2xl mb-[6px]">
            이메일 인증 성공
          </span>
          <span className="text-primary-main-color text-[13px]">
            이메일 인증에 성공하였습니다.
          </span>
          <span className="text-primary-main-color text-[13px] mb-[47px]">
            로그인 페이지로 이동해서 로그인해주세요.
          </span>

          <Button
            type="button"
            color="primary"
            variant="contained"
            onClick={() => navigate("/login", { replace: true })}
            btnStyle="px-[17px]"
            boxShadow
          >
            <span className="text-[13px]">로그인 페이지로 이동</span>
          </Button>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}

const EmailConfirmSuccessWithSidebar = withViewerTemplate(EmailConfirmSuccess);

export default {
  path: "/email-confirm-success",
  element: <EmailConfirmSuccessWithSidebar />,
} as RouteObject;
