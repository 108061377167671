export type MobileContentType = {
  index: number;
  title: string;
  image: string;
  descriptions: string[];
  url?: string;
  type?: string;
};

export const mobileContents: MobileContentType[] = [
  // {
  //   index: 0,
  //   image: "",
  //   title: "안녕하세요.\n PROP - INSIGHT입니다.",
  //   url: "youtube.com",
  //   descriptions: [
  //     "프롭 인사이트는 데스크탑 환경에서 서비스가 제공됩니다.",
  //     "모바일 환경에서는 프롭인사이트의 서비스 소개를 제공합니다.",
  //   ],
  //   type: "video",
  // },
  {
    index: 1,
    title: "PROP-INSIGHT는 \n 전국 공공택지 분양 최신 정보를 제공합니다.",
    image: "/images/mobile/mobile_image_1.png",
    descriptions: [
      "매주 업데이트되는 공공택지 분양 소식",
      "토지이용계획도를 지도에 맵핑하여 정확한 위치 확인",
      "vr 및 로드뷰로 대상지 위치정보 제공",
    ],
  },
  {
    index: 2,
    title:
      "PROP-INSIGHT는 \n 일반 매매 토지 물건을 지도에서 확인 할 수 있습니다.",
    image: "/images/mobile/mobile_image_2.png",
    descriptions: [
      "일반 매각물건 접수하여 업로드",
      "vr 및 로드뷰로 대상지 위치정보 제공",
    ],
  },
  {
    index: 3,
    title:
      "PROP-INSIGHT는 \n 즐겨찾기를 통해 관심 물건 관리와 추적이 가능합니다.",
    image: "/images/mobile/mobile_image_3.png",
    descriptions: [
      "지도에서 관심 물건을 선택 할 수 있으며 해당 토지의 상세한 내용 확인 가능",
      "즐겨찾기 물건의 메모기능이 있으며 과거 검토 이력 등을 기록할 수 있음",
    ],
  },
  {
    index: 4,
    title: "PROP-INSIGHT는 \n 검토중인 관심물건을 비교하여 볼 수 있습니다.",
    image: "/images/mobile/mobile_image_4.png",
    descriptions: [
      "관심 물건의 입지적 특징을 비교 분석 할 수 있음",
      "최대3의 필지를 추가하여 비교 분석 할 수 있음",
    ],
  },
];
