import { ResetPasswordForm } from "@prop-insight/components";
import { RouteObject, useNavigate } from "react-router-dom";
import withViewerTemplate from "hoc/withViewerTemplate";
import axiosInst from "utils/axiosInst";

function ResetPassword() {
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex flex-col items-center">
        <div className="flex flex-col max-w-[390px] w-full mt-[217px]">
          <div className="flex flex-col items-center">
            <span className="text-primary-main-color font-semibold text-2xl mb-[6px]">
              비밀번호 찾기
            </span>
            <span className="text-primary-main-color text-[13px] mb-[47px]">
              가입된 이메일을 입력하세요.
            </span>
          </div>

          <ResetPasswordForm
            reset={async (username) => {
              return axiosInst.post("/users/find-password", {
                username,
              });
            }}
            onSuccess={(username) => {
              navigate("/change-password", {
                replace: true,
                state: { username },
              });
            }}
          />
        </div>
      </div>
    </div>
  );
}

const ResetPasswordWithSidebar = withViewerTemplate(ResetPassword);

export default {
  path: "/reset-password",
  element: <ResetPasswordWithSidebar />,
} as RouteObject;
