import React from "react";
import { Button } from "@prop-insight/components";
// import moment from "moment";
import "moment/locale/ko"; // 한국어 로케일을 불러옵니다.
import { RealtyPolicyDetail } from "types/types";
import axiosInst from "utils/axiosInst";
import JSZip from "jszip";

interface RealtyPolicyDetailPanelProps {
  realtyPolicy: RealtyPolicyDetail;
}

const RealtyPolicyDetailPanel = ({
  realtyPolicy,
}: RealtyPolicyDetailPanelProps) => {
  return (
    <>
      <div className="p-[20px]">
        <h1 className="text-title2 mr-[11px] mb-[7px] text-text-darkgray mt-[50px]">
          상세 정보
        </h1>
        <div className="bg-background-sub-color rounded-[10px] p-[15px]">
          <>
            <div className="flex mb-[8px]">
              <div className="w-[70px] !h-[20px] !text-h3 mr-[5px] border border-text-darkgray flex items-center justify-center rounded-[5px]">
                <span className="text-text-darkgray font-semibold text-[14px]">
                  대표 내용
                </span>
              </div>
              <span className="!text-h3 font-semibold w-[290px] text-text-darkgray">
                {realtyPolicy.title}
              </span>
            </div>
            <div className="flex mb-[8px]">
              <div className="w-[70px] !h-[20px] !text-h3 mr-[5px] border border-text-darkgray flex items-center justify-center rounded-[5px]">
                <span className="text-text-darkgray font-semibold text-[14px]">
                  보도 일시
                </span>
              </div>
              <span className="!text-p3 font-normal w-[290px] flex items-center text-text-darkgray">
                2023.01.03.화 별도 공지
                {/* {moment(realtyPolicy.createdAt)
                  .locale("ko")
                  .format("YYYY.MM.DD.ddd 별도 공지")} */}
              </span>
            </div>
            <div className="flex mb-[8px]">
              <div className="w-[70px] !h-[20px] !text-h3 mr-[5px] border border-text-darkgray flex items-center justify-center rounded-[5px]">
                <span className="text-text-darkgray font-semibold text-[14px]">
                  배포 일시
                </span>
              </div>
              <span className="!text-p3 font-normal w-[290px] flex items-center text-text-darkgray">
                2023.01.03.화 14:00
                {/* {moment(realtyPolicy.createdAt)
                  .locale("ko")
                  .format("YYYY.MM.DD.ddd HH:mm")} */}
              </span>
            </div>
            <div className="flex mb-[8px]">
              <div className="w-[70px] !h-[20px] !text-h3 mr-[5px] border border-text-darkgray flex items-center justify-center rounded-[5px]">
                <span className="text-text-darkgray font-semibold text-[14px]">
                  담당 부서
                </span>
              </div>
              <span className="!text-p3 font-normal w-[290px] flex items-center text-text-darkgray">
                {realtyPolicy.department}
              </span>
            </div>
            <div className="flex mb-[8px]">
              <div className="w-[70px] !h-[20px] !text-h3 mr-[5px] border border-text-darkgray flex items-center justify-center rounded-[5px]">
                <span className="text-text-darkgray font-semibold text-[14px]">
                  책임자
                </span>
              </div>
              <span className="!text-p3 font-normal w-[290px] flex items-center text-text-darkgray">
                {realtyPolicy.principal}
              </span>
            </div>
            <div className="flex mb-[8px]">
              <div className="w-[70px] !h-[20px] !text-h3 mr-[5px] border border-text-darkgray flex items-center justify-center rounded-[5px]">
                <span className="text-text-darkgray font-semibold text-[14px]">
                  담당자
                </span>
              </div>
              <span className="!text-p3 font-normal w-[290px] flex items-center text-text-darkgray">
                {realtyPolicy.officer}
              </span>
            </div>
            <div className="flex mb-[8px]">
              <div className="w-[70px] !h-[20px] !text-h3 mr-[5px] border border-text-darkgray flex items-center justify-center rounded-[5px]">
                <span className="text-text-darkgray font-semibold text-[14px]">
                  본문 내용
                </span>
              </div>
              <span
                className="!text-p3 font-normal w-[290px] flex items-center text-text-darkgray"
                dangerouslySetInnerHTML={{ __html: realtyPolicy.body }}
              ></span>
            </div>
            <div className="flex mb-[8px]">
              <div className="w-[70px] !h-[20px] !text-h3 mr-[5px] border border-text-darkgray flex items-center justify-center rounded-[5px]">
                <span className="text-text-darkgray font-semibold text-[14px]">
                  본문 결과
                </span>
              </div>
              <span
                className="!text-p3 font-normal w-[290px] flex items-center text-text-darkgray"
                dangerouslySetInnerHTML={{ __html: realtyPolicy.result }}
              ></span>
            </div>
            <div className="flex mb-[8px]">
              <div className="w-[70px] !h-[20px] !text-h3 mr-[5px] border border-text-darkgray flex items-center justify-center rounded-[5px]">
                <span className="text-text-darkgray font-semibold text-[14px]">
                  본문 요약
                </span>
              </div>
              <span
                className="!text-p3 font-normal w-[290px] flex items-center text-text-darkgray"
                dangerouslySetInnerHTML={{ __html: realtyPolicy.summary }}
              ></span>
            </div>
            <div>
              <table>
                <thead className="border-b-[1px] border-tertiary-main h-[44px] text-text-darkgray">
                  <tr className="">
                    <th className="w-[73px] !text-h3 font-semibold text-text-darkgray">
                      구분
                    </th>
                    <th className="w-[145px] !text-h3 font-semibold text-text-darkgray">
                      1.5일 이후
                    </th>
                    <th className="w-[145px] !text-h3 font-semibold text-text-darkgray">
                      현재
                    </th>
                  </tr>
                </thead>
                <tbody className="h-[74px]">
                  {realtyPolicy?.realityPolicyHistories &&
                    realtyPolicy.realityPolicyHistories.map((item) => (
                      <tr key={item.id} className="">
                        <td className="w-[73px] !text-p4 font-normal p-[5px] text-text-darkgray">
                          {item.category}
                        </td>
                        <td className="w-[145px] !text-p4 font-normal p-[5px] text-text-darkgray">
                          {item.after}
                        </td>
                        <td className="w-[145px] !text-p4 font-normal p-[5px] text-text-darkgray">
                          {item.before}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            <div className="flex">
              <div className="w-[70px] !h-[20px] !text-h3 mr-[5px] border border-text-darkgray flex items-center justify-center rounded-[5px]">
                <span className="text-text-darkgray font-semibold text-[14px]">
                  참고
                </span>
              </div>
              <span className="!text-p3 font-normal w-[290px] flex items-center text-text-darkgray">
                {realtyPolicy.remarks}
              </span>
            </div>
          </>
        </div>
        <div className="flex mt-[15px]">
          <Button
            type="button"
            color="primary"
            disabled={realtyPolicy.files?.length === 0}
            variant="outlined"
            btnStyle=" w-[191px] h-[27px]"
            boxShadow
            withRightIcon="fileIcon"
            onClick={async () => {
              if (realtyPolicy.files && realtyPolicy.files?.length !== 0) {
                const files = [];

                for (const file of realtyPolicy.files) {
                  const resp = await axiosInst.get(`/files/${file.id}`);
                  const newFile = {
                    filename: resp.data.filename,
                    mimeType: resp.data.mimeType,
                    url: resp.data.url,
                  };
                  files.push(newFile);
                }

                // JSZip 인스턴스 생성
                const zip = new JSZip();

                // 파일 다운로드 및 zip에 추가
                const promises = files.map(async (fileInfo, index) => {
                  const { filename, url } = fileInfo;
                  const fileName = `${filename}`;

                  try {
                    const response = await fetch(url);

                    // blob 타입으로 다운로드
                    const blob = await response.blob();
                    zip.file(fileName, blob, { binary: true }); // binary 옵션을 설정하여 이진 파일로 처리
                  } catch (error) {
                    console.error(`파일 다운로드 오류: ${error}`);
                  }
                });

                // 모든 파일 다운로드 및 압축 후 zip 파일 다운로드
                await Promise.all(promises);
                zip.generateAsync({ type: "blob" }).then((content) => {
                  const a = document.createElement("a");
                  const url = window.URL.createObjectURL(content);
                  a.href = url;
                  a.download = "Prop-Insight.zip";
                  document.body.appendChild(a);
                  a.click();
                  window.URL.revokeObjectURL(url);
                });
              }
            }}
          >
            <span className="!text-h4">첨부파일 다운로드</span>
          </Button>

          <Button
            type="button"
            color="primary"
            variant="outlined"
            btnStyle=" w-[191px] h-[27px] ml-[6px] text-h4"
            boxShadow
            withRightIcon="arrowIcon"
            onClick={() => {
              const newWindow = window.open(`${realtyPolicy.link}`, "_blank");
              // 보안 설정 (rel="noopener noreferrer")
              if (newWindow) {
                newWindow.opener = null;
              }
            }}
          >
            <span className="!text-h4">해당 기관 사이트로 이동</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default RealtyPolicyDetailPanel;
