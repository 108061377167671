import { useEffect, useMemo, useState } from "react";
import {
  BookMarkItem,
  LandInfoDetails,
  LandInfoType,
  PrivateLandSiteDetail,
  RealtyPolicyDetail,
} from "types/types";
import axiosInst from "utils/axiosInst";
import Map from "components/Map";
import { PanelFooter } from "@prop-insight/components";
import { RouteObject, useLocation, useNavigate } from "react-router-dom";
import HomePanel from "components/HomePanel";
import withViewerTemplate, { ScreenDefaultProps } from "hoc/withViewerTemplate";
import BookmarkPanel from "components/BookmarkPanel";
import NewLandPanel from "components/NewLandPanel";
import RealtyPolicyPanel from "components/RealtyPolicyPanel";
import { Drawer } from "components/Drawer";
import { LandSite, Post, RealtyPolicy } from "@prop-insight/database";
import NewLandDetailPanel from "components/NewLandDetailPanel";
import BookmarkDetailPanel from "components/BookmarkDetailPanel";
import RealtyPolicyDetailPanel from "components/RealtyPolicyDetailPanel";
import NoticePanel from "components/PostPanel";
import NoticeDetailPanel from "components/PostDetailPanel";
import viewCountUp, {
  LocalStorageKey,
  landSiteCountUpApi,
  postCountUpApi,
  privateLandSiteCountUpApi,
  realtyPolicyCountUpApi,
} from "utils/viewCountUp";
import UserPanel from "components/UserPanel";
import PrivateLandSitesPanel from "components/PrivateLandSitesPanel";
import PrivateLandSiteDetailPanel from "components/PrivateLandSiteDetailPanel";

function Home({
  selectedMenu,
  openDrawer,
  onOpenDrawer,
  onChangeMenu,
  onOpenToast,
}: ScreenDefaultProps) {
  const navigate = useNavigate();
  const [landInfo, setLandInfo] = useState<LandInfoType>();
  const [landDetails, setLandDetails] = useState<LandInfoDetails>();
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [realtyPolicy, setRealtyPolicy] = useState<RealtyPolicyDetail>();
  const [privateLandSite, setPrivateLandSite] =
    useState<PrivateLandSiteDetail>();
  const [bookMarkList, setBookMarkList] = useState<BookMarkItem>();
  const [landSite, setLandSite] = useState<LandSite>();
  const [post, setPost] = useState<Post>();
  const { pathname } = useLocation();

  const userToken = window.localStorage.getItem("token");

  // 왼쪽 메뉴로 패널을 이동하면 상세패널을 닫기위함.
  useEffect(() => {
    setDetailsOpen(false);
  }, [selectedMenu]);

  useEffect(() => {
    const loadLandInfo = async (id: number) => {
      const resp = await axiosInst.get(`/lands/${id}/infra`);
      // console.log("resp", resp.data);
      setLandDetails(resp.data);
    };
    if (landInfo) {
      loadLandInfo(landInfo.id);
    }
  }, [landInfo]);

  const openPostDetail = (post: Post) => {
    setLandSite(undefined);
    setDetailsOpen(true);
    setPost(post);
    viewCountUp({
      id: post.id,
      localStorageKey: LocalStorageKey.POST_VIEW_TIME_DATA_LIST,
      countUpApi: postCountUpApi,
    });
  };

  const openNewLandDetail = (landSite: LandSite) => {
    setPost(undefined);
    setDetailsOpen(true);
    setLandSite(landSite);
    viewCountUp({
      id: landSite.id,
      localStorageKey: LocalStorageKey.LANDSITE_VIEW_TIME_DATA_LIST,
      countUpApi: landSiteCountUpApi,
    });
  };

  const openPrivateLandSiteDetail = (privateLandSite: any) => {
    setPost(undefined);
    setDetailsOpen(true);
    setPrivateLandSite(privateLandSite);
    viewCountUp({
      id: privateLandSite.id,
      localStorageKey: LocalStorageKey.PRIVATELANDSITE_VIEW_TIME_DATA_LIST,
      countUpApi: privateLandSiteCountUpApi,
    });
  };

  const openRealtyPolicyDetail = (realtyPolicy: RealtyPolicy) => {
    setRealtyPolicy(realtyPolicy);
    setDetailsOpen(true);
    viewCountUp({
      id: realtyPolicy.id,
      localStorageKey: LocalStorageKey.REALTYPOLICY_VIEW_TIME_DATA_LIST,
      countUpApi: realtyPolicyCountUpApi,
    });
  };

  const drawerMarkup = useMemo(() => {
    if (selectedMenu === "users") {
      return (
        <UserPanel onChangeMenu={onChangeMenu} onOpenToast={onOpenToast} />
      );
    }
    if (selectedMenu === "newland") {
      return <NewLandPanel openNewLandDetail={openNewLandDetail} />;
    }

    if (selectedMenu === "private-land-sites") {
      return (
        <PrivateLandSitesPanel
          openPrivateLandSiteDetail={openPrivateLandSiteDetail}
        />
      );
    }

    if (selectedMenu === "bookmark") {
      return (
        <BookmarkPanel
          openRealtyPolicyDetail={(bookMarkList) => {
            setDetailsOpen(true);
            setBookMarkList(bookMarkList);
          }}
          userToken={userToken !== null && userToken !== "null"}
        />
      );
    }
    if (selectedMenu === "realtypolicy") {
      return (
        <RealtyPolicyPanel openRealtyPolicyDetail={openRealtyPolicyDetail} />
      );
    }

    if (selectedMenu === "posts") {
      return <NoticePanel openPostDetail={openPostDetail} />;
    }

    return (
      <HomePanel
        userToken={userToken !== null && userToken !== "null"}
        onChangeMenu={onChangeMenu}
        openNewLandDetail={openNewLandDetail}
        openPostDetail={openPostDetail}
      />
    );
  }, [onChangeMenu, onOpenToast, selectedMenu, userToken]);

  const drawerDetailMarkup = useMemo(() => {
    if (selectedMenu === "newland" && landSite) {
      return (
        <NewLandDetailPanel
          landSite={landSite}
          user={userToken !== null && userToken !== "null"}
        />
      );
    }

    if (selectedMenu === "home" && landSite) {
      return (
        <NewLandDetailPanel
          landSite={landSite}
          user={userToken !== null && userToken !== "null"}
        />
      );
    }

    if (selectedMenu === "private-land-sites" && privateLandSite) {
      return <PrivateLandSiteDetailPanel privateLandSite={privateLandSite} />;
    }

    if (selectedMenu === "posts" && post) {
      return <NoticeDetailPanel post={post} />;
    }

    if (selectedMenu === "home" && post) {
      return <NoticeDetailPanel post={post} />;
    }

    if (selectedMenu === "bookmark" && bookMarkList) {
      return <BookmarkDetailPanel bookMarkItem={bookMarkList} />;
    }
    if (selectedMenu === "realtypolicy" && realtyPolicy) {
      return <RealtyPolicyDetailPanel realtyPolicy={realtyPolicy} />;
    }
  }, [
    bookMarkList,
    landSite,
    post,
    privateLandSite,
    realtyPolicy,
    selectedMenu,
    userToken,
  ]);

  return (
    <main className="flex min-h-screen flex-col items-center justify-between">
      <Map
        onChangeLandInfo={(l) => {
          console.log("landInfo", l);
          setLandInfo(l);
          setLandDetails(undefined);
        }}
        openPrivateLandSiteDetail={(privateLandSite: any) => {
          onChangeMenu && onChangeMenu("private-land-sites");
          onOpenDrawer(true);

          setTimeout(() => {
            setPrivateLandSite(privateLandSite);
            setDetailsOpen(true);
          }, 100);
        }}
        landDetails={landDetails}
      />

      <div className="absolute left-0 flex z-50">
        <div>
          <Drawer
            open={selectedMenu !== "analysis" && openDrawer}
            onClose={() => onOpenDrawer(false)}
            noPadding={selectedMenu === "home"}
            childrenDetail={drawerDetailMarkup}
            // selectedMenu 값 별로 상세패널을 다르게 보여주기 위함.
            selectedMenu={selectedMenu}
            // 로그인 안한경우 신규택지 상세에서 낙찰가/경쟁률을 블러처리, showBox가 false면 블러
            showBox={userToken !== null && userToken !== "null"}
            detailsOpen={detailsOpen}
            onDetailsClose={() => {
              setDetailsOpen(false);
            }}
          >
            {drawerMarkup}
            {/* 푸터 */}
            {(selectedMenu === "home" || selectedMenu === "users") && (
              <PanelFooter
                onLegalNotice={() => {
                  navigate("/legal-notice", {
                    state: {
                      from: pathname,
                    },
                  });
                }}
              />
            )}
          </Drawer>
        </div>
      </div>
    </main>
  );
}

const WithSidebar = withViewerTemplate(Home);

export default {
  path: "/",
  element: <WithSidebar />,
} as RouteObject;
