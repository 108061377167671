import { LandSite } from "@prop-insight/database";
import _ from "lodash";
import { useMap } from "providers/MapProvider";
import { Fragment, useCallback } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import clsx from "clsx";
import axiosInst from "utils/axiosInst";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import { LoadingSpinner } from "@prop-insight/components";
import useQuery from "hooks/useQuery";
import { getLandSiteCompleted } from "utils/api/landSiteApi";

interface CompletedLandTableProps {
  userToken?: boolean;
  openNewLandDetail: (landSite: LandSite) => void;
}

const CompletedLandTable = ({
  userToken,
  openNewLandDetail,
}: CompletedLandTableProps) => {
  const { setMapState } = useMap();
  const { data: landSites, isLoading } =
    useQuery<LandSite[]>(getLandSiteCompleted);

  const handleNewLandDetail = useCallback(
    async (id: number) => {
      try {
        const resp = await axiosInst.get(`/land-sites/${id}`);
        openNewLandDetail(resp.data);
      } catch (error) {
      } finally {
      }
    },
    [openNewLandDetail]
  );

  return (
    <table className="w-[100%]">
      <tbody
        className={`${clsx({
          "flex justify-center items-center h-[40vh]": landSites?.length === 0,
        })}`}
      >
        {landSites?.length === 0 ? (
          <div className="flex flex-col items-center">
            <span className="text-title text-primary-main-color font-semibold text-center h-[29px] -ml-1 mb-[13px]">
              택지 항목 없음
            </span>
            <span className="text-p2 text-primary-main-color text-center">
              마감된 택지 항목이 존재하지 않습니다.
            </span>
          </div>
        ) : isLoading ? (
          <div className="flex justify-center items-center h-[200px]">
            <LoadingSpinner panel />
          </div>
        ) : (
          _.map(landSites, (landSite) => {
            return (
              <Fragment key={`land-site-${landSite.id}`}>
                <tr
                  className="border-b-[1px] h-[80px] border-[#F3F2F2] hover:cursor-pointer hover:bg-background-main-color"
                  onClick={() => {
                    handleNewLandDetail(landSite.id);
                  }}
                >
                  <td className="w-[38px] h-[64px]">
                    <div
                      style={{ backgroundColor: "#A5ABB3" }}
                      className={
                        "text-[10px] rounded flex items-center justify-center h-[31px] text-white mr-[2px] px-[1px] max-w-[39px]"
                      }
                    >
                      <span className="whitespace-nowrap">{"접수마감"}</span>
                    </div>
                  </td>
                  <td className={"text-p4 w-[42px] h-[31px] text-center"}>
                    <span>{landSite.purpose}</span>
                  </td>
                  <td className={"w-[136px] h-[31px] pl-[3px] pr-[6px]"}>
                    <p className="text-h4 font-semibold whitespace-nowrap text-ellipsis max-w-[127px] overflow-hidden">
                      {landSite.title}
                    </p>
                    <p className="text-p4 whitespace-nowrap text-ellipsis max-w-[127px] overflow-hidden">
                      {landSite.address}
                    </p>
                  </td>
                  <td
                    className={
                      "text-p4 w-[72px] h-[31px] text-center pr-[12px]"
                    }
                  >
                    {landSite.authority}
                  </td>
                  <td className={"text-p4 h-[31px] w-[45px]"}>
                    <>
                      {userToken ? (
                        <>
                          <p className="text-p4 text-center">경쟁률</p>
                          {/* 경쟁률 / 아직 어드민에서 입력란이 없어서 일단 -로 출력 */}
                          <p className="text-p4 text-center">-</p>
                        </>
                      ) : (
                        <div
                          data-tooltip-id="info-tooltip"
                          data-tooltip-content={
                            "경쟁률 정보는 로그인 시 확인 가능합니다."
                          }
                        >
                          <div
                            style={{
                              textShadow: "0px 0px 10px black",
                              color: "transparent",
                            }}
                          >
                            <p className="text-p4 text-center">경쟁률</p>
                            <p className="text-p4 text-center">82.4:1</p>
                          </div>
                        </div>
                      )}

                      <ReactTooltip
                        id="info-tooltip"
                        place="right"
                        style={{
                          position: "fixed",
                          right: 0,
                          backgroundColor: "#16B5B8",
                          zIndex: 999,
                          padding: "1px 8px",
                          borderRadius: 4,
                        }}
                      />
                    </>
                  </td>
                  <td className="h-[31px] pl-[8px]">
                    <div className="flex justify-center items-center">
                      <FontAwesomeIcon
                        icon={faMapLocationDot}
                        size="lg"
                        className="p-2"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (landSite.imageBounds.length > 2) {
                            console.log(landSite.imageBounds.length);
                            setMapState({
                              bounds: JSON.parse(landSite.imageBounds),
                              fileId: landSite.fileId,
                            });
                          } else {
                            setMapState({
                              lat: landSite.lat,
                              lng: landSite.lng,
                            });
                          }
                        }}
                      />
                    </div>
                  </td>
                </tr>
              </Fragment>
            );
          })
        )}
      </tbody>
    </table>
  );
};

export default CompletedLandTable;
