import React, { useState, useMemo } from "react";
import _ from "lodash";
import ClickAwayListener from "./ClickAwayListener";

export interface SelectorItem {
  key: string;
  value: string;
}

interface SelectBoxProps {
  keyValue: string;
  defaultValue: string;
  onChange?: (item: SelectorItem) => void;
  items: SelectorItem[];
  className?: string;
  rootClassName?: string;
  textClassName?: string;
  textDivClassName?: string;
  MenuClassName?: string;
  longTextItem?: boolean;
}

const SelectBox = ({
  keyValue,
  defaultValue,
  onChange,
  className,
  rootClassName,
  textClassName,
  textDivClassName,
  MenuClassName,
  longTextItem,
  items,
}: SelectBoxProps) => {
  const [openMenu, setOpenMenu] = useState(false);

  const currentValue = useMemo(() => {
    const val =
      _.find(items, (item) => item.key === keyValue)?.value ?? defaultValue;
    return val;
  }, [defaultValue, items, keyValue]);

  return (
    <div className={`${rootClassName}`}>
      <div
        onClick={(event) => {
          setOpenMenu((o) => !o);
          event.stopPropagation();
        }}
        style={{
          filter: "drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.2))",
        }}
        className={`flex items-center justify-between ${
          openMenu || keyValue !== currentValue
            ? "bg-primary-main-color"
            : "bg-secondary-main-color"
        } hover:bg-primary-main-color active:bg-primary-main-color rounded pl-4 py-[4px] pr-[10px] cursor-pointer ${className}`}
      >
        <div className={`w-full text-center ${textDivClassName}`}>
          <span className={`text-h2 text-white mt-[2px] ${textClassName}`}>
            {currentValue}
          </span>
        </div>

        <svg
          width="8"
          height="4"
          viewBox="0 0 8 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0.25 0.125L4 3.875L7.75 0.125H0.25Z" fill="white" />
        </svg>
      </div>
      <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
        {openMenu && (
          <ul
            className={`absolute bg-white py-2 rounded mt-[7px] ${MenuClassName}`}
            style={{ boxShadow: "2px 2px 4px 0px #00000033" }}
          >
            {_.map(items, (item) => (
              <li
                className={`${
                  longTextItem ? "px-[12px]" : "px-[15px]"
                } py-2 cursor-pointer hover:bg-background-main-color`}
                key={item.key}
                onClick={() => {
                  setOpenMenu(false);
                  onChange?.(item);
                }}
              >
                <span className="text-text-darkgray text-p1">{item.value}</span>
              </li>
            ))}
          </ul>
        )}
      </ClickAwayListener>
    </div>
  );
};

export default SelectBox;
