import { useMemo, useCallback } from "react";
import {
  faBackwardStep,
  faAngleLeft,
  faAngleRight,
  faForwardStep,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onChangePage: (page: number) => void;
}

const Pagination = ({
  currentPage,
  totalPages,
  onChangePage,
}: PaginationProps) => {
  // 페이지 변경 핸들러
  const handlePageChange = useCallback(
    (page: number) => {
      onChangePage(page);
    },
    [onChangePage]
  );

  // 페이지 번호 생성 및 렌더링
  const renderPageNumbers = useMemo(() => {
    // 출력되는 번호 최대 5개
    const pageRange = 5;
    const pageNumbers = [];

    let startPage = Math.max(currentPage - Math.floor(pageRange / 2), 1);
    let endPage = Math.min(startPage + pageRange - 1, totalPages);

    if (endPage - startPage < pageRange - 1) {
      startPage = Math.max(endPage - pageRange + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`py-[14px] px-4 rounded hover:bg-background-main-color text-p2 ${
            currentPage === i
              ? "bg-background-main-color text-text-darkgray"
              : "text-text-darkgray"
          }`}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  }, [currentPage, handlePageChange, totalPages]);

  return (
    <div className="flex items-center justify-center absolute bottom-0 left-0 w-full h-[82px]">
      {/* 처음 페이지로 이동 */}
      <button
        onClick={() => handlePageChange(1)}
        disabled={currentPage === 1}
        className="py-2 px-[15px] text-text-darkgray disabled:text-secondary-main-color"
      >
        <FontAwesomeIcon icon={faBackwardStep} />
      </button>
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="py-2 px-4 text-text-darkgray disabled:text-secondary-main-color"
      >
        <FontAwesomeIcon icon={faAngleLeft} />
      </button>
      {renderPageNumbers}
      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="py-2 px-4 text-text-darkgray disabled:text-secondary-main-color"
      >
        <FontAwesomeIcon icon={faAngleRight} />
      </button>
      {/* 마지막 페이지로 이동 */}
      <button
        onClick={() => handlePageChange(totalPages)}
        disabled={currentPage === totalPages}
        className="py-2 px-[15px] text-text-darkgray disabled:text-secondary-main-color"
      >
        <FontAwesomeIcon icon={faForwardStep} />
      </button>
    </div>
  );
};

export default Pagination;
